import { Modal, Button } from "react-bootstrap";
import buttonStyle from "../../styles/button-style.module.scss";
import style from "../../styles/file-uploader.module.scss";
import { useState } from "react";
import { toast } from "react-toastify";
import BookingSummaryCard from "../booking-summary-card";
import DownloadPDF from "../download-pdf";
import axios from "axios";
import LoadingSpinner from "../loading-spinner";
import { BookingWithCert } from "../../types/BookingWithCert";
import { getServerURL } from "../../utils/funcs/get-serverURL";

export default function DeleteFile({
  cert,
  show,
  handleClose,
  setBookingWithCert,
}: any): React.ReactElement {
  const [loading, setLoading] = useState(false);
  const [deleteErrorMsg, setDeleteErrorMsg] = useState("");
  const [showConfirmation, setShowConfirmation] = useState("");

  const serverURL = getServerURL();

  const notify = () => {
    toast.success("Report deleted successfully", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleDelete = () => {
    setLoading(true);
    axios
      .delete(`${serverURL}/delete-certificate/${cert.booking.bookingID}`)
      .then((response: any) => {
        setLoading(false);
        setBookingWithCert((prev: BookingWithCert[]) => {
          return prev.map((certificate: BookingWithCert) => {
            if (certificate.booking.bookingID === cert.booking.bookingID) {
              delete certificate.certificate;
            }
            return certificate;
          });
        });
        handleClose();
        notify();
        console.log(response);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} className={style.uploadModal}>
        <Modal.Header closeButton className={style.uploadModalTitle} />
        <Modal.Body className={style.modalBody}>
          <>
            <h2>Are you sure you want to delete this file?</h2>
            <BookingSummaryCard booking={cert.booking} />
            <div className={style.flex}>
              <h5>{cert.certificate.certData.fileName}</h5>
              <DownloadPDF
                bookingID={cert.booking.bookingID}
                serverURL={serverURL}
                fileName={cert.certificate.certData.fileName}
              />
            </div>
            <br />
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} className="btn secondaryBtn">
            Close
          </Button>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <Button className={buttonStyle.modalBtn} onClick={handleDelete}>
              Delete File
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
