import ReactDOMClient from "react-dom/client";
import Router from "./routers/router";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import logo from "./assets/logo/logo.jpg";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { PaymentProvider } from "./context/payment-success-context";
import { ClerkProvider } from "@clerk/clerk-react";

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

const root = ReactDOMClient.createRoot(
  document.getElementById("root") as HTMLDivElement
);

root.render(
  <PaymentProvider>
      <ClerkProvider publishableKey={clerkPubKey}>
      <Router />
      </ClerkProvider>
      <FloatingWhatsApp
        phoneNumber="+447496445148"
        accountName="Teck Engineers"
        avatar={logo}
        notification={false}
      />
    </PaymentProvider>
);
