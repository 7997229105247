import EICRImg from "../../assets/EICR1.jpg";
import PATImg from "../../assets/pattesting.jpg";
import minorWorks from "../../assets/minor-works.jpg";
import emergency from "../../assets/emergency.jpg";
import fireAlarm from "../../assets/firealarm.jpg";
import remedial from "../../assets/remedial.webp";
import EICRIcon from "../../assets/badge.svg"
import PATIcon from "../../assets/PATIcon.svg"
import remedialIcon from "../../assets/remedialIcon.svg"
import minorIcon from "../../assets/minorIcon.svg"
import emergencyIcon from "../../assets/emergencyIcon.svg"
import lightingTesting from "../../assets/lighting-testing.jpg";
import alarmIcon from "../../assets/alarmIcon.svg"
import lightingIcon from "../../assets/lightingIcon.svg"

export interface ServicesExamples {
  id: number;
  slug: string;
  title: string;
  tagline: string;
  description: string;
  imgURL?: string;
  icon: string;
  minPrice: number;
  nameOfComponent: string;
  jsxContent?: React.ReactNode;
  searchTerms?: string[];
}

export const services: ServicesExamples[] = [
  {
    id: 1,
    slug: "EICR-certificate",
    title: "EICR Certificates",
    tagline: "Electric Installation Condition Report",
    description:
      "EICR (Electrical Installation Condition Report) testing is a thorough inspection of a property's electrical systems to identify potential hazards or defects. Our qualified electricians inspect wiring, sockets, switches, and other equipment, and provide a detailed report with recommendations for necessary repairs or improvements.",
    imgURL: EICRImg,
    icon: EICRIcon,
    minPrice: 69,
    nameOfComponent: "EICR",
    searchTerms: [
      "eicr",
      "certificate",
      "condition",
      "report",
      "tenant",
      "electrical",
      "installation",
    ],
  },
  {
    id: 2,
    slug: "PAT-certificate",
    title: "PAT Certificates",
    tagline: "Portable Appliance Testing Certificate ",
    description:
      "PAT (Portable Appliance Testing) is a process that aims to assess the safety of electrical appliances. Our qualified electricians conduct visual inspections and electrical tests to detect any faults that may cause harm. We provide a detailed report with recommendations for repairs or improvements, ensuring compliance with regulations and standards.",
    imgURL: PATImg,
    icon: PATIcon,
    minPrice: 65,
    nameOfComponent: "PAT",
    searchTerms: [
      "pat",
      "pat testing",
      "portable appliance testing",
      "certificate",
      "appliance",
      "report",
      "tenant",
    ],
  },
  {
    id: 3,
    slug: "remedial-works",
    title: "Electrical Remedial Works",
    tagline: "Electrical Remedial Works",
    description: "Electrical Remedial Works",
    imgURL: remedial,
    icon: remedialIcon,

    minPrice: 22,
    nameOfComponent: "RemedialWorks",
    searchTerms: ["electrical remedial work"],
  },
  {
    id: 4,
    slug: "minor-works",
    title: "Minor Electrical Works",
    tagline: "Fault finding, switches, lights, sockets or fusebox tripping",
    description: "desc",
    imgURL: minorWorks,
    icon: minorIcon,

    minPrice: 65,
    nameOfComponent: "MinorElectricalWorks",
    searchTerms: [
      "fault finding",
      "minor",
      "electrical",
      "works",
      "switches",
      "sokcets",
      "plugs",
      "lights",
      "fusebox tripping",
    ],
  },
  {
    id: 5,
    slug: "emergency-callouts",
    title: "Emergency Callout",
    tagline: "Emergency Callouts",
    description: "Our electricians are dedicated to addressing a wide range of electrical emergencies, including faulty wiring, and electrical system failures in homes and businesses. With our expertise and commitment to safety, we swiftly diagnose, repair, and restore your electrical systems, providing you with peace of mind when you need it most. Contact us directly for same day emergency callouts or book online for next-day appointments.",
    imgURL: emergency,
    icon: emergencyIcon,

    minPrice: 120,
    nameOfComponent: "EmergencyCallouts",
    searchTerms: ["emergency electrician", "electric", "installation"],
  },
  {
    id: 6,
    slug: "fire-alarm-testing",
    title: "Fire and Smoke Alarm Testing",
    tagline: "Fire and Smoke Alarm Testing",
    description: "Fire and Smoke Alarm Testing",
    imgURL: fireAlarm,
    icon: alarmIcon,
    minPrice: 99,
    nameOfComponent: "FireAlarm",
    searchTerms: [
      "fire",
      "smoke alarm",
      "testing",
      "emergency drain light testing",
    ],
  },
  {
    id: 7,
    slug: "emergency-lighting-testing",
    title: "Emergency Lighting Drain Testing",
    tagline: "Emergency Lighting Drain Testing",
    description: "Emergency Lighting Drain Testing",
    imgURL: lightingTesting,
    icon: lightingIcon,
    minPrice: 79,
    nameOfComponent: "LightingTesting",
    searchTerms: [
      "emergency lighting",
      "drain",
      "testing",
      "emergency drain light testing",
    ],
  },
];

// {
//   id: 4,
//   slug: "new-consumer-unit-installation",
//   title: "New Consumer Unit",
//   tagline:
//     "Ensure the safety and reliability of your electrical system with our new consumer unit installation.",
//   description:
//     "Our new consumer unit installation service provides a comprehensive upgrade to your electrical system, ensuring the safety and reliability of your home or business. Our team of skilled professionals will install a modern consumer unit, replacing your outdated fuse box or circuit breaker panel. This new unit will provide enhanced protection from electrical hazards, such as electric shock and fire, and offer greater control and convenience with features such as individual circuit breakers, residual current devices (RCDs), and surge protection. With our new consumer unit installation service, you can enjoy peace of mind knowing that your electrical system is up to date and functioning optimally.",
//     imgURL: consumerUnit,
//   minPrice: 700,
//   nameOfComponent: "ConsumerUnit",
//   searchTerms: [
//     "consumer unit",
//     "installation",
//     "fuse box",
//     "fuse board",
//     "fusebox",
//     "fuseboard",
//   ],
// },
  // {
  //   id: 3,
  //   slug: "socket-installation",
  //   title: "Sockets",
  //   tagline:
  //     "Power up your home or business with our expert socket installation service.",
  //   description:
  //     "Our socket installation service is designed to provide a convenient and reliable power source for your home or business. Whether you need additional sockets for new appliances or want to replace old and worn out sockets, our team of skilled electricians will provide a seamless installation experience. We can also offer advice on the best placement of sockets to maximize convenience and functionality.",
  //   imgURL: socket,
  //   minPrice: 30,
  //   nameOfComponent: "Sockets",
  //   searchTerms: ["sockets", "socket installation" ]
  // },
  // {
//   id: 5,
//   slug: "light-fittings",
//   title: "Light Fittings",
//   tagline:
//     "Illuminate your space with our expert light fitting installation service.",
//   description:
//     "Our light fitting installation service is designed to provide you with the perfect lighting solution for your space. Whether you want to add a touch of elegance to your home or create a more welcoming atmosphere in your business, our team of experienced electricians will provide a seamless installation experience. We use high-quality materials and workmanship to ensure that your new light fittings are safe, durable, and meet all electrical safety standards. With our light fitting installation service, you can transform your space with beautiful and functional lighting that enhances your ambiance and sets the mood for any occasion.",
//   imgURL: light,
//   minPrice: 30,
//   nameOfComponent: "LightFittings",
//   searchTerms: ["light fitting", "installation", "light switch" ]
// },
// {
//   id: 6,
//   slug: "switches-installation",
//   title: "Switches",
//   tagline:
//     "Take control of your space with our expert switch installation service.",
//   description:
//     "Our switch installation service provides a simple and effective way to control the lighting and electrical devices in your home or business. Whether you want to replace old and worn out switches or install new ones in different locations, our team of skilled electricians will provide a seamless installation experience. We offer a wide range of switches, including dimmer switches, rocker switches, and smart switches, to suit your needs and preferences. We use high-quality materials and workmanship to ensure that your new switches are safe, durable, and meet all electrical safety standards. With our switch installation service, you can take control of your space and create the perfect lighting and electrical setup that meets your unique requirements.",
//   imgURL: switches,
//   minPrice: 30,
//   nameOfComponent: "Switches",
//   searchTerms: ["switch", "installation", "light switch" ]
// },
