import { Link } from "react-router-dom";
import { services } from "../utils/objects/services-object";
import style from "../styles/product-cards.module.scss";
import { BottomSpacer } from "../components/spacer";
import SearchBar from "../components/search-services-bar";
import PostcodeChecker from "../components/postcode-checker";

export default function Book() {
  return (
    <>
      <div className={style.spacer} />
      <PostcodeChecker />
      <div className={style.searchBarSpacer} />
      <div className={style.searchBar}>
        <SearchBar booking />
      </div>
      <div className={style.topSpacer} />
      {/* <div className={style.servicesHeading}>
        <h2 >Services</h2>
        <div className={style.headingDivider} />
      </div> */}
      <div className={style.productsContainer}>
        {services.map(
          ({ id, slug, title, tagline, minPrice, imgURL, icon }) => {
            return (
              <Link to={`/book-now/${slug}`} key={id} className={style.link}>
                <div className={style.productCard}>
                  <img src={icon} alt="product" className={style.productImg} />
                  <h4>{title}</h4>
                  <p className={style.tagline}>{tagline}</p>
                  <div className={style.divider} />
                  <p>From £{minPrice}</p>
                  <button className={style.button}>Book Now</button>
                </div>
              </Link>
            );
          }
        )}
      </div>
      <BottomSpacer />
    </>
  );
}
