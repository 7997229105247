import { FaUpload } from "react-icons/fa";

import buttonStyle from "../styles/button-style.module.scss";
import style from "../styles/file-uploader.module.scss";

export default function FileInput({
  setFile,
  setFileError,
  file,
  fileError,
}: any) {


    
  return (
    <>
      <h5>File(s)</h5>
      <div className={style.uploadInputParent}>
        <input
          id="file"
          type="file"
          accept="application/pdf"
          className={style.uploadInput}
          onChange={(e) => {
            setFileError("");
            setFile(e.target.files);
          }}
        />
        <FaUpload className={style.uploadIcon} />
        {file && file[0] ? (
          <div>
            <p>{file[0]?.name}</p>
          </div>
        ) : (
          <p>Click here to upload file</p>
        )}
      </div>
      <br />
      {file && (
        <button
          className={buttonStyle.button}
          onClick={() => {
            setFile(null);
          }}
        >
          Remove Selected Files
        </button>
      )}
      {fileError && (
        <>
          <br/>
          <br/>
        <p className="error" style={{ textAlign: "center" }}>
          {fileError}
          </p>
          </>
      )}
    </>
  );
}
