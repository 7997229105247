import { useState, useEffect } from "react";
import style from "../../styles/service-prices.module.scss";
import EICRImg from "../../assets/EICR1.jpg";
import ServiceTemplateComponent from "./template";
import { formatEmailDate } from "../../utils/funcs/google-calendar";

export default function EICRPrice() {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  if (tomorrow.getDay() === 0) {
    // Sunday
    tomorrow.setDate(tomorrow.getDate() + 1); // Move to Monday
  }

  const [showCircuits, setShowCircuits] = useState(false);
  const [showBedrooms, setShowBedrooms] = useState(false);
  const [showFuseboxes, setShowFuseboxes] = useState(false);
  const [propertyType, setPropertyType] = useState("Domestic");
  const [bedrooms, setBedrooms] = useState(1);
  const [circuits, setCircuits] = useState(0);
  const [fuseboxes, setFuseboxes] = useState(1);
  const [item, setItem] = useState({
    bookingID: " ",
    productName: "EICR Certificate",
    price: 69,
    productInfo: `Domestic: No. of bedrooms: ${bedrooms}, No. of fuseboxes: ${fuseboxes}`,
    customerName: "",
    customerEmail: "",
    customerNumber: "",
    start: "",
    end: "",
    date: tomorrow,
    emailDate: formatEmailDate(tomorrow),
    emailStartTime: "",
    access: "Access provided by: Customer on booking",
    address: { line1: "", line2: " ", postTown: "", postcode: "" },
    finalPrice: 0
  });


  useEffect(() => {
      let price = 69;
      
      if (circuits >= 1 && circuits <= 5) {
        price = 99;
      } else if (circuits >= 6 && circuits <= 10) {
        price = 135;
      } else if (circuits >= 11 && circuits <= 15) {
        price = 171;
      } else if (circuits >= 16 && circuits <= 20) {
        price = 207;
      } else if (circuits >= 21 && circuits <= 25) {
        price = 243;
      } else if (circuits > 25) {
        const extraCircuits = circuits - 25;
        const extraPrice = extraCircuits * 6;
        price = 243 + extraPrice;
      }
      
      setItem((prevItems) => ({
        ...prevItems,
        productInfo: `Non-domestic: ${circuits} circuits`,
        price: price,
      }));
    }, [circuits]);
    
    useEffect(() => {
      const fuseboxPrice = fuseboxes * 45 - 45;
      let price = 69;
      
      if (bedrooms === 1) {
        price = 69 + fuseboxPrice;
      } else if (bedrooms === 2) {
      price = 73 + fuseboxPrice;
    } else if (bedrooms === 3) {
      price = 75 + fuseboxPrice;
    } else if (bedrooms === 4) {
      price = 82 + fuseboxPrice;
    } else if (bedrooms === 5) {
      price = 90 + fuseboxPrice;
    } else if (bedrooms > 5) {
      const extraRooms = bedrooms - 5;
      const extraPrice = extraRooms * 10;
      price = 90 + extraPrice + fuseboxPrice;
    }

    setItem((prevItems) => ({
      ...prevItems,
      productInfo: `Domestic: No. of bedrooms: ${bedrooms}, No. of fuseboxes: ${fuseboxes}`,
      price: price,
    }));
    }, [bedrooms, fuseboxes]);
  
  return (
    <ServiceTemplateComponent
      item={item}
      setItem={setItem}
      backgroundImage={EICRImg}
      tomorrow={tomorrow}
      serviceDescription="EICR (Electrical Installation Condition Report) testing is a thorough inspection of a property's electrical systems to identify potential hazards or defects. Our qualified electricians inspect wiring, sockets, switches, and other equipment, and provide a detailed report with recommendations for necessary repairs or improvements."
    >
      <div>
        <label htmlFor="propertyType">Property type:</label>
        <div className={style.allRadios}>
          <div className={style.propertyRadioBtns}>
            <input
              name="propertyType"
              type="radio"
              value="Domestic"
              id="domestic"
              defaultChecked
              onChange={(e) => {
                setItem((prevItem) => ({
                  ...prevItem,
                  productInfo: `Domestic: No. of bedrooms: 1, No. of fuseboxes: 1`,
                  price: 69,
                }));
                setPropertyType(e.target.value);
              }}
            />
            <label htmlFor="domestic">Domestic</label>
          </div>
          <div className={style.propertyRadioBtns}>
            <input
              type="radio"
              name="propertyType"
              value="Non-domestic"
              id="non-domestic"
              onChange={(e) => {
                setItem((prevItem) => ({
                  ...prevItem,
                  productInfo: `Non-domestic: 1-5 circuits`,
                  price: 99,
                }));
                setPropertyType(e.target.value);
              }}
            />
            <label htmlFor="non-domestic">Non-domestic</label>
          </div>
        </div>

        {propertyType === "Non-domestic" && (
          <>
            <label htmlFor="circuits">
              How many circuits does your property contain?
            </label>
            <div className={style.nonDomRadios}>
              <div className={style.nonDomBtns}>
                <input
                  defaultChecked
                  type="radio"
                  name="circuitsRadio"
                  value="1-5"
                  id="1-5"
                  onChange={(e) => {
                    setShowCircuits(false);
                    setCircuits(5);
                  }}
                />
                <label htmlFor="1-5">1-5 circuits</label>
              </div>
              <div className={style.nonDomBtns}>
                <input
                  type="radio"
                  name="circuitsRadio"
                  value="6-10"
                  id="6-10"
                  onChange={(e) => {
                    setShowCircuits(false);
                    setCircuits(10);
                  }}
                />
                <label htmlFor="6-10">6-10 circuits</label>
              </div>
              <div className={style.nonDomBtns}>
                <input
                  type="radio"
                  name="circuitsRadio"
                  value="11-15"
                  id="11-15"
                  onChange={(e) => {
                    setShowCircuits(false);
                    setCircuits(15);
                  }}
                />
                <label htmlFor="11-15">11-15 circuits</label>
              </div>
              <div className={style.nonDomBtns}>
                <input
                  type="radio"
                  name="circuitsRadio"
                  value="16-20"
                  id="16-20"
                  onChange={(e) => {
                    setShowCircuits(false);
                    setCircuits(20);
                  }}
                />
                <label htmlFor="16-20">16-20 circuits</label>
              </div>
              <div className={style.nonDomBtns}>
                <input
                  type="radio"
                  name="circuitsRadio"
                  value="21-25"
                  id="21-25"
                  onChange={(e) => {
                    setCircuits(25);
                    setShowCircuits(false);
                  }}
                />
                <label htmlFor="21-25">21-25 circuits</label>
              </div>
              <div className={style.nonDomBtns}>
                <input
                  type="radio"
                  name="circuitsRadio"
                  value="26+"
                  id="26+"
                  onChange={(e) => {
                    setCircuits(26);
                    setShowCircuits(true);
                  }}
                />
                <label htmlFor="26+">26+ circuits</label>
              </div>
              {showCircuits && (
                <input
                  min="1"
                  className={style.input}
                  type="number"
                  placeholder="Enter no. of circuits"
                  name="circuits"
                  id="circuits"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setCircuits(26);
                    } else {
                      setCircuits(+e.target.value);
                    }
                  }}
                  onWheel={(e) => e.currentTarget.blur()}
                />
              )}
            </div>
            <div className={style.list}>
              <p className={style.priceLabel}>Estimated price:</p>
              <p className={style.price}>£{item.price}</p>
            </div>
            <p style={{ textAlign: "left", fontSize: "0.9em" }}>
              <em>
                Your final price will be calculated based on your location
                before you go to the booking summary.
              </em>
            </p>
          </>
        )}

        {propertyType === "Domestic" && (
          <>
            <label htmlFor="bedrooms">
              How many bedrooms does your property contain?
            </label>
            <div className={style.allRadios}>
              <div className={style.radioBtns}>
                <input
                  defaultChecked
                  type="radio"
                  name="bedroomsRadio"
                  value={1}
                  id="1bedroom"
                  onChange={(e) => {
                    setShowBedrooms(false);
                    setBedrooms(Number(e.target.value));
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `Domestic: No. of bedrooms: ${e.target.value}, No. of fuseboxes: ${fuseboxes}`,
                    }));
                  }}
                />
                <label htmlFor="1bedroom">1</label>
              </div>
              <div className={style.radioBtns}>
                <input
                  type="radio"
                  name="bedroomsRadio"
                  value={2}
                  id="2bedroom"
                  onChange={(e) => {
                    setShowBedrooms(false);
                    setBedrooms(Number(e.target.value));
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `Domestic: No. of bedrooms: ${e.target.value}, No. of fuseboxes: ${fuseboxes}`,
                    }));
                  }}
                />
                <label htmlFor="2bedroom">2</label>
              </div>
              <div className={style.radioBtns}>
                <input
                  type="radio"
                  name="bedroomsRadio"
                  value={3}
                  id="3bedroom"
                  onChange={(e) => {
                    setShowBedrooms(false);
                    setBedrooms(Number(e.target.value));
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `Domestic: No. of bedrooms: ${e.target.value}, No. of fuseboxes: ${fuseboxes}`,
                    }));
                  }}
                />
                <label htmlFor="3bedroom">3</label>
              </div>
              <div className={style.radioBtns}>
                <input
                  type="radio"
                  name="bedroomsRadio"
                  value={4}
                  id="4bedroom"
                  onChange={(e) => {
                    setShowBedrooms(false);
                    setBedrooms(Number(e.target.value));
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `Domestic: No. of bedrooms: ${e.target.value}, No. of fuseboxes: ${fuseboxes}`,
                    }));
                  }}
                />
                <label htmlFor="4bedroom">4</label>
              </div>
              <div className={style.radioBtns}>
                <input
                  type="radio"
                  name="bedroomsRadio"
                  value={5}
                  id="5bedroom"
                  onChange={(e) => {
                    setShowBedrooms(true);
                    setBedrooms(Number(e.target.value));
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `Domestic: No. of bedrooms: ${e.target.value}, No. of fuseboxes: ${fuseboxes}`,
                    }));
                  }}
                />
                <label htmlFor="5bedroom">5+</label>
              </div>
            </div>
            {showBedrooms && (
              <input
                min="1"
                className={style.input}
                type="number"
                placeholder="Enter no. of bedrooms"
                name="bedrooms"
                id="bedrooms"
                onChange={(e) => {
                  if (e.target.value === "") {
                    setBedrooms(5);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `Domestic: No. of bedrooms: 1, No. of fuseboxes: ${fuseboxes}`,
                    }));
                  } else {
                    setBedrooms(Number(e.target.value));
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `Domestic: No. of bedrooms: ${e.target.value}, No. of fuseboxes: ${fuseboxes}`,
                    }));
                  }
                }}
                onWheel={(e) => e.currentTarget.blur()}
              />
            )}
            <label htmlFor="fuseboxes">
              How many fuseboxes does your property contain?
            </label>
            <div className={style.allRadios}>
              <div className={style.radioBtns}>
                <input
                  defaultChecked
                  type="radio"
                  name="fuseboxesRadio"
                  value={1}
                  id="1fusebox"
                  onChange={(e) => {
                    setShowFuseboxes(false);
                    setFuseboxes(Number(e.target.value));
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `Domestic: No. of bedrooms: ${bedrooms}, No. of fuseboxes: ${e.target.value}`,
                    }));
                  }}
                />
                <label htmlFor="1fusebox">1</label>
              </div>
              <div className={style.radioBtns}>
                <input
                  type="radio"
                  name="fuseboxesRadio"
                  value={2}
                  id="2fusebox"
                  onChange={(e) => {
                    setShowFuseboxes(false);
                    setFuseboxes(Number(e.target.value));
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `Domestic: No. of bedrooms: ${bedrooms}, No. of fuseboxes: ${e.target.value}`,
                    }));
                  }}
                />
                <label htmlFor="2fusebox">2</label>
              </div>
              <div className={style.radioBtns}>
                <input
                  type="radio"
                  name="fuseboxesRadio"
                  value={3}
                  id="3fusebox"
                  onChange={(e) => {
                    setShowFuseboxes(false);
                    setFuseboxes(Number(e.target.value));
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `Domestic: No. of bedrooms: ${bedrooms}, No. of fuseboxes: ${e.target.value}`,
                    }));
                  }}
                />
                <label htmlFor="3fusebox">3</label>
              </div>
              <div className={style.radioBtns}>
                <input
                  type="radio"
                  name="fuseboxesRadio"
                  value={4}
                  id="4fusebox"
                  onChange={(e) => {
                    setShowFuseboxes(false);
                    setFuseboxes(Number(e.target.value));
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `Domestic: No. of bedrooms: ${bedrooms}, No. of fuseboxes: ${e.target.value}`,
                    }));
                  }}
                />
                <label htmlFor="4fusebox">4</label>
              </div>
              <div className={style.radioBtns}>
                <input
                  type="radio"
                  name="fuseboxesRadio"
                  value={5}
                  id="5fusebox"
                  onChange={(e) => {
                    setShowFuseboxes(true);
                    setFuseboxes(Number(e.target.value));
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `Domestic: No. of bedrooms: ${bedrooms}, No. of fuseboxes: ${e.target.value}`,
                    }));
                  }}
                />
                <label htmlFor="5fusebox">5+</label>
              </div>
            </div>
            {showFuseboxes && (
              <input
                min="1"
                className={style.input}
                type="number"
                placeholder="Enter no. of fuseboxes"
                name="fuseboxes"
                id="fuseboxes"
                onChange={(e) => {
                  if (e.target.value === "") {
                    setFuseboxes(6);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `Domestic: No. of bedrooms: ${bedrooms}, No. of fuseboxes: 1`,
                    }));
                  } else {
                    setFuseboxes(Number(e.target.value));
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `Domestic: No. of bedrooms: ${bedrooms}, No. of fuseboxes: ${e.target.value}`,
                    }));
                  }
                }}
                onWheel={(e) => e.currentTarget.blur()}
              />
            )}
            <div className={style.list}>
              <p className={style.priceLabel}>Estimated price:</p>
              <p className={style.price}>£{item.price}</p>
            </div>
            <p style={{ textAlign: "left", fontSize: "0.9em" }}>
              <em>
                Your final price will be calculated based on your location
                before you go to the booking summary.
              </em>
            </p>
          </>
        )}
      </div>
    </ServiceTemplateComponent>
  );
}
