import { useEffect, useState } from "react";
import style from "../styles/postcode-checker.module.scss";
import { getLatLonFromPostcode } from "../utils/funcs/lat-ln";
import { isInRadius } from "../utils/funcs/is-in-radius";
import axios from "axios";
import { FaMapMarkerAlt } from "react-icons/fa";

export default function PostcodeChecker(): React.ReactElement {
  const [valid, setValid] = useState(false);
  const [show, setShow] = useState(false);
  const [postcode, setPostcode] = useState("");
  const [results, setResults] = useState([]);

  const findPostcode = (postcode: any) => {
    axios
      .get(
        `https://ws.postcoder.com/pcw/autocomplete/find?query=${postcode}&country=uk&apikey=PCWNU-UC9YJ-R24LJ-MCMT6`
      )
      .then((res) => {
        setResults(res.data);
      });
  };

  const handleResultSelect = (result: any) => {
    if (result.id) {
      const splitAddress = result.summaryline.split(", ");
      const selectedPostcode = splitAddress[splitAddress.length - 1]
      setPostcode(selectedPostcode);
      axios
        .get(
          `https://ws.postcoder.com/pcw/PCWNU-UC9YJ-R24LJ-MCMT6/address/uk/${selectedPostcode}`
        )
        .then((res) => {
          if (res.data.length === 1) {
            getLatLonFromPostcode(res.data[0].postcode).then((data: any) => {
              setShow(true);
              if (data && isInRadius(data.lat, data.lon)) {
                setValid(true);
              } else setValid(false);
            });
            setResults([]);
            setPostcode(
              `${result.summaryline ? result.summaryline : ""}, ${
                result.locationsummary ? result.locationsummary : ""
              }`
            );
          } else if (res.data.length > 1) {
            setResults(res.data);
          } else {
            console.log("No addresses found: ", res.data)
          }
        }).catch((err) => {
          console.log({ err })
          return err
        });
    } else {
      setResults([]);
      setPostcode(
        `${result.summaryline ? result.summaryline : ""}, ${
          result.locationsummary ? result.locationsummary : ""
        }`
      );
      getLatLonFromPostcode(result.postcode).then((data: any) => {
        setShow(true);
        if (data && isInRadius(data.lat, data.lon)) {
          setValid(true);
        } else setValid(false);
      });
    }
  };

  useEffect(() => {
    // if (postcode === "") {
    //   setShowErr(false)
    // }
    // if (postcode.length < 5 && postcode.length > 0) {
    //   setShowErr(true)
    //   setErr("Please enter your full postcode.")
    // }
    //   if (postcode && postcode.length >= 5) {
    //     setShow(true);
    //     setShowErr(false)
    //     getLatLonFromPostcode(postcode)
    //       .then((data: any) => {
    //         data && isInRadius(data.lat, data.lon)
    //           ? setValid(true)
    //           : setValid(false);
    //       })
    //       .catch((err: any) => {
    //         console.log(err);
    //       });
    //   } else setShow(false);
  }, [postcode]);
 
  return (
    <div className={style.postcodeChecker}>
      <p className={style.instructions}>Check if we cover your address:</p>
      <div className={style.postcodeLookup}>
        <div className={style.inputContainer}>
          <input
            className={style.postcodeLookupInput}
            type="text"
            onChange={(e) => {
              if (e.target.value === "") {
                setShow(false);
              }
              setPostcode(e.target.value);
              findPostcode(e.target.value);
            }}
            value={postcode}
            placeholder="Enter your postcode"
            onFocus={(e) =>
              e.currentTarget.setSelectionRange(
                e.currentTarget.value.length,
                e.currentTarget.value.length
              )
            }
          />
          {results.length > 0 ? (
            <div className={style.postcodeResults}>
              {results.map((result: any, index: any) => {
                return (
                  <div key={index}>
                    <div className={style.spacer} />
                    <div className={style.result}>
                      <FaMapMarkerAlt className={style.icon} />
                      <div
                        onClick={(e) => {
                          handleResultSelect(result);
                        }}
                      >
                        {result.summaryline ? (
                          <span>
                            <strong>{result.summaryline}</strong>,{" "}
                          </span>
                        ) : (
                          ""
                        )}
                        {result.locationsummary ? (
                          <span>{result.locationsummary}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
      {show &&
        (valid ? (
          <p className={style.pcCoverageArea}>
            We cover you!{" "}
            <a href="/book-now" className={style.pcCoverageArea}>
              Book now
            </a>
            
          </p>
        ) : (
          <p className={style.pcCoverageArea}>
            Unfortunately, we don't cover you!
          </p>
        ))}
    </div>
  );
}
