import style from "../styles/error.module.scss";
import {MdError} from "react-icons/md"

export function ErrorMsgBelow({ msg }: any): React.ReactElement {
  return (
    <div className={style.wrapperBelow}>
      <MdError color="white" />
      <p className={style.msgBelow}>{msg}</p>
    </div>
  );
}

export function ErrorMsgAbove({ msg }: any): React.ReactElement {
  return (
    <div className={style.wrapperAbove}>
      <MdError color="white" />
      <p className={style.msgAbove}>{msg}</p>
    </div>
  );
}
