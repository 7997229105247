import React, { createContext, useState } from "react";

interface PaymentContextProps {
  paymentSuccess: boolean;
  setPaymentSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PaymentContext = createContext<PaymentContextProps>({
  paymentSuccess: false,
  setPaymentSuccess: () => {},
});

export const PaymentProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  return (
    <PaymentContext.Provider value={{ paymentSuccess, setPaymentSuccess }}>
      {children}
    </PaymentContext.Provider>
  );
};
