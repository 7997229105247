import { useNavigate } from "react-router-dom";
import Button from "../components/button";

import ContactBanner from "../components/contact-banner";
import AboutUs from "../components/who-are-we";
import style from "../styles/about-banner-img.module.scss";

export default function About() {
  let navigate = useNavigate();
  return (
    <div>
      <div className={style.banner}>
        <h1 className={style.title}>Expert Electrical Services</h1>
        <p className={style.bannerDescription}>
          Fast, reliable and professional EICR and PAT testing in and around
          London.
        </p>
        <div className={style.readMore}>
        <Button
                  onClick={() => {
                    let path = "/book-now";
                    navigate(path);
                  }}
                >
                  Book now &gt;
                </Button>
        </div>
      </div>
      <ContactBanner />
      <AboutUs />
    </div>
  );
}
