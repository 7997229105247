export const isInRadius = (lat: any, lng: any) => {
  // check for validity
  const earthRadius = 6371; // Radius of the earth in km
  const homeLat = 51.590004;
  const homeLng = 0.165094;

  const deg2rad = (deg: number) => {
    return deg * (Math.PI / 180);
  };

  var dLat = deg2rad(lat - homeLat);
  var dLon = deg2rad(lng - homeLng);
  var a =
    Math.pow(Math.sin(dLat / 2), 2) +
    Math.cos(deg2rad(homeLat)) *
      Math.cos(deg2rad(homeLng)) *
      Math.pow(Math.sin(dLon / 2), 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = earthRadius * c; // Distance in km

  return d <= 50 ? true : false;
};
