import { useEffect, useState } from "react";
import ServiceTemplateComponent from "./template";
import { formatEmailDate } from "../../utils/funcs/google-calendar";
import fireAlarm from "../../assets/firealarm.jpg";
import style from "../../styles/service-prices.module.scss";

export default function FireAlarm() {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  if (tomorrow.getDay() === 0) {
    // Sunday
    tomorrow.setDate(tomorrow.getDate() + 1); // Move to Monday
  }
  

  const [item, setItem] = useState({
    bookingID: " ",
    productName: "Fire and Smoke Alarm Testing",
    price: 99,
    productInfo: "1-5 alarms",
    customerName: "",
    customerEmail: "",
    customerNumber: "",
    start: "",
    end: "",
    date: tomorrow,
    emailDate: formatEmailDate(tomorrow),
    emailStartTime: "",
    access: "Access provided by: Customer on booking",
    address: { line1: "", line2: " ", postTown: "", postcode: "" },
    finalPrice: 0
  });
  const [inputAlarms, setInputAlarms] = useState(0);
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
      let productInfo = "1-5 alarms";
      let price = 99;

      if (inputAlarms >= 1 && inputAlarms <= 5) {
        productInfo = "1-5 alarms";
        price = 99;
      } else if (inputAlarms >= 6 && inputAlarms <= 10) {
        productInfo = "6-10 alarms";
        price = 109;
      } else if (inputAlarms >= 11 && inputAlarms <= 20) {
        productInfo = "11-20 alarms";
        price = 119;
      } else if (inputAlarms >= 21 && inputAlarms <= 30) {
        productInfo = "21-30 alarms";
        price = 129;
      } else if (inputAlarms >= 31 && inputAlarms <= 40) {
        productInfo = "31-40 alarms";
        price = 139;
      } else if (inputAlarms > 40) {
        const minusTen = inputAlarms - 10;
        if (minusTen > 0) {
          const nearestTen = Math.ceil(minusTen / 10) * 10;
          const multiplier = nearestTen / 10;
          productInfo = `${inputAlarms} alarms`;
          price = 139 + 12 * (multiplier - 3);
        }

      setItem((prevItems) => ({
        ...prevItems,
        productInfo,
        price,
      }));
    }
  }, [inputAlarms]);
  
  
  return (
    <>
      <ServiceTemplateComponent
        item={item}
        setItem={setItem}
        backgroundImage={fireAlarm}
        tomorrow={tomorrow}
        serviceDescription="This service includes inspection of fire and smoke alarm systems, guaranteeing early threat detection. We also test emergency lighting darining to ensure reliable illumination during power outages or crises by maintaining exit signs and battery backups. These services collectively ensure your peace of mind, protect lives and property, and maintain a safe environment, offering a comprehensive solution for your electrical and safety needs."
      >

          <>
            <label htmlFor="alarms">
              How many alarms do you require testing for?
            </label>

            <div className={style.allMinorWorksRadios}>
              <div className={style.minorWorksRadioBtns}>
                <input
                  defaultChecked
                  type="radio"
                  name="alarmsRadio"
                  value={"1-5"}
                  id="1-5alarms"
                  onChange={(e) => {
                    setShowInput(false);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${e.target.value} alarms`,
                      price: 99,
                    }));
                  }}
                />
                <label htmlFor="1-5alarms">1-5 alarms</label>
              </div>
              <div className={style.minorWorksRadioBtns}>
                <input
                  type="radio"
                  name="alarmsRadio"
                  value={"6-10"}
                  id="6-10alarms"
                  onChange={(e) => {
                    setShowInput(false);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${e.target.value} alarms`,
                      price: 109,
                    }));
                  }}
                />
                <label htmlFor="6-10alarms">6-10 alarms</label>
              </div>
              <div className={style.minorWorksRadioBtns}>
                <input
                  type="radio"
                  name="alarmsRadio"
                  value={"11-20"}
                  id="11-20alarms"
                  onChange={(e) => {
                    setShowInput(false);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${e.target.value} alarms`,
                      price: 119,
                    }));
                  }}
                />
                <label htmlFor="11-20alarms">11-20 alarms</label>
              </div>
              <div className={style.minorWorksRadioBtns}>
                <input
                  type="radio"
                  name="alarmsRadio"
                  value={"21-30"}
                  id="21-30alarms"
                  onChange={(e) => {
                    setShowInput(false);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${e.target.value} alarms`,
                      price: 129,
                    }));
                  }}
                />
                <label htmlFor="21-30alarms">21-30 alarms</label>
              </div>
              <div className={style.minorWorksRadioBtns}>
                <input
                  type="radio"
                  name="alarmsRadio"
                  value={"31"}
                  id="31+alarms"
                  onChange={(e) => {
                    setShowInput(true);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${e.target.value} alarms`,
                      price: 139,
                    }));
                  }}
                />
                <label htmlFor="31+alarms">31+ alarms</label>
              </div>
            </div>
            {showInput && (
              <>
                <input
                  min="1"
                  type="number"
                  placeholder="No. of alarms"
                  id="alarmsQuant"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setInputAlarms(31);
                      setItem((prevItem) => ({
                        ...prevItem,
                        productInfo: "31 alarms",
                      }));
                    } else {
                      setInputAlarms(+e.target.value);
                      setItem((prevItem) => ({
                        ...prevItem,
                        productInfo: `${e.target.value} alarms`,
                      }));
                    }
                  }}
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </>
            )}
          </>
          <div className={style.list}>
              <p className={style.priceLabel}>Estimated price:</p>
              <p className={style.price}>£{item.price}</p>
            </div>
            <p style={{ textAlign: "left", fontSize: "0.9em" }}>
              <em>
                Your final price will be calculated based on your location
                before you go to the booking summary.
              </em>
            </p>
      </ServiceTemplateComponent>
    </>
  );
}
