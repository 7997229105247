import axios from "axios";

export const getRole = ({serverURL, setRole, user}:any) => {
  try {
    axios
    .post(`${serverURL}/userRole`, {
      email: user?.emailAddresses[0].emailAddress,
    })
    .then((res:any) => {
      setRole(res.data.role);
    });
  } catch (err) {
        console.log(err);
      }
}