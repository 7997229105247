import { Link } from "react-router-dom";

import style from "../styles/footer.module.scss";

export default function Footer(): React.ReactElement {
  return (
    <div className={style.footer}>
      <Link to="/contact-us">
        Contact us at info@teckengineers.co.uk © {new Date().getFullYear()} -
        Teck Engineers
      </Link>
    </div>
  );
}
