// date should be in the format: "21/03/2024", use createShortDate for this
export const createDisplayDate = (date: string) => {
  const parts = date.split("/"); // Split the string by '/'

    const day = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`).toLocaleString(
      "en-GB",
      { weekday: "short" }
    ); // Get the abbreviated day name
    const dateOfMonth = parts[0]; // Get the date of the month
    const month = new Date(
      `${parts[2]}-${parts[1]}-${parts[0]}`
    ).toLocaleString("en-GB", { month: "short" }); // Get the abbreviated month name
    const year = parts[2];

    const formattedDate = {
      day: day,
      dateOfMonth: dateOfMonth,
      month: month,
      year: year,
  }

    return formattedDate;
};
