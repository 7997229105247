import axios from "axios";
import { BookingWithCert } from "../../../types/BookingWithCert";
import { createShortDate } from "../createShortDate";

export const getCustomerBookingsWithCerts = async ({user, serverURL, setPastBookings, setFutureBookings, setLoading}: any) => {
    try {
        if (user) {
          const response = await axios.post(`${serverURL}/bookings`, {
            email: user?.emailAddresses[0].emailAddress,
          });
         
          const today = new Date();
          const pastBookingsTemp: BookingWithCert[] = [];
          const futureBookingsTemp: BookingWithCert[] = [];

          
          // setCertificates(response.data.matchingEICRs);
          if (typeof response.data !== "string") {
            const data = response.data.map((booking: any) => {
              const copy = { ...booking };
              if (copy.certificate && copy.certificate.quoteItems) {
                copy.certificate.quoteItemsArr = Object.values(
                  copy.certificate.quoteItems
                );
              }
      
              return copy;
            });
            data.forEach((bookingWithCert: BookingWithCert) => {
              const bookingDate = new Date(bookingWithCert.booking.date);
              bookingWithCert.booking.displayDate = createShortDate(bookingDate)
              if (bookingDate < today || bookingWithCert.booking.cancelled) {
                pastBookingsTemp.push(bookingWithCert);
              } else {
                futureBookingsTemp.push(bookingWithCert);
              }
            });
  
            futureBookingsTemp.sort((a, b) => {
              const dateA = new Date(a.booking.date).getTime();
              const dateB = new Date(b.booking.date).getTime();
              return dateA - dateB;
            });
            setPastBookings(pastBookingsTemp);
            setFutureBookings(futureBookingsTemp);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      } catch (err) {
      console.log(err);
      setLoading(false)
      }
}