import style from "../styles/card.module.scss";
import DisplayDate from "./display-date";
import downArrow from "../assets/downArrow.png";
import upArrow from "../assets/upArrow.png";
import { useEffect, useState } from "react";
import { createShortDate } from "../utils/funcs/createShortDate";

export default function Card({
  children,
  date,
  currentSelected,
  bookingID,
  columns,
}: any) {
  const [columnsToDisplay, setColumnsToDisplay] = useState<any[][]>();

  const objToArr = (object: any) => {
    const arr = [];
    for (const key in object) {
      arr.push([key, object[key]]);
    }
    return arr;
  };

  useEffect(() => {
    setColumnsToDisplay(objToArr(columns));
  }, [columns]);

  return (
    <>
      <div className={style.bookingCard}>
        <div className={style.booking}>
          <div className={style.column1}>
            <DisplayDate date={createShortDate(new Date(date))} />
          </div>
          {columnsToDisplay?.map((column, index) => {
            return (
              <div className={column[0] === "status" ? style[column[1]] : style[column[0]]} key={index}>
                <p>{column[1]}</p>
              </div>
            );
          })}

          {currentSelected !== bookingID ? (
            <img
              src={downArrow}
              className={style.downArrow}
              alt="down Arrow head"
            />
          ) : (
            <img
              src={upArrow}
              className={style.downArrow}
              alt="up Arrow head"
            />
          )}
        </div>
        {currentSelected === bookingID && (
          <div className={style.extraInfo}>{children}</div>
        )}
      </div>
    </>
  );
}
