import { useMemo } from "react";
import style from "../styles/quote-summary-card.module.scss";
import { Quote } from "../types/QuoteItems";

export default function QuoteSummaryCard({ quoteItems, totalPrice, timeTaken }: any) {
  const formattedQuoteItems = useMemo(() => {
    const items: Quote[] = [];
    for (const key in quoteItems) {
      items.push(quoteItems[key]);
    }
    return items;
  }, [quoteItems]);

  return (
    <>
      <p className={style.status}>Status: <span className={style.fail}>FAIL</span></p>
      <div className={style.bookingInfo}>
        <table>
          <thead>
            <tr>
              <th className={style.thDescription}>Description</th>
              <th className={style.th}>Quantity</th>
              <th className={style.th}>Price</th>
            </tr>
          </thead>
          <tbody>
            {formattedQuoteItems.map((item, index) => {
              return (
                <tr key={index}>
                  <td className={style.td}>{item.description}</td>
                  <td className={style.td}>{item.quantity}</td>
                  <td className={style.td}>£{item.price}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
        <div className={style.priceContainer}>
          {!!totalPrice && (
            <div className={style.price}>
              <h4>Total Price:</h4>
              <p>£{totalPrice}</p>
            </div>
          )}
        </div>
        <br />
        <p>Duration of job: <span style={{fontWeight:"600"}}>{timeTaken}</span></p>
      </div>
    </>
  );
}
