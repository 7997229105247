import axios from "axios";
import { Booking } from "../../../types/BookingWithCert";

export const sendExpiryEmail = (serverURL: string, booking: Booking) => {
  return axios
    .post(`${serverURL}/send-expiry-email`, booking)
      .then((res) => {
          return res
      })
    .catch((err) => console.log(err));
};
