import { useEffect, useState } from "react";
import style from "../styles/map.module.scss";
import { isInRadius } from "../utils/funcs/is-in-radius";
import { getLatLonFromPostcode } from "../utils/funcs/lat-ln";
import { ErrorMsgBelow, ErrorMsgAbove } from "./required-error-messages";
import axios from "axios";
import { FaMapMarkerAlt } from "react-icons/fa";
import { calculateDistance } from "../utils/funcs/calculate-distance";
import { capitaliseFirstLetter } from "../utils/funcs/capitalise-first-letter";
import { capitaliseAllChars } from "../utils/funcs/capitalise-all-chars";
export default function Location({
  item,
  setItem,
  isValid,
  setIsValid,
  errorMsgs,
  refs,
  setErrorMsgs,
  setFinalPrice,
  access,
  setAccess,
}: any) {
  const [show, setShow] = useState(false);
  const [results, setResults] = useState([]);
  const [postcode, setPostcode] = useState("");

  const findPostcode = (postcode: any) => {
    axios
      .get(
        `https://ws.postcoder.com/pcw/autocomplete/find?query=${postcode}&country=uk&apikey=PCWNU-UC9YJ-R24LJ-MCMT6`
      )
      .then((res) => {
        setResults(res.data);
      });
  };

  const handleResultSelect = (result: any) => {
    if (result.id) {
      const splitAddress = result.summaryline.split(", ");
      const selectedPostcode = splitAddress[splitAddress.length - 1];
      setPostcode(selectedPostcode);
      axios
        .get(
          `https://ws.postcoder.com/pcw/autocomplete/retrieve/?id=${result.id}&query=${selectedPostcode}&country=uk&apikey=PCWNU-UC9YJ-R24LJ-MCMT6`
        )
        .then((res) => {
          if (res.data.length === 1) {
            getLatLonFromPostcode(res.data[0].postcode)
              .then((data: any) => {
                data && isInRadius(data.lat, data.lon)
                  ? setIsValid(true)
                  : setIsValid(false);
              })
              .catch((err: any) => {
                console.log(err);
              });

            setResults([]);
            setItem((prevItem: any) => ({
              ...prevItem,
              address: {
                ...prevItem.address,
                line1: res.data[0].subbuildingname
                  ? res.data[0].premise
                  : `${res.data[0].number} ${res.data[0].street}`,
                line2: res.data[0].dependentlocality ?? res.data[0].street,
                postTown: res.data[0].posttown,
                postcode: res.data[0].postcode,
              },
            }));
            setErrorMsgs((prev: any) => ({
              ...prev,
              address1Error: "",
              postTownError: "",
              postcodeError: "",
            }));
          } else if (res.data.length > 1) {
            setResults(res.data);
          }
        });
    } else {
      setResults([]);
      setPostcode(`${result.summaryline}, ${result.locationsummary}`);
      getLatLonFromPostcode(result.postcode)
        .then((data: any) => {
          data && isInRadius(data.lat, data.lon)
            ? setIsValid(true)
            : setIsValid(false);
        })
        .catch((err: any) => {
          console.log(err);
        });
      if (result.dependentlocality) {
        setItem((prevItem: any) => ({
          ...prevItem,
          address: {
            ...prevItem.address,
            line2: result.dependentlocality,
          },
        }));
      } else {
        setItem((prevItem: any) => ({
          ...prevItem,
          address: {
            ...prevItem.address,
            line2: " ",
          },
        }));
      }
      setErrorMsgs((prev: any) => ({
        ...prev,
        address1Error: "",
        postTownError: "",
        postcodeError: "",
      }));
      setItem((prevItem: any) => ({
        ...prevItem,
        address: {
          ...prevItem.address,
          line1: `${result.number} ${result.street}`,
          postTown: result.posttown,
          postcode: result.postcode,
        },
      }));
    }
  };

  useEffect(() => {
    isValid === null || item.address.postcode === ""
      ? setShow(false)
      : setShow(true);
  }, [isValid, item.address?.postcode]);

  useEffect(() => {
    if (item.address?.postcode) {
      getLatLonFromPostcode(item.address.postcode)
        .then((data: any) => {
          if (data && isInRadius(data.lat, data.lon)) {
            const distance = Math.floor(calculateDistance(data.lat, data.lon));
            const travelFee = distance > 7 ? (distance - 7) * 1.5 : 0;
            setFinalPrice(item.price + travelFee);
            setIsValid(true);
          } else setIsValid(false);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, [item.address?.postcode, setIsValid, item.price, setFinalPrice]);

  return (
    <>
      <h3 className={style.heading}>Your details</h3>
      <div className={style.divider} />
      <input
        placeholder="Full name*"
        onChange={(e) => {
          setItem((prevItem: any) => (
            {
            ...prevItem,
            customerName: capitaliseFirstLetter(e.target.value),
          }));
          setErrorMsgs((prev: any) => ({ ...prev, nameError: "" }));
        }}
        required
        type="text"
        ref={refs.name}
      />
      {errorMsgs.nameError && <ErrorMsgBelow msg={errorMsgs.nameError} />}
      <input
        type="email"
        required
        placeholder="Email*"
        onChange={(e) => {
          setItem((prevItem: any) => ({
            ...prevItem,
            customerEmail: e.target.value,
          }));
          setErrorMsgs((prev: any) => ({ ...prev, emailError: "" }));
        }}
        ref={refs.email}
      />
      {errorMsgs.emailError && <ErrorMsgBelow msg={errorMsgs.emailError} />}
      <input
        placeholder="Contact number*"
        onChange={(e) => {
          setItem((prevItem: any) => ({
            ...prevItem,
            customerNumber: e.target.value,
          }));
          setErrorMsgs((prev: any) => ({ ...prev, telError: "" }));
        }}
        required
        type="number"
        ref={refs.tel}
        onWheel={(e) => e.currentTarget.blur()}
      />
      {errorMsgs.telError && <ErrorMsgBelow msg={errorMsgs.telError} />}
      <label className={style.addressInstruction}>
        Please enter the appointment address.
      </label>
      <div className={style.postcodeLookup}>
        <div className={style.inputContainer}>
          <input
            className={style.postcodeLookupInput}
            type="text"
            onChange={(e) => {
              setPostcode(e.target.value);
              findPostcode(e.target.value);
            }}
            value={postcode}
            placeholder="Enter your postcode"
          />
          {results.length > 0 ? (
            <div className={style.postcodeResults}>
              {results.map((result: any, index: any) => {
                return (
                  <div key={index}>
                    <div className={style.spacer} />
                    <div className={style.result}>
                      <FaMapMarkerAlt className={style.icon} />
                      <div
                        onClick={(e) => {
                          handleResultSelect(result);
                        }}
                      >
                        {result.summaryline ? (
                          <span>
                            <strong>{result.summaryline}</strong>,{" "}
                          </span>
                        ) : (
                          ""
                        )}
                        {result.locationsummary ? (
                          <span>{result.locationsummary}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
      <div className={style.address}>
        <label>Address Line 1*</label>
        {errorMsgs.address1Error && (
          <ErrorMsgAbove msg={errorMsgs.address1Error} />
        )}
        <input
          type="text"
          value={item.address?.line1}
          onChange={(e) => {
            setItem((prevItem: any) => ({
              ...prevItem,
              address: { ...prevItem.address, line1: capitaliseFirstLetter(e.target.value) },
            }));
            setErrorMsgs((prev: any) => ({ ...prev, address1Error: "" }));
          }}
          required
          ref={refs.address1}
        />
        <label>Address Line 2</label>
        <input
          type="text"
          value={item.address?.line2}
          onChange={(e) => {
            if (e.target.value === "") {
              setItem((prevItem: any) => ({
                ...prevItem,
                address: { ...prevItem.address, line2: " " },
              }));
            } else {
              setItem((prevItem: any) => ({
                ...prevItem,
                address: { ...prevItem.address, line2: capitaliseFirstLetter(e.target.value) },
              }));
            }
          }}
        />
        <label>Town/City*</label>
        {errorMsgs.postTownError && (
          <ErrorMsgAbove msg={errorMsgs.postTownError} />
        )}
        <input
          type="text"
          value={item.address?.postTown}
          onChange={(e) => {
            setItem((prevItem: any) => ({
              ...prevItem,
              address: { ...prevItem.address, postTown: capitaliseFirstLetter(e.target.value) },
            }));
            setErrorMsgs((prev: any) => ({ ...prev, postTownError: "" }));
          }}
          required
          ref={refs.postTown}
        />
        <label>Postcode*</label>
        {errorMsgs.postcodeError && (
          <ErrorMsgAbove msg={errorMsgs.postcodeError} />
        )}
        <input
          type="text"
          value={item.address?.postcode}
          onChange={(e) => {
            setItem((prevItem: any) => ({
              ...prevItem,
              address: { ...prevItem.address, postcode: capitaliseAllChars(e.target.value) },
            }));
            setErrorMsgs((prev: any) => ({ ...prev, postcodeError: "" }));
          }}
          required
          ref={refs.postcode}
        />

        {show && !isValid && (
          <p className={style.notCoverageArea}>
            Your address is not within our coverage area
          </p>
        )}

        <label htmlFor="access">Who is providing access to the property?</label>
        <div className={style.allRadios}>
          <div className={style.accessRadioBtns}>
            <input
              name="access"
              type="radio"
              value="No"
              id="no"
              defaultChecked
              onChange={(e) => {
                setItem((prevItem: any) => ({
                  ...prevItem,
                  access: "Access provided by: customer",
                }));
                setAccess((prev: any) => ({ ...prev, providedByOther: false }));
              }}
            />
            <label htmlFor="no">Myself</label>
          </div>
          <div className={style.accessRadioBtns}>
            <input
              name="access"
              type="radio"
              value="Yes"
              id="yes"
              onChange={() => {
                setItem((prevItem: any) => ({
                  ...prevItem,
                  access: `Access provided by: Name: ${capitaliseFirstLetter(access.info.name)}; Email: ${access.info.email}; Number: ${access.info.number}`,
                }));
                setAccess((prev: any) => ({ ...prev, providedByOther: true }));
              }}
            />
            <label htmlFor="yes">Someone else</label>
          </div>
        </div>
        {access.providedByOther && (
          <>
            <label htmlFor="accessInfo">
              Please provide the name, email and number of the person allowing
              access to the property:
            </label>
            <input
              ref={refs.accessName}
              required={access.providedByOther}
              name="access"
              value={access.info.name}
              id="accessName"
              onChange={(e) => {
                setAccess((prev: any) => {
                  const copy = { ...prev };
                  copy.info.name = capitaliseFirstLetter(e.target.value);
                  return copy;
                });
                setErrorMsgs((prev: any) => ({ ...prev, accessNameError: "" }));
                setItem((prevItem: any) => ({
                  ...prevItem,
                  accessName: e.target.value,
                  access: `Access provided by: Name: ${capitaliseFirstLetter(e.target.value)}; Email: ${access.info.email}; Number: ${access.info.number}`,
                }));
              }}
              placeholder="Name*"
            />
            {errorMsgs.accessNameError && (
              <ErrorMsgBelow msg={errorMsgs.accessNameError} />
            )}
            <input
              ref={refs.accessEmail}
              required={access.providedByOther}
              name="access"
              value={access.info.email}
              id="accessEmail"
              onChange={(e) => {
                setAccess((prev: any) => {
                  const copy = { ...prev };
                  copy.info.email = e.target.value;
                  return copy;
                });
                setErrorMsgs((prev: any) => ({
                  ...prev,
                  accessEmailError: "",
                }));
                setItem((prevItem: any) => ({
                  ...prevItem,
                  accessEmail: e.target.value,
                  access: `Access provided by: Name: ${access.info.name}; Email: ${e.target.value}; Number: ${access.info.number}`,
                }));
              }}
              placeholder="Email*"
            />
            {errorMsgs.accessEmailError && (
              <ErrorMsgBelow msg={errorMsgs.accessEmailError} />
            )}
            <input
              ref={refs.accessNumber}
              required={access.providedByOther}
              name="access"
              value={access.info.number}
              id="accessNumber"
              onChange={(e) => {
                setAccess((prev: any) => {
                  const copy = { ...prev };
                  copy.info.number = e.target.value;
                  return copy;
                });
                setErrorMsgs((prev: any) => ({
                  ...prev,
                  accessNumberError: "",
                }));
                setItem((prevItem: any) => ({
                  ...prevItem,
                  accessNumber: e.target.value,
                  access: `Access provided by: Name: ${access.info.name}; Email: ${access.info.email}; Number: ${e.target.value}`,
                }));
              }}
              placeholder="Contact Number*"
            />
            {errorMsgs.accessNumberError && (
              <ErrorMsgBelow msg={errorMsgs.accessNumberError} />
            )}
          </>
        )}
      </div>
    </>
  );
}

// FLAT:
// buildingname: "Firth House";
// county: "Greater London";
// postcode: "E2 6AR";
// posttown: "London";
// premise: "Flat 1, Firth House";
// street: "Turin Street";
// subbuildingname: "Flat 1";
// summaryline: "Flat 1, Firth House, Turin Street, London, Greater London, E2 6AR";

// HOUSE
// county: "Essex"
// dependentlocality: "Linford"
// number: "5"
// postcode: "SS17 0RB"
// posttown: "Stanford-Le-Hope"
// premise: "5"
// street: "High Ash Close"
// summaryline: "5 High Ash Close, Linford, Stanford-Le-Hope
