import { useEffect, useState } from "react";
import ServiceTemplateComponent from "./template";
import { formatEmailDate } from "../../utils/funcs/google-calendar";
import remedial from "../../assets/remedial.webp";
import style from "../../styles/service-prices.module.scss";

export default function RemedialWorks() {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  if (tomorrow.getDay() === 0) {
    // Sunday
    tomorrow.setDate(tomorrow.getDate() + 1); // Move to Monday
  } 
  const [serviceRequired, setServiceRequired] = useState("Earth Bonding");
  const [item, setItem] = useState({
    bookingID: " ",
    productName: "Electrical Remedial Works",
    price: 120,
    productInfo: `Earth Bonding - 5m`,
    customerName: "",
    customerEmail: "",
    customerNumber: "",
    start: "",
    end: "",
    date: tomorrow,
    emailDate: formatEmailDate(tomorrow),
    emailStartTime: "",
    access: "Access provided by: Customer on booking",
    address: { line1: "", line2: " ", postTown: "", postcode: "" },
    finalPrice: 0
  });

  //earth bonding
  const [crossBonding, setCrossBonding] = useState(false);

  // consumer unit
  const [circuits, setCircuits] = useState("12 circuits");
  const [unitsQuantity, setUnitsQuantity] = useState(1);
  const twelveCircuits = 620;
  const sixteenCircuits = 700;

  // circuit outlet
  const [outletLightFittings, setOutletLightFittings] = useState<number>(0);
  const [outletSockets, setOutletSockets] = useState<number>(0);
  const [outletSwitches, setOutletSwitches] = useState<number>(0);
  const [outletBlankingPlates, setOutletBlankingPlates] = useState<number>(0);
  // Define the prices for each option
  const lightFittingsPrice = 30;
  const socketsPrice = 30;
  const switchesPrice = 25;
  const blankingPlatesPrice = 22;
  // Calculate the combined price whenever any of the options change
  useEffect(() => {
    if (serviceRequired === "Circuit Outlets") {
      const totalPrice =
        100 +
        outletLightFittings * lightFittingsPrice +
        outletSockets * socketsPrice +
        outletSwitches * switchesPrice +
        outletBlankingPlates * blankingPlatesPrice;

      setItem((prevItem) => ({
        ...prevItem,
        productInfo: `${serviceRequired} - ${outletLightFittings} light fittings, ${outletSockets} sockets, ${outletSwitches} switches, ${outletBlankingPlates} blanking plates`,
        price: totalPrice,
      }));
    }
  }, [
    outletLightFittings,
    outletSockets,
    outletSwitches,
    outletBlankingPlates,
    serviceRequired,
  ]);

  // works to existing CU
  const [minorRewiring, setMinorRewiring] = useState("Yes");
  const [CUoptions, setCUoptions] = useState<string[]>([]);
  const [circuitLabelling, setCircuitLabelling] = useState<string[]>([]);
  const [rcd, setRcd] = useState(0);
  const [mcb, setMcb] = useState(0);
  const [rcbo, setRcbo] = useState(0);
  useEffect(() => {
    if (serviceRequired === "Works/Repairs to Existing Consumer Unit") {
      const totalPrice =
        (minorRewiring === "Yes" ? 110 : 0) +
        CUoptions.length * 200 +
        circuitLabelling.length * 100 +
        rcd * 110 +
        mcb * 60 +
        rcbo * 80;

      const CUWork = CUoptions.length > 0 ? CUoptions.join(", ") : "None";
      const labellingWork =
        circuitLabelling.length > 0 ? circuitLabelling.join(", ") : "None";

      setItem((prevItem) => ({
        ...prevItem,
        productInfo: `${serviceRequired} - ${minorRewiring} minor rewiring required; Labelling: ${labellingWork}; Devices: ${rcd} RCD, ${mcb} MCB, ${rcbo} RCBO; Other: ${CUWork}`,
        price: totalPrice,
      }));
    }
  }, [
    serviceRequired,
    minorRewiring,
    CUoptions,
    circuitLabelling,
    rcd,
    mcb,
    rcbo,
  ]);

  // Full rewiring
  const [rewiringPrice, setRewiringPrice] = useState(0);

  return (
    <>
      <ServiceTemplateComponent
        item={item}
        setItem={setItem}
        backgroundImage={remedial}
        tomorrow={tomorrow}
        serviceDescription="Our Electrical Remedial Works service offers expert electrical repairs and enhancements for homes and businesses. We diagnose and resolve issues, upgrade electrical systems, and ensure safety and compliance."
        serviceRequired={serviceRequired}
      >
        <label htmlFor="remedial">
          What type of electrical remedial work do you require?
        </label>
        <div className={style.allMinorWorksRadios}>
          <div className={style.minorWorksRadioBtns}>
            <input
              defaultChecked
              type="radio"
              name="remedialRadio"
              value={"Earth Bonding"}
              id="earthBonding"
              onChange={(e) => {
                setServiceRequired("Earth Bonding");
                setItem((prevItem) => ({
                  ...prevItem,
                  productInfo: `Earth Bonding - 5m`,
                  price: 120,
                }));
              }}
            />
            <label htmlFor="earthBonding">Earth Bonding</label>
          </div>
          <div className={style.minorWorksRadioBtns}>
            <input
              type="radio"
              name="remedialRadio"
              value={"New Consumer Unit"}
              id="newConsumerUnit"
              onChange={(e) => {
                setServiceRequired("New Consumer Unit");
                setItem((prevItem) => ({
                  ...prevItem,
                  productInfo: `New Consumer Unit 12 circuits - 1 unit`,
                  price: 620,
                }));
              }}
            />
            <label htmlFor="newConsumerUnit">New Consumer Unit</label>
          </div>
          <div className={style.minorWorksRadioBtns}>
            <input
              type="radio"
              name="remedialRadio"
              value={"Circuit Outlets"}
              id="circuitOutlets"
              onChange={(e) => {
                setServiceRequired("Circuit Outlets");
                setItem((prevItem) => ({
                  ...prevItem,
                  productInfo: `Circuit Outlets`,
                  price: 100,
                }));
              }}
            />
            <label htmlFor="circuitOutlets">Circuit Outlets</label>
          </div>
          <div className={style.minorWorksRadioBtns}>
            <input
              type="radio"
              name="remedialRadio"
              value={"Fail - Further Investigation (FI)"}
              id="failFI"
              onChange={(e) => {
                setServiceRequired("Fail - Further Investigation (FI)");
                setItem((prevItem) => ({
                  ...prevItem,
                  productInfo: `Fail - Further Investigation (FI)`,
                  price: 120,
                }));
              }}
            />
            <label htmlFor="failFI">Fail - Further Investigation (FI)</label>
          </div>
          <div className={style.minorWorksRadioBtns}>
            <input
              type="radio"
              name="remedialRadio"
              value={"Works/Repairs to Existing Consumer Unit"}
              id="failFI"
              onChange={(e) => {
                setServiceRequired("Works/Repairs to Existing Consumer Unit");
                setItem((prevItem) => ({
                  ...prevItem,
                  productInfo: `Works/Repairs to Existing Consumer Unit`,
                  price: 100,
                }));
              }}
            />
            <label htmlFor="failFI">
              Works/Repairs to Existing Consumer Unit
            </label>
          </div>
          <div className={style.minorWorksRadioBtns}>
            <input
              type="radio"
              name="remedialRadio"
              value={"Full Rewiring"}
              id="fullRewiring"
              onChange={(e) => {
                setRewiringPrice(3000);
                setServiceRequired("Full Rewiring");
                setItem((prevItem) => ({
                  ...prevItem,
                  productInfo: `Full Rewiring: Free quote - 1 bedroom `,
                  price: 0,
                }));
              }}
            />
            <label htmlFor="fullRewiring">Full Rewiring</label>
          </div>
        </div>

        {/* 
        EARTH
        BONDING
        */}
        {serviceRequired === "Earth Bonding" && (
          <>
            <div className={style.allMinorWorksRadios}>
              <p
                style={{
                  textAlign: "left",
                  marginTop: "18px",
                  fontSize: "20px",
                }}
              >
                {serviceRequired}
              </p>
              <label htmlFor="remedial">
                Do you require Cross Earth Bonding between pipes?
              </label>
              <div className={style.minorWorksRadioBtns}>
                <input
                  defaultChecked
                  type="radio"
                  name="crossRadio"
                  value={"Cross Earth Bonding Required"}
                  id="crossRequired"
                  onChange={(e) => {
                    setCrossBonding(false);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${serviceRequired} - 5m`,
                      price: 120,
                    }));
                  }}
                />
                <label htmlFor="crossRequired">No</label>
              </div>
              <div className={style.minorWorksRadioBtns}>
                <input
                  type="radio"
                  name="crossRadio"
                  value={"Cross Earth Bonding Required"}
                  id="crossRequired"
                  onChange={(e) => {
                    setCrossBonding(true);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${serviceRequired} - ${e.target.value}`,
                      price: 115,
                    }));
                  }}
                />
                <label htmlFor="crossRequired">Yes</label>
              </div>
            </div>
            {!crossBonding && (
              <div className={style.allMinorWorksRadios}>
                <label htmlFor="remedial">
                  What length of cable do you require?
                </label>
                <div className={style.minorWorksRadioBtns}>
                  <input
                    defaultChecked
                    type="radio"
                    name="earthRadio"
                    value={"5m"}
                    id="5m"
                    onChange={(e) => {
                      setItem((prevItem) => ({
                        ...prevItem,
                        productInfo: `${serviceRequired} - ${e.target.value}`,
                        price: 75,
                      }));
                    }}
                  />
                  <label htmlFor="5m">5m</label>
                </div>
                <div className={style.minorWorksRadioBtns}>
                  <input
                    type="radio"
                    name="earthRadio"
                    value={"10m"}
                    id="10m"
                    onChange={(e) => {
                      setItem((prevItem) => ({
                        ...prevItem,
                        productInfo: `${serviceRequired} - ${e.target.value}`,
                        price: 100,
                      }));
                    }}
                  />
                  <label htmlFor="10m">10m</label>
                </div>
                <div className={style.minorWorksRadioBtns}>
                  <input
                    type="radio"
                    name="earthRadio"
                    value={"15m+"}
                    id="15m+"
                    onChange={(e) => {
                      setItem((prevItem) => ({
                        ...prevItem,
                        productInfo: `${serviceRequired} - ${e.target.value}`,
                        price: 140,
                      }));
                    }}
                  />
                  <label htmlFor="15m+">15m+</label>
                </div>
              </div>
            )}
          </>
        )}

        {/* 
       CONSUMER
       UNIT
        */}

        {serviceRequired === "New Consumer Unit" && (
          <>
            <div className={style.allMinorWorksRadios}>
              <p
                style={{
                  textAlign: "left",
                  marginTop: "18px",
                  fontSize: "20px",
                }}
              >
                {serviceRequired}
              </p>
              <label htmlFor="remedial">Number of circuits:</label>
              <div className={style.minorWorksRadioBtns}>
                <input
                  defaultChecked
                  type="radio"
                  name="consumerUnitRadio"
                  value={"12 circuits"}
                  id="12circuits"
                  onChange={(e) => {
                    setUnitsQuantity(1);
                    setCircuits(e.target.value);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${serviceRequired} ${e.target.value} - 1 unit`,
                      price: twelveCircuits,
                    }));
                  }}
                />
                <label htmlFor="12circuits">Up to 12 circuits</label>
              </div>
              <div className={style.minorWorksRadioBtns}>
                <input
                  type="radio"
                  name="consumerUnitRadio"
                  value={"16 circuits"}
                  id="16circuits"
                  onChange={(e) => {
                    setUnitsQuantity(1);
                    setCircuits(e.target.value);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${serviceRequired} ${e.target.value} - 1 unit`,
                      price: sixteenCircuits,
                    }));
                  }}
                />
                <label htmlFor="16circuits">Up to 16 circuits</label>
              </div>
            </div>

            <div className={style.allRadios}>
              <label htmlFor="remedial">
                How many new consumer units (up to {circuits}) do you require?
              </label>
              <div className={style.radioBtns}>
                <input
                  type="radio"
                  name="consumerQuantRadio"
                  value={1}
                  id="1unit"
                  checked={unitsQuantity === 1}
                  onChange={(e) => {
                    setUnitsQuantity(1);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${serviceRequired} ${circuits} - ${e.target.value} unit`,
                      price:
                        circuits === "12 circuits"
                          ? twelveCircuits * +e.target.value
                          : sixteenCircuits * +e.target.value,
                    }));
                  }}
                />
                <label htmlFor="1unit">1</label>
              </div>
              <div className={style.radioBtns}>
                <input
                  type="radio"
                  name="consumerQuantRadio"
                  value={2}
                  id="2unit"
                  onChange={(e) => {
                    setUnitsQuantity(2);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${serviceRequired} ${circuits} - ${e.target.value} units`,
                      price:
                        circuits === "12 circuits"
                          ? twelveCircuits * +e.target.value
                          : sixteenCircuits * +e.target.value,
                    }));
                  }}
                />
                <label htmlFor="2unit">2</label>
              </div>
              <div className={style.radioBtns}>
                <input
                  type="radio"
                  name="consumerQuantRadio"
                  value={3}
                  id="3unit"
                  onChange={(e) => {
                    setUnitsQuantity(3);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${serviceRequired} ${circuits} - ${e.target.value} units`,
                      price:
                        circuits === "12 circuits"
                          ? twelveCircuits * +e.target.value
                          : sixteenCircuits * +e.target.value,
                    }));
                  }}
                />
                <label htmlFor="3unit">3</label>
              </div>
              <div className={style.radioBtns}>
                <input
                  type="radio"
                  name="consumerQuantRadio"
                  value={4}
                  id="4unit"
                  onChange={(e) => {
                    setUnitsQuantity(4);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${serviceRequired} ${circuits} - ${e.target.value} units`,
                      price:
                        circuits === "12 circuits"
                          ? twelveCircuits * +e.target.value
                          : sixteenCircuits * +e.target.value,
                    }));
                  }}
                />
                <label htmlFor="4unit">4</label>
              </div>
              <div className={style.radioBtns}>
                <input
                  type="radio"
                  name="consumerQuantRadio"
                  value={5}
                  id="5unit"
                  onChange={(e) => {
                    setUnitsQuantity(5);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${serviceRequired} ${circuits} - ${e.target.value} units`,
                      price:
                        circuits === "12 circuits"
                          ? twelveCircuits * +e.target.value
                          : sixteenCircuits * +e.target.value,
                    }));
                  }}
                />
                <label htmlFor="5unit">5</label>
              </div>
            </div>
          </>
        )}

        {/* 
        CIRCUIT
        OUTLETS
        */}

        {serviceRequired === "Circuit Outlets" && (
          <>
            <div className={style.allMinorWorksRadios}>
              <p
                style={{
                  textAlign: "left",
                  marginTop: "18px",
                  fontSize: "20px",
                }}
              >
                {serviceRequired}
              </p>
              <div className={style.circuitOutletRadioBtns}>
                <button
                  type="button"
                  className={style.decNumInputBtn}
                  onClick={() => {
                    setOutletLightFittings((prev) =>
                      prev > 0 ? prev - 1 : prev
                    );
                  }}
                >
                  -
                </button>
                <input
                  min="0"
                  type="number"
                  className={style.numberInput}
                  name="outletRadio"
                  value={outletLightFittings}
                  id="outletLightFittings"
                  onWheel={(e) => e.currentTarget.blur()}
                  onChange={(e) => {
                    setOutletLightFittings(+e.target.value);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${serviceRequired} - ${e.target.value}`,
                      price: 100 + +e.target.value * 30,
                    }));
                  }}
                />
                <button
                  type="button"
                  className={style.incNumInputBtn}
                  onClick={() => {
                    setOutletLightFittings((prev) => prev + 1);
                  }}
                >
                  +
                </button>
                <label
                  className={style.numInputLabel}
                  htmlFor="outletLightFittings"
                >
                  Light Fittings
                </label>
              </div>

              <div className={style.circuitOutletRadioBtns}>
                <button
                  type="button"
                  className={style.decNumInputBtn}
                  onClick={() => {
                    setOutletSockets((prev) => (prev > 0 ? prev - 1 : prev));
                  }}
                >
                  -
                </button>
                <input
                  min="0"
                  type="number"
                  className={style.numberInput}
                  name="outletRadio"
                  value={outletSockets}
                  id="outletSockets"
                  onWheel={(e) => e.currentTarget.blur()}
                  onChange={(e) => {
                    setOutletSockets(+e.target.value);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${serviceRequired} - ${e.target.value}`,
                      price: 100 + +e.target.value * 30,
                    }));
                  }}
                />
                <button
                  type="button"
                  className={style.incNumInputBtn}
                  onClick={() => {
                    setOutletSockets((prev) => prev + 1);
                  }}
                >
                  +
                </button>
                <label
                  className={style.numInputLabel}
                  htmlFor="outletLightFittings"
                >
                  Sockets
                </label>
              </div>
              <div className={style.circuitOutletRadioBtns}>
                <button
                  type="button"
                  className={style.decNumInputBtn}
                  onClick={() => {
                    setOutletSwitches((prev) => (prev > 0 ? prev - 1 : prev));
                  }}
                >
                  -
                </button>
                <input
                  min="0"
                  type="number"
                  className={style.numberInput}
                  name="outletRadio"
                  value={outletSwitches}
                  id="outletSwitches"
                  onWheel={(e) => e.currentTarget.blur()}
                  onChange={(e) => {
                    setOutletSwitches(+e.target.value);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${serviceRequired} - ${e.target.value}`,
                      price: 100 + +e.target.value * 30,
                    }));
                  }}
                />
                <button
                  type="button"
                  className={style.incNumInputBtn}
                  onClick={() => {
                    setOutletSwitches((prev) => prev + 1);
                  }}
                >
                  +
                </button>
                <label
                  className={style.numInputLabel}
                  htmlFor="outletLightFittings"
                >
                  Switches
                </label>
              </div>
              <div className={style.circuitOutletRadioBtns}>
                <button
                  type="button"
                  className={style.decNumInputBtn}
                  onClick={() => {
                    setOutletBlankingPlates((prev) =>
                      prev > 0 ? prev - 1 : prev
                    );
                  }}
                >
                  -
                </button>
                <input
                  min="0"
                  type="number"
                  className={style.numberInput}
                  name="outletRadio"
                  value={outletBlankingPlates}
                  id="outletBlankingPlates"
                  onWheel={(e) => e.currentTarget.blur()}
                  onChange={(e) => {
                    setOutletBlankingPlates(+e.target.value);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${serviceRequired} - ${e.target.value}`,
                      price: 100 + +e.target.value * 30,
                    }));
                  }}
                />
                <button
                  type="button"
                  className={style.incNumInputBtn}
                  onClick={() => {
                    setOutletBlankingPlates((prev) => prev + 1);
                  }}
                >
                  +
                </button>
                <label
                  className={style.numInputLabelPlate}
                  htmlFor="outletLightFittings"
                >
                  Blanking Off Plates for Consumer Units
                </label>
              </div>
            </div>
          </>
        )}
        {/* 
        FAIL
        FI
        */}

        {serviceRequired === "Fail - Further Investigation (FI)" && (
          <>
            <label style={{ textAlign: "left" }}>
              How many hours do you require?
            </label>
            <p style={{ textAlign: "left", fontSize: "12px" }}>
              <em>
                Recommended time: 1 hour. <br /> If further time is required,
                your electrician will advise you of the cost of this during your
                appointment and book you in for further time.
              </em>
            </p>
            <div className={style.allRadios}>
              <div className={style.radioBtns}>
                <input
                  defaultChecked
                  type="radio"
                  name="failFIRadio"
                  value={1}
                  id="1hrFI"
                  onChange={() => {
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${serviceRequired} - 1hr`,
                      price: 90,
                    }));
                  }}
                />
                <label htmlFor="1hr">1</label>
              </div>
              <div className={style.radioBtns}>
                <input
                  type="radio"
                  name="failFIRadio"
                  value={2}
                  id="2hrFI"
                  onChange={() => {
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${serviceRequired} - 2hr`,
                      price: 180,
                    }));
                  }}
                />
                <label htmlFor="2hr">2</label>
              </div>
              <div className={style.radioBtns}>
                <input
                  type="radio"
                  name="failFIRadio"
                  value={3}
                  id="3hrFI"
                  onChange={() => {
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${serviceRequired} - 3hr`,
                      price: 270,
                    }));
                  }}
                />
                <label htmlFor="3hr">3</label>
              </div>
            </div>
          </>
        )}
        {/* 
        WORKS
        TO
        EXISITING
        CONSUMER UNIT
        */}

        {serviceRequired === "Works/Repairs to Existing Consumer Unit" && (
          <>
            <p
              style={{
                textAlign: "left",
                marginTop: "18px",
                fontSize: "20px",
              }}
            >
              {serviceRequired}
            </p>
            <label style={{ textAlign: "left" }}>
              Do you need minor rewiring?
            </label>
            <div className={style.allRadios}>
              <div className={style.radioBtns}>
                <input
                  defaultChecked
                  type="radio"
                  name="minorRewireRadio"
                  value={"Yes"}
                  id="yesMinorRewire"
                  onChange={(e) => {
                    setMinorRewiring(e.target.value);
                  }}
                />
                <label htmlFor="yesMinorRewire">Yes</label>
              </div>
              <div className={style.radioBtns}>
                <input
                  type="radio"
                  name="minorRewireRadio"
                  value={"No"}
                  id="noMinorRewire"
                  onChange={(e) => {
                    setMinorRewiring(e.target.value);
                  }}
                />
                <label htmlFor="noMinorRewire">No</label>
              </div>
            </div>
            <label style={{ textAlign: "left", marginBottom: "-5px" }}>
              Do you require any of the following services?
            </label>
            <div style={{ marginBottom: "20px" }}>
              <div className={style.checkBoxOptions}>
                <input
                  type="checkbox"
                  name="repaiRCUOptions"
                  value={"Minor repairs to CU"}
                  id="minorToCU"
                  onChange={(e) => {
                    setCUoptions((prev) => {
                      const copy = [...prev];
                      if (copy.includes(e.target.value)) {
                        const index = copy.indexOf(e.target.value);
                        copy.splice(index, 1);
                      } else {
                        copy.push(e.target.value);
                      }
                      return copy;
                    });
                  }}
                />
                <label htmlFor="minorToCU">
                  Minor Repairs to Consumer Unit
                </label>
              </div>
              <div className={style.checkBoxOptions}>
                <input
                  type="checkbox"
                  name="repaiRCUOptions"
                  value={"Minor repairs inside CU"}
                  id="minorInsideCU"
                  onChange={(e) => {
                    setCUoptions((prev) => {
                      const copy = [...prev];
                      if (copy.includes(e.target.value)) {
                        const index = copy.indexOf(e.target.value);
                        copy.splice(index, 1);
                      } else {
                        copy.push(e.target.value);
                      }
                      return copy;
                    });
                  }}
                />
                <label htmlFor="minorInsideCU">
                  Minor Repairs inside Consumer Unit
                </label>
              </div>
              <div className={style.checkBoxOptions}>
                <input
                  type="checkbox"
                  name="repaiRCUOptions"
                  value={"Adjustments to CU"}
                  id="adjustmentsToCU"
                  onChange={(e) => {
                    setCUoptions((prev) => {
                      const copy = [...prev];
                      if (copy.includes(e.target.value)) {
                        const index = copy.indexOf(e.target.value);
                        copy.splice(index, 1);
                      } else {
                        copy.push(e.target.value);
                      }
                      return copy;
                    });
                  }}
                />
                <label htmlFor="adjustmentsToCU">
                  Adjustments to Consumer Unit
                </label>
              </div>
              <div className={style.checkBoxOptions}>
                <input
                  type="checkbox"
                  name="repaiRCUOptions"
                  value={"Labelling Fuse Boards (12 circuits)"}
                  id="labelling12circuits"
                  onChange={(e) => {
                    setCircuitLabelling((prev) => {
                      const copy = [...prev];
                      if (copy.includes(e.target.value)) {
                        const index = copy.indexOf(e.target.value);
                        copy.splice(index, 1);
                      } else {
                        copy.push(e.target.value);
                      }
                      return copy;
                    });
                  }}
                />
                <label htmlFor="labelling12circuits">
                  Labelling Fuse Boards (12 circuits)
                </label>
              </div>
              <div className={style.checkBoxOptions}>
                <input
                  type="checkbox"
                  name="repaiRCUOptions"
                  value={"Labelling Fuse Boards (16 circuits)"}
                  id="labelling16circuits"
                  onChange={(e) => {
                    setCircuitLabelling((prev) => {
                      const copy = [...prev];
                      if (copy.includes(e.target.value)) {
                        const index = copy.indexOf(e.target.value);
                        copy.splice(index, 1);
                      } else {
                        copy.push(e.target.value);
                      }
                      return copy;
                    });
                  }}
                />
                <label htmlFor="labelling16circuits">
                  Labelling Fuse Boards (16 circuits)
                </label>
              </div>
            </div>
            <label
              style={{
                textAlign: "left",
                marginBottom: "-5px",
              }}
            >
              Do you require any of the following devices?
            </label>
            <div style={{ marginBottom: "20px" }}>
              <div className={style.circuitOutletRadioBtns}>
                <button
                  type="button"
                  className={style.decNumInputBtn}
                  onClick={() => {
                    setRcd((prev) => (prev > 0 ? prev - 1 : prev));
                  }}
                >
                  -
                </button>
                <input
                  min="0"
                  type="number"
                  className={style.numberInput}
                  name="devicesRadio"
                  value={rcd}
                  id="rcd"
                  onWheel={(e) => e.currentTarget.blur()}
                  onChange={(e) => {
                    setRcd(+e.target.value);
                  }}
                />
                <button
                  type="button"
                  className={style.incNumInputBtn}
                  onClick={() => {
                    setRcd((prev) => prev + 1);
                  }}
                >
                  +
                </button>
                <label className={style.numInputLabel} htmlFor="rcd">
                  RCD - Residual Current Device
                </label>
              </div>
              <div className={style.circuitOutletRadioBtns}>
                <button
                  type="button"
                  className={style.decNumInputBtn}
                  onClick={() => {
                    setMcb((prev) => (prev > 0 ? prev - 1 : prev));
                  }}
                >
                  -
                </button>
                <input
                  min="0"
                  type="number"
                  className={style.numberInput}
                  name="devicesRadio"
                  value={mcb}
                  id="mcb"
                  onWheel={(e) => e.currentTarget.blur()}
                  onChange={(e) => {
                    setMcb(+e.target.value);
                  }}
                />
                <button
                  type="button"
                  className={style.incNumInputBtn}
                  onClick={() => {
                    setMcb((prev) => prev + 1);
                  }}
                >
                  +
                </button>
                <label className={style.numInputLabel} htmlFor="mcb">
                  MCB - Mini Circuit Breaker
                </label>
              </div>
              <div className={style.circuitOutletRadioBtns}>
                <button
                  type="button"
                  className={style.decNumInputBtn}
                  onClick={() => {
                    setRcbo((prev) => (prev > 0 ? prev - 1 : prev));
                  }}
                >
                  -
                </button>
                <input
                  min="0"
                  type="number"
                  className={style.numberInput}
                  name="devicesRadio"
                  value={rcbo}
                  id="rcbo"
                  onWheel={(e) => e.currentTarget.blur()}
                  onChange={(e) => {
                    setRcbo(+e.target.value);
                  }}
                />
                <button
                  type="button"
                  className={style.incNumInputBtn}
                  onClick={() => {
                    setRcbo((prev) => prev + 1);
                  }}
                >
                  +
                </button>
                <label className={style.numInputLabel} htmlFor="rcbo">
                  RCBO - Residual Circuit Breaker with Overload
                </label>
              </div>
            </div>
            <div className={style.list}>
            <p className={style.priceLabel}>Estimated price:</p>
            <p className={style.price}>£{item.price}</p>
          </div>
          <p style={{ textAlign: "left", fontSize: "0.9em" }}>
            <em>
              Your final price will be calculated based on your location before
              you go to the booking summary.
            </em>
          </p>
          </>
        )}
        {/* 
        FULL
        REWIRING
      */}

        {serviceRequired === "Full Rewiring" && (
          <>
            <div className={style.allMinorWorksRadios}>
              <p
                style={{
                  textAlign: "left",
                  marginTop: "18px",
                  fontSize: "20px",
                }}
              >
                {serviceRequired}
              </p>
              <label htmlFor="remedial">
                How many bedrooms does the property contain?
              </label>
              <div className={style.minorWorksRadioBtns}>
                <input
                  defaultChecked
                  type="radio"
                  name="rewiringRadio"
                  value={"1 bedroom"}
                  id="1bedroomRewiring"
                  onChange={(e) => {
                    setRewiringPrice(3000);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${serviceRequired} free quotation - ${e.target.value}`,
                      price: 0.5,
                    }));
                  }}
                />
                <label htmlFor="1bedroomRewiring">1 bedroom</label>
              </div>
              <div className={style.minorWorksRadioBtns}>
                <input
                  type="radio"
                  name="rewiringRadio"
                  value={"2 bedrooms"}
                  id="2bedroomRewiring"
                  onChange={(e) => {
                    setRewiringPrice(3200);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${serviceRequired} free quotation - ${e.target.value}`,
                      price: 0,
                    }));
                  }}
                />
                <label htmlFor="2bedroomRewiring">2 bedrooms</label>
              </div>
              <div className={style.minorWorksRadioBtns}>
                <input
                  type="radio"
                  name="rewiringRadio"
                  value={"3 bedrooms"}
                  id="3bedroomRewiring"
                  onChange={(e) => {
                    setRewiringPrice(3600);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${serviceRequired} free quotation - ${e.target.value}`,
                      price: 0,
                    }));
                  }}
                />
                <label htmlFor="3bedroomRewiring">3 bedrooms</label>
              </div>
              <div className={style.minorWorksRadioBtns}>
                <input
                  type="radio"
                  name="rewiringRadio"
                  value={"4 bedrooms"}
                  id="4bedroomRewiring"
                  onChange={(e) => {
                    setRewiringPrice(4000);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${serviceRequired} free quotation - ${e.target.value}`,
                      price: 0,
                    }));
                  }}
                />
                <label htmlFor="4bedroomRewiring">4 bedrooms</label>
              </div>
              <div className={style.minorWorksRadioBtns}>
                <input
                  type="radio"
                  name="rewiringRadio"
                  value={"5 bedrooms"}
                  id="5bedroomRewiring"
                  onChange={(e) => {
                    setRewiringPrice(4500);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${serviceRequired} free quotation - ${e.target.value}`,
                      price: 0,
                    }));
                  }}
                />
                <label htmlFor="5bedroomRewiring">5 bedrooms</label>
              </div>
              <div className={style.minorWorksRadioBtns}>
                <input
                  type="radio"
                  name="rewiringRadio"
                  value={"6 bedrooms"}
                  id="6bedroomRewiring"
                  onChange={(e) => {
                    setRewiringPrice(5000);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${serviceRequired} free quotation - ${e.target.value}`,
                      price: 0,
                    }));
                  }}
                />
                <label htmlFor="6bedroomRewiring">6 bedrooms</label>
              </div>
            </div>
            <div className={style.list}>
              <p className={style.priceLabel}>Estimated price:</p>
              <p className={style.estimatedPrice}>£{rewiringPrice}</p>
            </div>
            <h3 style={{ textAlign: "left", marginTop: "10px" }}>
              Book a{" "}
              <span style={{ fontSize: "28px" }} className={style.price}>
                FREE
              </span>{" "}
              quotation
            </h3>
            <div className={style.divider} />
            <p style={{ textAlign: "left", fontSize: "18px" }}>
              One of our electricians will visit your property to provide you
              with a more accurate quote for your full rewiring.
            </p>
            <p style={{ textAlign: "left", fontSize: "18px" }}>
              <strong>Please note:</strong> you will NOT be charged for this
              booking.
            </p>
            <div className={style.list}>
              <p className={style.priceLabel}>To pay today:</p>
              <p className={style.price}>£{item.price}</p>
            </div>
          </>
        )}

        {serviceRequired !== "Full Rewiring" && (
          <>
            <div className={style.list}>
              <p className={style.priceLabel}>Estimated price:</p>
              <p className={style.price}>£{item.price}</p>
            </div>
            <p style={{ textAlign: "left", fontSize: "0.9em" }}>
              <em>
                Your final price will be calculated based on your location
                before you go to the booking summary.
              </em>
            </p>
          </>
        )}
      </ServiceTemplateComponent>
    </>
  );
}
