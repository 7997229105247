export const areAllValuesTruthy = (obj: any) => {
  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      if (!areAllValuesTruthy(obj[key])) {
        return false;
      }
    } else {
      if (!obj[key] && obj[key] !== 0) {
        return false;
      }
    }
  }
  return true;
};
