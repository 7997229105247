export default function convertShortDateToObj(dateString: string) {
  const dateParts = dateString.split("/");

  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1; // Adjust month to be zero-based
  const year = parseInt(dateParts[2], 10);

  // Check if the provided values are within valid ranges
  if (
    isNaN(day) ||
    isNaN(month) ||
    isNaN(year) ||
    day < 1 ||
    day > 31 ||
    month < 0 ||
    month > 11 ||
    year < 0
  ) {
    return null;
  }

  const convertedDate = new Date(year, month, day);

  // Check if the date is valid
  if (isNaN(convertedDate.getTime()) || convertedDate.getTime() < Date.now()) {
    return null;
  }

  return convertedDate;
}
