import style from "../styles/spacer.module.scss";

export function TopSpacer() {
  return <div className={style.topSpacer} />;
}

export function BottomSpacer() {
  return <div className={style.bottomSpacer} />;
}

export function MidSpacer() {
  return <div className={style.midSpacer} />;
}
