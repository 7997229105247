import Button from "../components/button";
import ContactForm from "../components/contact-form";
import { BottomSpacer, TopSpacer } from "../components/spacer";
import style from "../styles/contact-page.module.scss";

export default function Contact() {
  return (
    <>
      <TopSpacer />
      <div className={style.flexContainer}>
        <div className={style.getInTouch}>
          <h1>Get in touch</h1>
          <Button>
            <a href="tel:+447496445148" className={style.anchor}>
              07496445148
            </a>
          </Button>
          <Button>
            <a href="mailto:info@teckengineers.co.uk" className={style.anchor}>
              info@teckengineers.co.uk
            </a>
          </Button>
        </div>
        <BottomSpacer />
        <ContactForm />
      </div>
    </>
  );
}
