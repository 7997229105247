import style from "../styles/button-style.module.scss";

interface ButtonProps {
  children?: React.ReactNode;
  onClick?: (event: any) => void;
  type?: string;
}

export default function Button({
  children = "",
  onClick,
}: ButtonProps): React.ReactElement {
  return (
    <button onClick={onClick} className={style.button}>
      {children}
    </button>
  );
}
