import ServiceTemplateComponent from "./template";
import emergency from "../../assets/emergency.jpg";
import { useState } from "react";
import style from "../../styles/service-prices.module.scss";
import { formatEmailDate } from "../../utils/funcs/google-calendar";

export default function EmergencyCallouts() {
  const today = new Date();
 
  const [item, setItem] = useState({
    bookingID: " ",
    productName: "Emergency Callout",
    price: 120,
    productInfo: `Emergency Callout - 1hr`,
    customerName: "",
    customerEmail: "",
    customerNumber: "",
    start: "",
    end: "",
    date: today,
    emailDate: formatEmailDate(today),
    emailStartTime: "",
    access: "Access provided by: Customer on booking",
    address: { line1: "", line2: "", postTown: "", postcode: "" },
    finalPrice: 0
  });
  return (
    
    <>
      <ServiceTemplateComponent
        item={item}
        setItem={setItem}
        backgroundImage={emergency}
        serviceDescription="Our electricians are dedicated to addressing a wide range of electrical emergencies, including faulty wiring, and electrical system failures in homes and businesses. With our expertise and commitment to safety, we swiftly diagnose, repair, and restore your electrical systems, providing you with peace of mind when you need it most. Contact us directly or book online for same day emergency callouts."
        emergency
      >
        <>
          <label style={{ textAlign: "left" }}>
            How many hours do you require?
          </label>
          <p style={{ textAlign: "left", fontSize: "12px" }}>
            <em>
              Recommended time: 1 hour. <br /> If further time is required, your
              electrician will advise you of the cost of this during your
              appointment and book you in for further time.
            </em>
          </p>
          <div className={style.allRadios}>
            <div className={style.radioBtns}>
              <input
                defaultChecked
                type="radio"
                name="emergencyRadio"
                value={1}
                id="emergency1hr"
                onChange={(e) => {
                  setItem((prevItem) => ({
                    ...prevItem,
                    productInfo: `Emergency Callout - ${e.target.value}hr`,
                    price: 120,
                  }));
                }}
              />
              <label htmlFor="emergency1hr">1</label>
            </div>
            <div className={style.radioBtns}>
              <input
                type="radio"
                name="emergencyRadio"
                value={2}
                id="emergency2hr"
                onChange={(e) => {
                  setItem((prevItem) => ({
                    ...prevItem,
                    productInfo: `Emergency Callout - ${e.target.value}hrs`,
                    price: 240,
                  }));
                }}
              />
              <label htmlFor="emergency2hr">2</label>
            </div>
            <div className={style.radioBtns}>
              <input
                type="radio"
                name="emergencyRadio"
                value={3}
                id="emergency3hr"
                onChange={(e) => {
                  setItem((prevItem) => ({
                    ...prevItem,
                    productInfo: `Emergency Callout - ${e.target.value}hrs`,
                    price: 360,
                  }));
                }}
              />
              <label htmlFor="emergency3hr">3</label>
            </div>
          </div>
          <div className={style.list}>
            <p className={style.priceLabel}>Estimated price:</p>
            <p className={style.price}>£{item.price}</p>
          </div>
          <p style={{ textAlign: "left", fontSize: "0.9em" }}>
            <em>
              Your final price will be calculated based on your location before
              you go to the booking summary.
            </em>
          </p>
        </>
      </ServiceTemplateComponent>
    </>
  );
}
