export const shortenString = (string: string) => {
    if (string.length > 21) {
        if (string[21] === " ") {
            string = string.substring(0, 20) + "...";

        } else string = string.substring(0, 21) + "...";
    }
    return string
}

export const shortenStringCompletely = (string: string) => {
    if (string.length > 18) {
        if (string[18] === " ") {
            string = string.substring(0, 19) + "...";

        } else string = string.substring(0, 18) + "...";
    }
    return string
}