import { setHours, setMinutes, format, getDay } from "date-fns";

export const isWeekday = (date: any) => {
  const day = getDay(date);
  return day !== 0;
};

export const getDateFromDateTimeString = (dateTimeString: any) => {
  const date = dateTimeString ? new Date(dateTimeString) : null;
  const year = date ? date.getFullYear() : 0;
  const month = date ? date.getMonth() + 1 : 0;
  const day = date ? date.getDate() : 0;
  return { year, month, day };
};

export const createTimeSlots = () => {
  let timeSlots = [];
  for (let hour = 9; hour <= 17; hour++) {
    for (let minute = 0; minute <= 45; minute += 30) {
      let time = format(
        setHours(setMinutes(new Date(), minute), hour),
        "HH:mm"
      );
      timeSlots.push(time);
    }
  }
  return timeSlots;
};

export const getStartAndEndTimes = (selectedTime: any, date: any) => {
  const [hour, minute] = selectedTime.split(":");
  const { year, month, day } = getDateFromDateTimeString(date);
  const start = new Date(
    Date.UTC(year, month - 1, day, Number(hour), Number(minute))
  );
  const end = start ? new Date(start.getTime() + 60 * 60 * 1000) : "";
  return { start, end };
};

export const formatEmailStartTime = (start: any) => {
  // const adjustedStart = subHours(start, 1);
  let formattedStart = format(start, "h:mm a");
  if (start.getHours() === 12 || (start.getHours() > 0 && start.getHours() < 9)) {
    // If the hour is 12, or between 1 and 8, change "AM" to "PM"
    formattedStart = formattedStart.replace('AM', 'PM');
  }
  return formattedStart
};

function getOrdinalSuffix(day: number) {
  const suffixes = ["th", "st", "nd", "rd"];
  const lastDigit = day % 10;
  const specialCases = [11, 12, 13];

  if (specialCases.includes(day)) {
    return "th";
  } else {
    return suffixes[lastDigit] || "th";
  }
}

export const formatEmailDate = (date: Date) => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfWeek = daysOfWeek[date.getUTCDay()];
  const dayOfMonth = date.getUTCDate();
  const month = monthsOfYear[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  return `${dayOfWeek} ${dayOfMonth}${getOrdinalSuffix(
    dayOfMonth
  )} ${month} ${year}`;
};

export function convertTo24HourFormat(timeString: string) {
  // Split the input string into parts
  const parts = timeString.split(' ');

  if (parts.length !== 2) {
    // Invalid input format
    return "";
  }

  // Extract the time and AM/PM portions
  const time = parts[0];
  const period = parts[1].toUpperCase();

  // Split the time into hours and minutes
  const timeParts = time.split(':');

  if (timeParts.length !== 2) {
    // Invalid time format
    return "";
  }

  let hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[1]);

  // Adjust hours for PM
  if (period === 'PM' && hours !== 12) {
    hours += 12;
  } else if (period === 'AM' && hours === 12) {
    hours = 0;
  }

  // Convert to 24-hour format
  const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  return formattedTime;
}



//   function isTimeSlotBooked(timeSlot: string) {
//   // Loop through googleEvents and myConEvents to check if the time slot is booked
//   const isBooked = googleEvents.concat(myConEvents).some((event: any) => {
//     const eventStart = new Date(event.start.dateTime);
//     const eventEnd = new Date(event.end.dateTime);
//     const timeSlotDate = new Date(date!);
//     const timeSlotStart = setHours(
//       setMinutes(timeSlotDate, parseInt(timeSlot.slice(3))),
//       parseInt(timeSlot.slice(0, 2))
//     );
//     const timeSlotEnd = setHours(
//       setMinutes(timeSlotDate, parseInt(timeSlot.slice(3))),
//       parseInt(timeSlot.slice(0, 2))
//     );

//     return (
//       (eventStart <= timeSlotStart && timeSlotStart < eventEnd) ||
//       (eventStart < timeSlotEnd && timeSlotEnd <= eventEnd) ||
//       (timeSlotStart <= eventStart && eventEnd <= timeSlotEnd)
//     );
//   });
//   return isBooked;
// }
