import { useClerk } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import buttonStyle from "../styles/button-style.module.scss";

export default function SignOutButton() {
  const { signOut } = useClerk();
  let navigate = useNavigate();

  return (
    <>
      <div className={buttonStyle.top} />
      <button
        onClick={() => signOut(() => navigate("/"))}
        className={buttonStyle.button}
      >
        Sign out
      </button>
    </>
  );
}
