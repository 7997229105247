import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import buttonStyle from "../../styles/button-style.module.scss";
import style from "../../styles/modal.module.scss";
import { findEmptyInputFields } from "../../utils/funcs/find-empty-input-ref";
import { ErrorMsgBelow } from "../required-error-messages";
import { validateMobile } from "../../utils/funcs/validate-mobile";
import { isInRadius } from "../../utils/funcs/is-in-radius";
import { getLatLonFromPostcode } from "../../utils/funcs/lat-ln";
import Calendar from "../calendar";
import { parseISO } from "date-fns";
import downArrowHead from "../../assets/down-arrow-head.svg";
import upArrowHead from "../../assets/up-arrow-head.svg";
import { toast } from "react-toastify";
import { getServerURL } from "../../utils/funcs/get-serverURL";

function EditModal({ show, setShowEditModal, booking, handleEditUpdate }: any) {
  const serverURL = getServerURL();

  const [showDetails, setShowDetails] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showAccess, setShowAccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updatedBooking, setUpdatedBooking] = useState(booking);
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [showPostcodeError, setShowPostcodeError] = useState(false);
  const [access, setAccess] = useState({
    providedByOther: false,
    info: {
      name: "",
      email: "",
      number: "",
    },
  });

  const [errorMsgs, setErrorMsgs] = useState({
    nameError: "",
    telError: "",
    dateError: "",
    timeError: "",
    addressLine1Error: "",
    addressLine2Error: "",
    postTownError: "",
    postcodeError: "",
    accessNameError: "",
    accessEmailError: "",
    accessNumberError: "",
  });

  const refs = {
    name: useRef(null),
    tel: useRef(null),
    time: useRef(null),
    addressLine1: useRef(null),
    postTown: useRef(null),
    postcode: useRef(null),
    accessName: useRef(null),
    accessEmail: useRef(null),
    accessNumber: useRef(null),
  };

  useEffect(() => {
    if (updatedBooking.postcode) {
      getLatLonFromPostcode(updatedBooking.postcode)
        .then((data: any) => {
          if (data && isInRadius(data.lat, data.lon)) {
            setShowPostcodeError(false);
            setIsValid(true);
          } else {
            setShowPostcodeError(true);
            setIsValid(false);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else setShowPostcodeError(false);
  }, [updatedBooking.postcode, setIsValid]);

  useEffect(() => {
    setUpdatedBooking((prev: any) => ({
      ...prev,
      address: `${updatedBooking.addressLine1}, ${updatedBooking.addressLine2}, ${updatedBooking.postTown}, ${updatedBooking.postcode}`,
    }));
  }, [
    updatedBooking.addressLine1,
    updatedBooking.addressLine2,
    updatedBooking.postTown,
    updatedBooking.postcode,
    updatedBooking.address,
  ]);

  const notify = () => {
    toast.success("Booking updated successfully", {
      position: "top-left",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    setError("");
    delete updatedBooking.displayDate;
    const emptyRefs = findEmptyInputFields(refs);
    if (emptyRefs.length > 0) {
      setLoading(false);
      setError("Please complete all fields");
      emptyRefs.forEach((ref) => {
        setErrorMsgs((prev: any) => ({
          ...prev,
          [`${ref}Error`]: "Required",
        }));
      });
      return;
    }

    if (access.providedByOther) {
      setUpdatedBooking((prev: any) => ({
        ...prev,
        access: `Access provided by: Name: ${access.info.name}; Email: ${access.info.email}; Number: ${access.info.number}`,
      }));
    }

    if (!isValid) {
      setLoading(false);
      setShowPostcodeError(false);
      setError("Please enter a valid postcode");
      return;
    }

    const validBookingMobile = await validateMobile(
      updatedBooking.customerNumber
    );

    if (!validBookingMobile) {
      setLoading(false);
      setErrorMsgs((prev: any) => ({
        ...prev,
        telError: "Invalid phone number",
      }));
      setError("Please provide a valid phone number");
      return;
    }

    try {
      const response = await axios.post(
        `${serverURL}/update-booking`,
        updatedBooking
      );
      if (response) {
        setLoading(false);
        handleEditUpdate();
        setShowEditModal(false);
        notify();
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Modal
        show={show}
        onHide={() => {
          setShowEditModal(false);
        }}
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className={style.title}>
              Edit your upcoming booking
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={style.modalBody}>
              <h3 className={style.heading}>Your details</h3>
              <div className={style.divider} />
              <label htmlFor="customerName">Name</label>
              <input
                type="text"
                ref={refs.name}
                value={updatedBooking.customerName}
                id="customerName"
                name="updateBooking"
                onChange={(e) => {
                  setError("");
                  setErrorMsgs((prev: any) => ({
                    ...prev,
                    nameError: "",
                  }));
                  setUpdatedBooking((prev: any) => ({
                    ...prev,
                    customerName: e.target.value,
                  }));
                }}
              />
              {errorMsgs.nameError && (
                <ErrorMsgBelow msg={errorMsgs.nameError} />
              )}
              <label htmlFor="customerNumber">Number</label>
              <input
                type="number"
                ref={refs.tel}
                value={updatedBooking.customerNumber}
                id="customerNumber"
                name="updateBooking"
                onChange={(e) => {
                  setError("");
                  setErrorMsgs((prev: any) => ({
                    ...prev,
                    telError: "",
                  }));
                  setUpdatedBooking((prev: any) => ({
                    ...prev,
                    customerNumber: e.target.value,
                  }));
                }}
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
              />
              {errorMsgs.telError && <ErrorMsgBelow msg={errorMsgs.telError} />}
              <Calendar
                item={updatedBooking}
                setItem={setUpdatedBooking}
                refs={refs}
                errorMsgs={errorMsgs}
                setErrorMsgs={setErrorMsgs}
                edit={true}
                selectedDate={parseISO(booking.date)}
              />
              <h3 className={style.heading}>Appt. Address</h3>
              <div className={style.divider} />
              <label htmlFor="addressLine1">Address Line 1</label>
              <input
                type="text"
                ref={refs.addressLine1}
                value={updatedBooking.addressLine1}
                id="addressLine1"
                name="updateBooking"
                onChange={(e) => {
                  setError("");
                  setErrorMsgs((prev: any) => ({
                    ...prev,
                    addressLine1Error: "",
                  }));
                  setUpdatedBooking((prev: any) => ({
                    ...prev,
                    addressLine1: e.target.value,
                  }));
                }}
              />
              {errorMsgs.addressLine1Error && (
                <ErrorMsgBelow msg={errorMsgs.addressLine1Error} />
              )}
              <label htmlFor="addressLine2">Address Line 2</label>
              <input
                type="text"
                value={updatedBooking.addressLine2}
                id="addressLine2"
                name="updateBooking"
                onChange={(e) => {
                  setError("");
                  if (e.target.value === "") {
                    setUpdatedBooking((prev: any) => ({
                      ...prev,
                      addressLine2: " ",
                    }));
                  }
                  setErrorMsgs((prev: any) => ({
                    ...prev,
                    addressLine2Error: "",
                  }));
                  setUpdatedBooking((prev: any) => ({
                    ...prev,
                    addressLine2: e.target.value,
                  }));
                }}
              />
              {errorMsgs.addressLine2Error && (
                <ErrorMsgBelow msg={errorMsgs.addressLine2Error} />
              )}
              <label htmlFor="postTown">Town/City</label>
              <input
                type="text"
                ref={refs.postTown}
                value={updatedBooking.postTown}
                id="postTown"
                name="updateBooking"
                onChange={(e) => {
                  setError("");
                  setErrorMsgs((prev: any) => ({
                    ...prev,
                    postTownError: "",
                  }));
                  setUpdatedBooking((prev: any) => ({
                    ...prev,
                    postTown: e.target.value,
                  }));
                }}
              />
              {errorMsgs.postTownError && (
                <ErrorMsgBelow msg={errorMsgs.postTownError} />
              )}
              <label htmlFor="postcode">Postcode</label>
              <input
                type="text"
                ref={refs.postcode}
                value={updatedBooking.postcode}
                id="postcode"
                name="updateBooking"
                onChange={(e) => {
                  setError("");
                  setErrorMsgs((prev: any) => ({
                    ...prev,
                    postcodeError: "",
                  }));
                  setUpdatedBooking((prev: any) => ({
                    ...prev,
                    postcode: e.target.value,
                  }));
                }}
              />
              {errorMsgs.postcodeError && (
                <ErrorMsgBelow msg={errorMsgs.postcodeError} />
              )}
              {showPostcodeError && !isValid && (
                <p className={style.notCoverageArea}>
                  This postcode is not within our coverage area
                </p>
              )}

              <h3 className={style.heading}>Access</h3>
              <div className={style.divider} />
              <div className={style.flex}>
                <p>{booking.access}</p>
                <img
                  src={showAccess ? upArrowHead : downArrowHead}
                  className={style.arrowHead}
                  alt="arrowhead"
                  onClick={() => {
                    setShowAccess(!showAccess);
                    setErrorMsgs((prev: any) => ({
                      ...prev,
                      accessNameError: "",
                      accessNumberError: "",
                      accessEmailError: "",
                    }));
                    setError("");
                    setAccess((prev) => ({
                      ...prev,
                      providedByOther: !access.providedByOther,
                    }));
                  }}
                />
              </div>
              {showAccess && (
                <>
                  <>
                    <label htmlFor="accessInfo">
                      Please provide the name, email and number of the person
                      allowing access to the property:
                    </label>
                    <input
                      ref={refs.accessName}
                      name="access"
                      value={access.info.name}
                      id="accessName"
                      onChange={(e) => {
                        setError("");
                        setAccess((prev: any) => {
                          const copy = { ...prev };
                          copy.info.name = e.target.value;
                          return copy;
                        });
                        setErrorMsgs((prev: any) => ({
                          ...prev,
                          accessNameError: "",
                        }));
                        setUpdatedBooking((prevItem: any) => ({
                          ...prevItem,
                          accessName: e.target.value,
                          access: `Access provided by: Name: ${e.target.value}; Email: ${access.info.email}; Number: ${access.info.number}`,
                        }));
                      }}
                      placeholder="Name*"
                    />
                    {errorMsgs.accessNameError && (
                      <ErrorMsgBelow msg={errorMsgs.accessNameError} />
                    )}
                    <input
                      ref={refs.accessEmail}
                      name="access"
                      value={access.info.email}
                      id="accessEmail"
                      onChange={(e) => {
                        setError("");
                        setAccess((prev: any) => {
                          const copy = { ...prev };
                          copy.info.email = e.target.value;
                          return copy;
                        });
                        setErrorMsgs((prev: any) => ({
                          ...prev,
                          accessEmailError: "",
                        }));
                        setUpdatedBooking((prevItem: any) => ({
                          ...prevItem,
                          accessEmail: e.target.value,
                          access: `Access provided by: Name: ${access.info.name}; Email: ${e.target.value}; Number: ${access.info.number}`,
                        }));
                      }}
                      placeholder="Email*"
                    />
                    {errorMsgs.accessEmailError && (
                      <ErrorMsgBelow msg={errorMsgs.accessEmailError} />
                    )}
                    <input
                      ref={refs.accessNumber}
                      name="access"
                      value={access.info.number}
                      id="accessNumber"
                      onChange={(e) => {
                        setError("");
                        setAccess((prev: any) => {
                          const copy = { ...prev };
                          copy.info.number = e.target.value;
                          return copy;
                        });
                        setErrorMsgs((prev: any) => ({
                          ...prev,
                          accessNumberError: "",
                        }));
                        setUpdatedBooking((prevItem: any) => ({
                          ...prevItem,
                          accessNumber: e.target.value,
                          access: `Access provided by: Name: ${access.info.name}; Email: ${access.info.email}; Number: ${e.target.value}`,
                        }));
                      }}
                      placeholder="Contact Number*"
                    />
                    {errorMsgs.accessNumberError && (
                      <ErrorMsgBelow msg={errorMsgs.accessNumberError} />
                    )}
                  </>
                </>
              )}
              {error && <p className="error">{error}</p>}
            </div>
          </Modal.Body>
          <Modal.Footer className={style.closeBtns}>
            <Button
              variant="secondary"
              onClick={() => {
                setShowEditModal(false);
              }}
            >
              Close
            </Button>
            {loading ? (
              <>
                <div className={style.loadingBtn}>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </>
            ) : (
              <Button
                variant="primary"
                type="submit"
                className={buttonStyle.modalBtn}
              >
                Edit Booking
              </Button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default EditModal;
