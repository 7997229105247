import { Modal, Button, Table } from "react-bootstrap";
import buttonStyle from "../../styles/button-style.module.scss";
import style from "../../styles/file-uploader.module.scss";
import thumbsUp from "../../assets/thumb-up-svgrepo-com.png";
import thumbsDown from "../../assets/thumb-down-svgrepo-com.png";
import { FaUpload } from "react-icons/fa6";
import { useCallback, useEffect, useState } from "react";
import BookingSummaryCard from "../booking-summary-card";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Quote, QuoteItems } from "../../types/QuoteItems";
import QuoteSummaryCard from "../quote-summary";
import LoadingSpinner from "../loading-spinner";
import convertShortDateToObj from "../../utils/funcs/convert-short-date-to-object";
import { sendPDFEmail } from "../../utils/funcs/api/send-PDF-email";
import { getServerURL } from "../../utils/funcs/get-serverURL";

export default function UploadFile({
  certificate,
  show,
  handleClose,
  setUploadSuccess,
  uploadSuccess,
}: any): React.ReactElement {
  const [status, setStatus] = useState("");
  const [file, setFile] = useState<FileList | null>(null);
  const [fileError, setFileError] = useState("");
  const [quoteItems, setQuoteItems] = useState<QuoteItems>({});
  const [timeTaken, setTimeTaken] = useState("1 hour");
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [uploadErrorMsg, setUploadErrorMsg] = useState("");

  // expiry
  const [typeDate, setTypeDate] = useState("");
  const [dateError, setDateError] = useState("");

  const serverURL = getServerURL();

  const notify = () => {
    toast.success("Your report has been uploaded successfully", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  // for fail
  const [rows, setRows] = useState([[]]);
  const [addRowError, setAddRowError] = useState("");
  const [num, setNum] = useState("1");
  const [duration, setDuration] = useState("hour");

  // date
  // const [selectDate, setSelectDate] = useState<Date | null>(null);

  const updatePrice = useCallback(() => {
    let price = 0;

    for (const key in quoteItems) {
      price += +quoteItems[key].quantity * +quoteItems[key].price;
    }

    setTotalPrice(price);
  }, [quoteItems, setTotalPrice]);

  const addRow = () => {
    // check if all inputs in current displayed rows are full - if they aren't then don't allow row add
    const rowComplete = isComplete();

    if (rowComplete) {
      const nextIndex = rows.length;

      // Update rows state
      setRows((prev) => [...prev, []]);

      // Update quoteItems state
      setQuoteItems((prev: any) => {
        return { ...prev, [nextIndex]: {} };
      });

      // Update the total price
      updatePrice();

      setAddRowError("");
    } else {
      setAddRowError(
        "Please complete the above row(s) before trying to add a new row."
      );
    }
  };

  const removeRow = () => {
    setAddRowError("");
    if (rows.length > 1) {
      const updatedRows = [...rows];
      updatedRows.pop();

      const updatedQuoteItems = { ...quoteItems };
      delete updatedQuoteItems[rows.length - 1];

      setRows(updatedRows);
      setQuoteItems(updatedQuoteItems);
    }
  };

  useEffect(() => {
    updatePrice();
  }, [quoteItems, updatePrice]);

  const isComplete = () => {
    let rowComplete = true;
    const rows = Object.keys(quoteItems);

    // if the first row is empty, there will be no keys on the quoteItems
    // if there is a new row, but theres no properties then dont allow add
    // if there's a new row but one input is not filled in then dont allow add
    if (!rows.length) {
      rowComplete = false;
    }
    // cannot access quoteItems[index][key] directly using for of due to type error
    Object.keys(quoteItems).forEach((index: string) => {
      if (Object.keys(quoteItems[index]).length < 3) {
        rowComplete = false;
      }

      Object.keys(quoteItems[index]).forEach((key: string) => {
        if (!quoteItems[index][key as keyof Quote]) {
          rowComplete = false;
        }
      });
    });

    return rowComplete;
  };

  const createQuote = () => {
    if (isComplete()) {
      setStatus("upload");
    } else {
      setAddRowError(
        "Please complete the above row(s) before trying create quote"
      );
    }
  };

  const submitFile = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const converted = convertShortDateToObj(typeDate);
    if (!typeDate) {
      setLoading(false);
      setDateError("Please enter an expiry date above");
      return;
    } else if (!converted) {
      setLoading(false);
      setDateError("Please enter a valid future date in the format dd/mm/yyyy");
      return;
    }
    const formData = new FormData();

    if (status === "upload") {
      // it's failed
      if (file && quoteItems) {
        const quote = {
          status: "fail",
          bookingID: certificate.booking.bookingID,
          quoteItems: quoteItems,
          timeTaken,
          expiry: converted,
          expiryReminderSent: false,
        };
        formData.append("quote", JSON.stringify(quote));
        formData.append("file", file[0]);
        formData.append("quoteTotal", JSON.stringify(totalPrice));
      } else {
        setLoading(false);
        setFileError("Please select a file to upload");
        return;
      }
    } else if (status === "pass") {
      if (file) {
        const quote = {
          status,
          bookingID: certificate.booking.bookingID,
          expiry: converted,
          quoteItems: null,
          timeTaken: null,
          expiryReminderSent: false,
        };
        formData.append("quote", JSON.stringify(quote));
        formData.append("file", file[0]);
        formData.append("quoteTotal", JSON.stringify(null));
      } else {
        setLoading(false);
        setFileError("Please select a file to upload");
        return;
      }
    }

    if (file && !fileError) {
      try {
        const result = await axios.post(
          `${serverURL}/upload-certificate`,
          formData
        );
        if (result) {
          setLoading(false);
          setUploadSuccess(!uploadSuccess);
          handleClose();
          notify();
          sendPDFEmail(serverURL, certificate.booking, "upload")
        }
      } catch (err: any) {
        setLoading(false);
        console.log(err);
        // setUploadErrorMsg(err.response.data.message);
      }
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className={
          status === "fail" ? style.uploadFailModal : style.uploadModal
        }
      >
        <Modal.Header
          closeButton
          className={style.uploadModalTitle}
        ></Modal.Header>
        <Modal.Body className={style.modalBody}>
          {status === "" && (
            <>
              <h5>Please select the outcome of the report:</h5>
              <div>
                <div className={style.modalBodyContainer}>
                  <div>
                    <img
                      src={thumbsUp}
                      alt=""
                      className={style.modalBodyIconTick}
                      onClick={() => {
                        setStatus("pass");
                      }}
                    />
                    <p>Satisfactory</p>
                  </div>
                  <div>
                    <img
                      src={thumbsDown}
                      alt=""
                      className={style.modalBodyIconCross}
                      onClick={() => {
                        setStatus("fail");
                      }}
                    />
                    <p>Unsatisfactory</p>
                  </div>
                </div>
              </div>
            </>
          )}

          {status === "pass" && (
            <>
              <h3>Upload file</h3>
              <BookingSummaryCard booking={certificate.booking} />
              <h5>File(s)</h5>
              <div className={style.uploadInputParent}>
                <input
                  id="file"
                  type="file"
                  accept="application/pdf"
                  className={style.uploadInput}
                  onChange={(e) => {
                    setFileError("");
                    setFile(e.target.files);
                  }}
                />
                <FaUpload className={style.uploadIcon} />
                {file && file[0] ? (
                  <div>
                    <p>{file[0]?.name}</p>
                  </div>
                ) : (
                  <p>Click here to upload file</p>
                )}
              </div>
              <br />
              {file && (
                <button
                  className={buttonStyle.button}
                  onClick={() => {
                    setFile(null);
                  }}
                >
                  Remove Selected Files
                </button>
              )}
              {fileError && (
                <p className="error" style={{ textAlign: "center" }}>
                  {fileError}
                </p>
              )}
              <br />
              <br />
              <label htmlFor="timeTaken">When will this report expire? *</label>
              <input
                type="text"
                placeholder="Type a date in the format: dd/mm/yyy"
                value={typeDate}
                maxLength={10}
                className={style.expiry}
                onChange={(e) => {
                  setDateError("");
                  setTypeDate(e.target.value);
                }}
              />
              {dateError && <p className="error">{dateError}</p>}
            </>
          )}

          {status === "fail" && (
            <>
              <div>
                <h2 style={{ textAlign: "center" }}>Create a quote</h2>
                <br />
                <Table bordered className={style.table}>
                  <thead>
                    <tr>
                      <th className={style.thDescription}>Description</th>
                      <th className={style.th}>Quantity</th>
                      <th className={style.th}>Unit Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td className={style.td}>
                            <input
                              className={style.input}
                              onChange={(e) => {
                                setAddRowError("");
                                quoteItems[index] = {
                                  ...quoteItems[index],
                                  description: e.target.value,
                                };
                              }}
                            />
                          </td>
                          <td className={style.td}>
                            {" "}
                            <input
                              type="number"
                              defaultValue={0}
                              className={style.input}
                              onChange={(e) => {
                                setAddRowError("");
                                quoteItems[index] = {
                                  ...quoteItems[index],
                                  quantity: +e.target.value,
                                };
                                updatePrice();
                              }}
                            />
                          </td>
                          <td className={style.td}>
                            {" "}
                            <input
                              type="number"
                              defaultValue={0}
                              className={style.input}
                              onChange={(e) => {
                                setAddRowError("");
                                quoteItems[index] = {
                                  ...quoteItems[index],
                                  price: +e.target.value,
                                };
                                updatePrice();
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {addRowError && <p className="error">{addRowError}</p>}
                <div className={style.rowBtnGroup}>
                  {rows.length > 1 ? (
                    <button
                      className={buttonStyle.secondaryBtn}
                      onClick={() => {
                        removeRow();
                      }}
                    >
                      Remove Last Row
                    </button>
                  ) : (
                    <div></div>
                  )}
                  <button
                    className={buttonStyle.button}
                    onClick={() => {
                      addRow();
                    }}
                  >
                    Add New Row
                  </button>
                </div>

                <div className={style.priceContainer}>
                  {!!totalPrice && (
                    <div className={style.price}>
                      <h3>Total Price:</h3>
                      <p>£{totalPrice}</p>
                    </div>
                  )}

                  <label htmlFor="timeTaken">
                    How long will the remedial works take? *
                  </label>
                  <div className={style.timeTaken}>
                    <input
                      type="text"
                      min="1"
                      id="timeTaken"
                      value={num}
                      onChange={(e) => {
                        setDateError("");
                        if (
                          (isNaN(+e.target.value) && e.target.value !== "") ||
                          e.target.value.includes(" ")
                        ) {
                          setNum("");
                          return;
                        }
                        setNum(e.target.value);
                        if (duration === "hours" && e.target.value === "1") {
                          setDuration("hour");
                          setTimeTaken(`${e.target.value} hour`);
                        } else if (
                          duration === "days" &&
                          e.target.value === "1"
                        ) {
                          setDuration("day");
                          setTimeTaken(`${e.target.value} day`);
                        } else if (
                          duration === "hours" &&
                          e.target.value !== "1"
                        ) {
                          setTimeTaken(`${e.target.value} hours`);
                        } else if (
                          duration === "days" &&
                          e.target.value !== "1"
                        ) {
                          setTimeTaken(`${e.target.value} days`);
                        } else if (
                          duration === "hour" &&
                          e.target.value !== "1"
                        ) {
                          setDuration("hours");
                          setTimeTaken(`${e.target.value} hours`);
                        } else if (
                          duration === "day" &&
                          e.target.value !== "1"
                        ) {
                          setDuration("days");
                          setTimeTaken(`${e.target.value} days`);
                        }
                      }}
                    />
                    <select
                      id="duration"
                      name="duration"
                      value={duration}
                      onChange={(e) => {
                        setDuration(e.target.value);
                        setTimeTaken(`${num} ${e.target.value}`);
                      }}
                    >
                      <option value={num === "1" ? "hour" : "hours"}>
                        {num === "1" ? "hour" : "hours"}
                      </option>
                      <option value={num === "1" ? "day" : "days"}>
                        {num === "1" ? "day" : "days"}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </>
          )}

          {status === "upload" && (
            <>
              <h3>Upload file</h3>
              <BookingSummaryCard booking={certificate.booking} />

              <h5>Quote Summary</h5>
              <QuoteSummaryCard
                totalPrice={totalPrice}
                quoteItems={quoteItems}
                timeTaken={timeTaken}
              />
              <h5>File</h5>
              <div className={style.uploadInputParent}>
                <input
                  id="file"
                  type="file"
                  accept="application/pdf"
                  className={style.uploadInput}
                  onChange={(e) => {
                    setFileError("");
                    setFile(e.target.files);
                  }}
                />
                <FaUpload className={style.uploadIcon} />
                {file ? (
                  <div>
                    <p>{file[0]?.name}</p>
                  </div>
                ) : (
                  <p>Click here to upload file</p>
                )}
              </div>
              <br />
              {file && (
                <button
                  className={buttonStyle.button}
                  onClick={() => {
                    setFile(null);
                  }}
                >
                  Remove Selected File
                </button>
              )}
              <br />
              <br />
              {fileError && (
                <p className="error" style={{ textAlign: "center" }}>
                  {fileError}
                </p>
              )}
              <label htmlFor="timeTaken">When will this report expire? *</label>
              <input
                type="text"
                placeholder="Type a date in the format: dd/mm/yyy"
                value={typeDate}
                maxLength={10}
                className={style.expiry}
                onChange={(e) => {
                  setDateError("");
                  setTypeDate(e.target.value);
                }}
              />
              {dateError && <p className="error">{dateError}</p>}
              <br />
            </>
          )}
        </Modal.Body>
        {status === "fail" && (
          <Modal.Footer>
            <Button onClick={handleClose} className="btn secondaryBtn">
              Close
            </Button>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <Button
                className={buttonStyle.modalBtn}
                onClick={() => {
                  createQuote();
                }}
              >
                Create Quote{" "}
              </Button>
            )}

            {uploadErrorMsg && <p className="error">{uploadErrorMsg}</p>}
          </Modal.Footer>
        )}
        {(status === "upload" || status === "pass") && (
          <Modal.Footer>
            <Button onClick={handleClose} className="btn secondaryBtn">
              Close
            </Button>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <Button
                className={buttonStyle.modalBtn}
                onClick={(e) => {
                  submitFile(e);
                }}
              >
                Upload
              </Button>
            )}

            {uploadErrorMsg && <p className="error">{uploadErrorMsg}</p>}
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}
