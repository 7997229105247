import { useEffect, useState } from "react";
import { BottomSpacer, TopSpacer } from "../../spacer";
import style from "../../../styles/dashboard-customer.module.scss";
import buttonStyle from "../../../styles/button-style.module.scss";
import {
  shortenString,
  shortenStringCompletely,
} from "../../../utils/funcs/shorten-string";
import CancelModal from "../../modals/cancel-booking";
import EditModal from "../../modals/edit-booking";
import { Link } from "react-router-dom";
import downArrow from "../../../assets/downArrow.png";
import lessSign from "../../../assets/lessSign.svg";
import SignOutButton from "../../sign-out";
import { RedirectToSignIn, SignedOut } from "@clerk/clerk-react";
import DisplayDate from "../../display-date";
import { Tabs, Tab } from "react-bootstrap";
import { getCustomerBookingsWithCerts } from "../../../utils/funcs/api/get-customer-bookings-with-certs";
import { BookingWithCert } from "../../../types/BookingWithCert";
import { ToastContainer } from "react-toastify";
import ExtraInfo from "../../booking-extra-info";

export default function Bookings({
  serverURL,
  user,
  editUpdate,
  setEditUpdate,
}: any): React.ReactElement {
  const [currentSelected, setCurrentSelected] = useState("");
  const [showEditModal, setShowEditModal] = useState<Boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<Boolean>(false);

  const [pastBookings, setPastBookings] = useState<BookingWithCert[]>([]);
  const [futureBookings, setFutureBookings] = useState<BookingWithCert[]>([]);
  const [loading, setLoading] = useState<Boolean>(true);

  useEffect(() => {
    getCustomerBookingsWithCerts({
      user,
      setPastBookings,
      setFutureBookings,
      setLoading,
      serverURL,
    });
  }, [user, editUpdate, serverURL]);

  const handleEditUpdate = () => {
    setEditUpdate(!editUpdate);
  };

  const showSelectedBooking = (bookingID: string) => {
    if (currentSelected === bookingID) {
      setCurrentSelected("");
    } else {
      setCurrentSelected(bookingID);
    }
  };

  // check if booking is 24 hours before:
  const canCancel = (date: string) => {
    const bookingDate = new Date(date);
    const today = new Date();
    const timeDifference = bookingDate.getTime() - today.getTime();
    const hoursDifference = timeDifference / (1000 * 3600);
    return hoursDifference <= 24;
  };

  return (
    <div className={style.dashboard}>
      <TopSpacer />

      <>
        <ToastContainer className={style.toastPosition} />
        <div className={style.linkGroups}>
          <img src={lessSign} alt="" />
          <Link to="/dashboard">Back to dashboard</Link>
        </div>

        <br />
        <h1>Bookings</h1>
        <br />
        <div>
          <Tabs
            defaultActiveKey="Upcoming"
            id="uncontrolled-tab-example"
            className="mb-3"
            fill
          >
            <Tab eventKey="Upcoming" title="Upcoming">
              {editUpdate}
              {loading ? (
                <>
                  <div className="loadingBtn">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </>
              ) : (
                <>
                  {futureBookings.length ? (
                    <>
                      {futureBookings.map(({ booking }: any, index) => {
                        return (
                          <div
                            key={booking.bookingID}
                            className={style.bookingCard}
                            onClick={() => {
                              showSelectedBooking(booking.bookingID);
                              canCancel(booking.start);
                            }}
                          >
                            <div className={style.booking}>
                              <div className={style.column1}>
                                {booking.displayDate ? (
                                  <DisplayDate date={booking.displayDate} />
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className={style.column2}>
                                {currentSelected !== booking.bookingID && (
                                  <div className={style.inline}>
                                    <p>
                                      {index === 0
                                        ? shortenStringCompletely(
                                            booking.productName
                                          )
                                        : shortenString(booking.productName)}
                                    </p>

                                    <img
                                      src={downArrow}
                                      className={style.downArrow}
                                      alt="downArrow
                                       sign"
                                    />
                                  </div>
                                )}
                                {index === 0 && (
                                  <p
                                    className={
                                      currentSelected === booking.bookingID
                                        ? style.nextSelected
                                        : style.valid
                                    }
                                  >
                                    UPCOMING
                                  </p>
                                )}
                              </div>
                            </div>
                            {currentSelected === booking.bookingID && (
                              <div className={style.extraInfo}>
                                <ExtraInfo past={false} booking={booking} />
                                <br />
                                {!booking.cancelled ? (
                                  canCancel(booking.start) ? (
                                    <p style={{ textAlign: "center" }}>
                                      This booking cannot be amended
                                    </p>
                                  ) : (
                                    <div className={style.btnGroup}>
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setShowEditModal(true);
                                        }}
                                        className={buttonStyle.button}
                                      >
                                        Edit booking
                                      </button>
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setShowCancelModal(true);
                                        }}
                                        className={buttonStyle.button}
                                      >
                                        Cancel booking
                                      </button>
                                      {showEditModal && (
                                        <EditModal
                                          show={showEditModal}
                                          setShowEditModal={setShowEditModal}
                                          booking={booking}
                                          handleEditUpdate={handleEditUpdate}
                                        />
                                      )}
                                      {showCancelModal && (
                                        <CancelModal
                                          show={showCancelModal}
                                          setShowCancelModal={
                                            setShowCancelModal
                                          }
                                          booking={booking}
                                          handleEditUpdate={handleEditUpdate}
                                        />
                                      )}
                                    </div>
                                  )
                                ) : (
                                  <p className={style.cancelledConfirmation}>
                                    This booking has been cancelled.
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <p>You have no upcoming bookings to display</p>
                  )}
                </>
              )}
            </Tab>
            <Tab eventKey="Completed" title="Completed">
              {loading ? (
                <>
                  <div className="loadingBtn">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </>
              ) : (
                <>
                  {pastBookings.length ? (
                    <>
                      {pastBookings.map(({ booking, certificate }: any) => {
                        return (
                          <div
                            key={booking.bookingID}
                            className={style.bookingCard}
                            onClick={() => {
                              showSelectedBooking(booking.bookingID);
                              canCancel(booking.start);
                            }}
                          >
                            <div className={style.booking}>
                              <div className={style.column1}>
                                {booking.displayDate ? (
                                  <DisplayDate date={booking.displayDate} />
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className={style.column2}>
                                {currentSelected !== booking.bookingID && (
                                  <div className={style.inline}>
                                    <p>{shortenString(booking.productName)}</p>

                                    <img
                                      src={downArrow}
                                      className={style.downArrow}
                                      alt="downArrow than sign"
                                    />
                                  </div>
                                )}
                                {booking.cancelled ? (
                                  <p
                                    className={
                                      currentSelected === booking.bookingID
                                        ? style.cancelledSelected
                                        : style.cancelled
                                    }
                                  >
                                    CANCELLED
                                  </p>
                                ) : (
                                  <p
                                    className={
                                      currentSelected === booking.bookingID
                                        ? style.completeSelected
                                        : style.complete
                                    }
                                  >
                                    COMPLETE
                                  </p>
                                )}
                              </div>
                            </div>
                            {currentSelected === booking.bookingID && (
                              <div className={style.extraInfo}>
                                <ExtraInfo past={true} booking={booking} />
                                {certificate ? (
                                  <p
                                    className="valid"
                                    style={{ textAlign: "center" }}
                                  >
                                    The report for this booking is now available
                                    in{" "}
                                    <a href="/dashboard/reports">
                                      Your reports
                                    </a>
                                    .
                                  </p>
                                ) : (
                                  <></>
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <p>You have no past bookings to display</p>
                  )}
                </>
              )}
            </Tab>
          </Tabs>
        </div>
        <SignOutButton />
      </>

      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
      <BottomSpacer />
    </div>
  );
}
