import { BottomSpacer, TopSpacer } from "../spacer";

import { SignedIn, SignedOut, RedirectToSignIn } from "@clerk/clerk-react";
import SignOutButton from "../sign-out";
import Customer from "./customer/customer-portal";
import Admin from "./admin/admin-portal";

export default function Dashboard({ user, role }: any): React.ReactElement {
  return (
    <div>
      <TopSpacer />
      <br />
      <SignedIn>
        <h1>Hi, {user?.firstName} 👋🏼</h1>
        <br />
        <h2 style={{ width: "90%", margin: "auto" }}>
          Welcome to the Teck Engineers {role === "user" ? "Customer" : "Admin"}{" "}
          Portal
        </h2>
        {role === "user" && <Customer />}
        {role === "admin" && <Admin />}
        <SignOutButton />
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
      <BottomSpacer />
      <BottomSpacer />
    </div>
  );
}
