import { useNavigate } from "react-router-dom";
import light from "../assets/light.svg";
import { services } from "../utils/objects/services-object";
import style from "../styles/serviceExamples.module.scss";
import Button from "./button";

export default function ServiceExamples() {
  const servicesExamples = [services[0], services[1]];

  let navigate = useNavigate();

  return (
    <div className={style.servicesContainer}>
      {servicesExamples.map((service, i) => {
        return (
          <div
            className={style.serviceExampleCard}
            key={i}
            onClick={() => {
              let path = `/book-now/${service.slug}`;
              navigate(path);
            }}
          >
            {service.imgURL && (
              <img src={service.icon} className={style.img} alt="service" />
            )}
            <div className={style.serviceExampleContainer}>
              <div>
                <h4 className={style.serviceName}>{service.title}</h4>
                <p className={style.serviceDescription}>{service.tagline}</p>
                <Button
                  onClick={() => {
                    let path = `/book-now/${service.slug}`;
                    navigate(path);
                  }}
                >
                  Book now &gt;
                </Button>
              </div>
            </div>
          </div>
        );
      })}

      <div className={style.serviceExampleCard}>
        <img
          src={light}
          className={style.img}
          onClick={() => {
            let path = "/book-now";
            navigate(path);
          }}
          alt="service"
        />

        <div className={style.serviceExampleContainer}>
          <div>
            <h4 className={style.serviceName}>Electrical Services</h4>
            <p className={style.serviceDescription}>
              See our other electrical services
            </p>
            <Button
              onClick={() => {
                let path = "/book-now";
                navigate(path);
              }}
            >
              Book now &gt;
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
