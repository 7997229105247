import axios from "axios";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import buttonStyle from "../../styles/button-style.module.scss";
import style from "../../styles/modal.module.scss";
import { toast } from "react-toastify";
import { getServerURL } from "../../utils/funcs/get-serverURL";

function CancelModal({
  show,
  setShowCancelModal,
  booking,
  handleEditUpdate,
}: any) {
  const [loading, setLoading] = useState(false);

  const serverURL = getServerURL();

  const notify = () => {
    toast.success("Booking updated successfully", {
      position: "top-left",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${serverURL}/refund`, {
        refundID: booking.payment_intent,
        booking: booking,
      });

      if (response) {
        setLoading(false);
        handleEditUpdate();
        setShowCancelModal(false);
        notify();
        console.log(response);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Modal
        show={show}
        onHide={() => {
          setShowCancelModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className={style.title}>
            Cancel your upcoming booking
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to cancel your booking?</p>
          <p>You will receive a full refund of £{booking.paid}.</p>
          <p>This action cannot be undone.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowCancelModal(false);
            }}
          >
            Close
          </Button>
          {loading ? (
            <>
              <div className={style.loadingBtn}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </>
          ) : (
            <Button
              variant="primary"
              onClick={handleSubmit}
              type="submit"
              className={buttonStyle.modalBtn}
            >
              Cancel Booking
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CancelModal;
