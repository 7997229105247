import axios from "axios"

export const validateEmail = async (email: string) => {
    try {
      const response = await axios.get(`https://ws.postcoder.com/pcw/
      PCWNU-UC9YJ-R24LJ-MCMT6/email/${email}`);
      return response.data.valid;
    } catch (error) {
      // Handle any errors that might occur during the API call
      console.error("Error validating email:", error);
      return false; // Return false if there's an error
    }
  }
