import { BookingWithCert } from "../types/BookingWithCert";
import style from "../styles/dashboard-customer.module.scss";
import { createDisplayDate } from "../utils/funcs/create-displayDate";
import { createShortDate } from "../utils/funcs/createShortDate";
import { useMemo } from "react";

export default function PassQuoteBreakdown({
  bookingWithCert,
}: {
  bookingWithCert: BookingWithCert;
}) {
  const date = useMemo(() => {
    if (bookingWithCert.certificate?.expiry) {
      return createDisplayDate(
        createShortDate(new Date(bookingWithCert.certificate?.expiry))
      );
    }
  }, [bookingWithCert.certificate?.expiry]);

  return (
    <div className={style.extraInfo}>
      <p>Booking ID: {bookingWithCert.booking.bookingID}</p>
      <p className={style.heading}>Completed on</p>
      <p>
        {bookingWithCert.booking.emailDate} at{" "}
        {bookingWithCert.booking.emailStartTime}
      </p>
      <p className={style.heading}>Address</p>
      <p>{bookingWithCert.booking.address}</p>

      <p className={style.heading}>Report expires on</p>
      <p>
        {date?.day} {date?.dateOfMonth} {date?.month} {date?.year}
      </p>
    </div>
  );
}
