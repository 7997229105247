import { useEffect, useState } from "react";
import style from "../../styles/service-prices.module.scss";
import PATImg from "../../assets/pattesting.jpg";
import ServiceTemplateComponent from "./template";
import { formatEmailDate } from "../../utils/funcs/google-calendar";

export default function PATPrice() {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  if (tomorrow.getDay() === 0) {
    // Sunday
    tomorrow.setDate(tomorrow.getDate() + 1); // Move to Monday
  } 
  const basePrice = 65;
  const [appliances, setAppliances] = useState("1-10");
  const [inputAppliances, setInputAppliances] = useState(0);
  const [showAppliances, setShowAppliances] = useState(false);
  const [item, setItem] = useState({
    productName: "PAT Certificate",
    bookingID: " ",
    price: basePrice,
    productInfo: `No. of appliances: ${appliances}`,
    customerName: "",
    customerEmail: "",
    customerNumber: "",
    start: "",
    end: "",
    date: tomorrow,
    emailDate: formatEmailDate(tomorrow),
    emailStartTime: "",
    access: "Access provided by: Customer on booking",
    address: { line1: "", line2: " ", postTown: "", postcode: "" },
    finalPrice: 0
  });

  useEffect(() => {
    const minusTen = inputAppliances - 10;

    if (minusTen > 0) {
      const nearestTen = Math.ceil(minusTen / 10) * 10;
      const multiplier = nearestTen / 10;
      setItem((prevItems) => ({
        ...prevItems,
        price: basePrice + 20 * multiplier,
      }));
    } else
      setItem((prevItems) => ({
        ...prevItems,
        price: basePrice,
      }));
  }, [inputAppliances]);

  return (
    <ServiceTemplateComponent
      backgroundImage={PATImg}
      item={item}
      setItem={setItem}
      tomorrow={tomorrow}
      serviceDescription="PAT (Portable Appliance Testing) is a process that aims to assess the safety of electrical appliances. Our qualified electricians conduct visual inspections and electrical tests to detect any faults that may cause harm. We provide a detailed report with recommendations for repairs or improvements, ensuring compliance with regulations and standards."
    >
      <div>
        <label htmlFor="appliances">
          How many appliances do you require testing for?
        </label>

        <div className={style.allMinorWorksRadios}>
          <div className={style.minorWorksRadioBtns}>
            <input
              defaultChecked
              type="radio"
              name="appliancesRadio"
              value={"1-10"}
              id="1-10appliances"
              onChange={(e) => {
                setShowAppliances(false);
                setAppliances("1-10");
                setItem((prevItem) => ({
                  ...prevItem,
                  productInfo: `No. of appliances: ${e.target.value}`,
                  price: 65,
                }));
              }}
            />
            <label htmlFor="1-10appliances">1-10 appliances</label>
          </div>
          <div className={style.minorWorksRadioBtns}>
            <input
              type="radio"
              name="appliancesRadio"
              value={"11-20"}
              id="11-20appliances"
              onChange={(e) => {
                setShowAppliances(false);
                setAppliances("11-20");
                setItem((prevItem) => ({
                  ...prevItem,
                  productInfo: `No. of appliances: ${e.target.value}`,
                  price: 85,
                }));
              }}
            />
            <label htmlFor="11-20appliances">11-20 appliances</label>
          </div>
          <div className={style.minorWorksRadioBtns}>
            <input
              type="radio"
              name="appliancesRadio"
              value={"21-30"}
              id="21-30appliances"
              onChange={(e) => {
                setShowAppliances(false);
                setAppliances("21-30");
                setItem((prevItem) => ({
                  ...prevItem,
                  productInfo: `No. of appliances: ${e.target.value}`,
                  price: 105,
                }));
              }}
            />
            <label htmlFor="21-30appliances">21-30 appliances</label>
          </div>
          <div className={style.minorWorksRadioBtns}>
            <input
              type="radio"
              name="appliancesRadio"
              value={"31+"}
              id="31+appliances"
              onChange={(e) => {
                setShowAppliances(true);
                setAppliances("31+");
                setItem((prevItem) => ({
                  ...prevItem,
                  productInfo: `No. of appliances: ${e.target.value}`,
                  price: 125,
                }));
              }}
            />
            <label htmlFor="31+appliances">31+ appliances</label>
          </div>
        </div>

        {showAppliances && (
          <input
            min="1"
            type="number"
            placeholder="No. of appliances"
            id="appliances"
            onChange={(e) => {
              if (e.target.value === "") {
                setInputAppliances(31);
                setItem((prevItem) => ({
                  ...prevItem,
                  productInfo: `No. of appliances: 1`,
                }));
              } else {
                setItem((prevItem) => ({
                  ...prevItem,
                  productInfo: `No. of appliances: ${e.target.value}`,
                }));
                setInputAppliances(+e.target.value);
              }
            }}
            onWheel={(e) => e.currentTarget.blur()}
          />
        )}
        <div className={style.list}>
          <p className={style.priceLabel}>Estimated price:</p>
          <p className={style.price}>£{item.price}</p>
        </div>
        <p style={{ textAlign: "left", fontSize: "0.9em" }}>
          <em>
            Your final price will be calculated based on your location before
            you go to the booking summary.
          </em>
        </p>
      </div>
    </ServiceTemplateComponent>
  );
}
