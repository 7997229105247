import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useState } from "react";
import style from "../styles/contact-form.module.scss";

export default function ContactForm() {
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const refs = { email: useRef(null), phone: useRef(null) };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const form = useRef(null);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

 
    if (form.current) {
      emailjs
        .sendForm(
          "service_t0727xs",
          "template_aocvz23",
          form.current,
          "SEKbPytJIG9Y9Opss"
        )
        .then(() => {
          setLoading(false);
          setFormData({ ...formData, message: "" });
          setSubmitted(true);
        })
        .catch((error: any) => {
          console.log(error.text);
        });
    }
  };

  return (
    <>
      <div className={style.formContainer}>
        {submitted ? (
          <>
            <h2>We've got your message!</h2>
            <p className={style.submitted}>
              Thanks for reaching out! We're on it and will respond ASAP.
            </p>
            <p className={style.submitted}>
              Check your inbox (or spam) for a confirmation email!
            </p>
            <button
              className={style.button}
              onClick={() => {
                setSubmitted(false);
              }}
            >
              Submit another enquiry
            </button>
          </>
        ) : (
          <div>
            <h2>Need something specific? Let us know</h2>
            <form ref={form} onSubmit={handleSubmit}>
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                className={style.input}
                name="firstName"
                value={formData.firstName}
                required
                onChange={(e) =>
                  setFormData({ ...formData, firstName: e.target.value })
                }
              />
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                className={style.input}
                name="lastName"
                id="lastName"
                value={formData.lastName}
                required
                onChange={(e) =>
                  setFormData({ ...formData, lastName: e.target.value })
                }
              />
              <label htmlFor="email">Email</label>
              <input
                className={style.input}
                name="email"
                id="email"
                type="email"
                ref={refs.email}
                value={formData.email}
                required
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
              <label htmlFor="phone">Phone</label>
              <input
                className={style.input}
                name="phone"
                id="phone"
                type="tel"
                ref={refs.phone}
                value={formData.phone}
                required
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
              />
              <label htmlFor="message">How can we help?</label>
              <textarea
                className={style.input}
                name="message"
                id="message"
                value={formData.message}
                required
                onChange={(e) =>
                  setFormData({ ...formData, message: e.target.value })
                }
              />
              <div />
              {loading ? (
                <div className={style.loadingBtn}>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                <button type="submit" className={style.button}>
                  Submit
                </button>
              )}
            </form>
          </div>
        )}
      </div>
    </>
  );
}
