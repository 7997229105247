import ContentLoader from "react-content-loader";

export default function TableLoader() {
    return (
    
        <tbody>
            <tr>
                  <td>
                    <ContentLoader viewBox="0 0 380 70">
                      {/* Only SVG shapes */}
                      <rect
                        x="20"
                        y="17"
                        rx="4"
                        ry="4"
                        width="300"
                        height="13"
                      />
                      <rect
                        x="20"
                        y="40"
                        rx="3"
                        ry="3"
                        width="250"
                        height="10"
                      />
                    </ContentLoader>
                  </td>
                  <td>
                    <ContentLoader viewBox="0 0 380 70">
                      {/* Only SVG shapes */}
                      <rect
                        x="20"
                        y="17"
                        rx="4"
                        ry="4"
                        width="300"
                        height="13"
                      />
                      <rect
                        x="20"
                        y="40"
                        rx="3"
                        ry="3"
                        width="250"
                        height="10"
                      />
                    </ContentLoader>
                  </td>
                  <td>
                    <ContentLoader viewBox="0 0 380 70">
                      {/* Only SVG shapes */}
                      <rect
                        x="20"
                        y="17"
                        rx="4"
                        ry="4"
                        width="300"
                        height="13"
                      />
                      <rect
                        x="20"
                        y="40"
                        rx="3"
                        ry="3"
                        width="250"
                        height="10"
                      />
                    </ContentLoader>
                  </td>
                  <td>
                    <ContentLoader viewBox="0 0 380 70">
                      {/* Only SVG shapes */}
                      <rect
                        x="20"
                        y="17"
                        rx="4"
                        ry="4"
                        width="300"
                        height="13"
                      />
                      <rect
                        x="20"
                        y="40"
                        rx="3"
                        ry="3"
                        width="250"
                        height="10"
                      />
                    </ContentLoader>
                  </td>
                  <td>
                    <ContentLoader viewBox="0 0 380 70">
                      {/* Only SVG shapes */}
                      <rect
                        x="20"
                        y="17"
                        rx="4"
                        ry="4"
                        width="300"
                        height="13"
                      />
                      <rect
                        x="20"
                        y="40"
                        rx="3"
                        ry="3"
                        width="250"
                        height="10"
                      />
                    </ContentLoader>
                  </td>
                </tr>
            <tr>
                  <td>
                    <ContentLoader viewBox="0 0 380 70">
                      {/* Only SVG shapes */}
                      <rect
                        x="20"
                        y="17"
                        rx="4"
                        ry="4"
                        width="300"
                        height="13"
                      />
                      <rect
                        x="20"
                        y="40"
                        rx="3"
                        ry="3"
                        width="250"
                        height="10"
                      />
                    </ContentLoader>
                  </td>
                  <td>
                    <ContentLoader viewBox="0 0 380 70">
                      {/* Only SVG shapes */}
                      <rect
                        x="20"
                        y="17"
                        rx="4"
                        ry="4"
                        width="300"
                        height="13"
                      />
                      <rect
                        x="20"
                        y="40"
                        rx="3"
                        ry="3"
                        width="250"
                        height="10"
                      />
                    </ContentLoader>
                  </td>
                  <td>
                    <ContentLoader viewBox="0 0 380 70">
                      {/* Only SVG shapes */}
                      <rect
                        x="20"
                        y="17"
                        rx="4"
                        ry="4"
                        width="300"
                        height="13"
                      />
                      <rect
                        x="20"
                        y="40"
                        rx="3"
                        ry="3"
                        width="250"
                        height="10"
                      />
                    </ContentLoader>
                  </td>
                  <td>
                    <ContentLoader viewBox="0 0 380 70">
                      {/* Only SVG shapes */}
                      <rect
                        x="20"
                        y="17"
                        rx="4"
                        ry="4"
                        width="300"
                        height="13"
                      />
                      <rect
                        x="20"
                        y="40"
                        rx="3"
                        ry="3"
                        width="250"
                        height="10"
                      />
                    </ContentLoader>
                  </td>
                  <td>
                    <ContentLoader viewBox="0 0 380 70">
                      {/* Only SVG shapes */}
                      <rect
                        x="20"
                        y="17"
                        rx="4"
                        ry="4"
                        width="300"
                        height="13"
                      />
                      <rect
                        x="20"
                        y="40"
                        rx="3"
                        ry="3"
                        width="250"
                        height="10"
                      />
                    </ContentLoader>
                  </td>
                </tr>
            <tr>
                  <td>
                    <ContentLoader viewBox="0 0 380 70">
                      {/* Only SVG shapes */}
                      <rect
                        x="20"
                        y="17"
                        rx="4"
                        ry="4"
                        width="300"
                        height="13"
                      />
                      <rect
                        x="20"
                        y="40"
                        rx="3"
                        ry="3"
                        width="250"
                        height="10"
                      />
                    </ContentLoader>
                  </td>
                  <td>
                    <ContentLoader viewBox="0 0 380 70">
                      {/* Only SVG shapes */}
                      <rect
                        x="20"
                        y="17"
                        rx="4"
                        ry="4"
                        width="300"
                        height="13"
                      />
                      <rect
                        x="20"
                        y="40"
                        rx="3"
                        ry="3"
                        width="250"
                        height="10"
                      />
                    </ContentLoader>
                  </td>
                  <td>
                    <ContentLoader viewBox="0 0 380 70">
                      {/* Only SVG shapes */}
                      <rect
                        x="20"
                        y="17"
                        rx="4"
                        ry="4"
                        width="300"
                        height="13"
                      />
                      <rect
                        x="20"
                        y="40"
                        rx="3"
                        ry="3"
                        width="250"
                        height="10"
                      />
                    </ContentLoader>
                  </td>
                  <td>
                    <ContentLoader viewBox="0 0 380 70">
                      {/* Only SVG shapes */}
                      <rect
                        x="20"
                        y="17"
                        rx="4"
                        ry="4"
                        width="300"
                        height="13"
                      />
                      <rect
                        x="20"
                        y="40"
                        rx="3"
                        ry="3"
                        width="250"
                        height="10"
                      />
                    </ContentLoader>
                  </td>
                  <td>
                    <ContentLoader viewBox="0 0 380 70">
                      {/* Only SVG shapes */}
                      <rect
                        x="20"
                        y="17"
                        rx="4"
                        ry="4"
                        width="300"
                        height="13"
                      />
                      <rect
                        x="20"
                        y="40"
                        rx="3"
                        ry="3"
                        width="250"
                        height="10"
                      />
                    </ContentLoader>
                  </td>
                </tr>
        </tbody>
)
}