import axios from "axios";
import { Booking } from "../../../types/BookingWithCert";
import { createShortDate } from "../createShortDate";

export const getAllBookings = async ({
  user,
  serverURL,
  setFutureBookings,
  setPastBookings,
  setLoading,
  setSearch,
}: any) => {
  try {
    if (user) {
      const response = await axios.post(`${serverURL}/all-bookings`, {
        email: user?.emailAddresses[0].emailAddress,
      });
      const today = new Date();
      const pastBookingsTemp: Booking[] = [];
      const futureBookingsTemp: Booking[] = [];

      response.data.forEach((booking: Booking) => {
        const bookingDate = new Date(booking.date);
        booking.shortDate = createShortDate(bookingDate);
        if (bookingDate < today || booking.cancelled) {
          pastBookingsTemp.push(booking);
        } else {
          futureBookingsTemp.push(booking);
        }
      });

      futureBookingsTemp.sort((a, b) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        return dateA - dateB;
      });

      setPastBookings(pastBookingsTemp);
      setFutureBookings(futureBookingsTemp);
      setLoading(false);
      setSearch((prev: any) => ({
        ...prev,
        listPastBookings: pastBookingsTemp,
        listFutureBookings: futureBookingsTemp,
      }));
    } else {
      setLoading(false);
    }
  } catch (err) {
    console.log(err);
    setLoading(false);
  }
};
