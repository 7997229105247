import { useMemo } from "react";
import style from "../styles/display-date.module.scss";
import { createDisplayDate } from "../utils/funcs/create-displayDate";

export default function DisplayDate({ date }: any): React.ReactElement {
  // date should be in the format: "21/03/2024", use createShortDate for this

  const displayDate = useMemo(() => {
    return createDisplayDate(date);
  }, [date]);

  return (
    <div className={style.container}>
      <p>{displayDate.day}</p>
      <p className={style.year}>
        {displayDate.dateOfMonth} {displayDate.month}
      </p>
      <p>{displayDate.year}</p>
    </div>
  );
}
