import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import success from "../assets/success.png";
import style from "../styles/checkout-pages.module.scss";
import Button from "../components/button";
import emailjs from "@emailjs/browser";
import { PaymentContext } from "../context/payment-success-context";
import { BottomSpacer } from "../components/spacer";

export default function FreeQuoteSuccess() {
  const { paymentSuccess, setPaymentSuccess } = useContext(PaymentContext);
  const storedItems = localStorage.getItem("item");
  const item = storedItems ? JSON.parse(storedItems) : null;

  useEffect(() => {
    setPaymentSuccess(true);
  }, [setPaymentSuccess]);

  useEffect(() => {
    const storedEmailSent = localStorage.getItem("emailSent");
    const emailSent = storedEmailSent ? JSON.parse(storedEmailSent) : null;

    if (item && paymentSuccess && !emailSent) {
      localStorage.setItem("emailSent", "true");
      emailjs
        .send("service_5zqhezh", "template_mp7udgq", item, "lPrMba9WggiTBU4jW")
        .then(
          (result: any) => {
            console.log(result.text);
          },
          (error: any) => {
            console.log(error.text);
          }
        );
    }
  }, [setPaymentSuccess, paymentSuccess, item]);

  let navigate = useNavigate();

  const handleClick = () => {
    let path = `/book-now`;
    navigate(path);
  };

  return (
    <div className={style.container}>
      <img src={success} className={style.icon} alt="green tick" />
      <h2 className={style.title}>
        Your free quotation with one of our electricians is booked!
      </h2>
      <div className={style.bookingDetails}>
        <p>Your booking details:</p>
        <table>
          <tbody>
            <tr>
              <td>Name:</td>
              <td className={style.rightAlign}>{item.customerName}</td>
            </tr>
            <tr>
              <td>Booking ID:</td>
              <td className={style.rightAlign}>{item.bookingID}</td>
            </tr>
            <tr>
              <td>Service:</td>
              <td className={style.rightAlign}>{item.productName}</td>
            </tr>
            <tr>
              <td>Paid:</td>
              <td className={style.rightAlign}>£{item.price}</td>
            </tr>
            <tr>
              <td>Date:</td>
              <td className={style.rightAlign}>{item.emailDate} </td>
            </tr>
            <tr>
              <td>Time:</td>
              <td className={style.rightAlign}>{item.emailStartTime}</td>
            </tr>
            <tr>
              <td>Address:</td>
              <td className={style.rightAlign}>
                {item.address.line1}, {item.address.postcode}
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          Keep an eye on your inbox - we've sent your email confirmation there.
          In case you don't find it there, we recommend checking your junk mail.
        </p>
      </div>
      <Button onClick={handleClick}>Book another service</Button>
      <p className={style.title}>
        Need to talk to someone about your booking?{" "}
        <a href="/contact-us">Contact us</a>
      </p>
      <BottomSpacer />
    </div>
  );
}
