import { Link, useLocation } from "react-router-dom";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";

export default function SideBar() {
  const location = useLocation();

  return (
    <div >
      <Sidebar>
        <Menu
          menuItemStyles={{
            button: ({ active }) => ({
              backgroundColor: active ? "#00acb666" : "#0098a0db",
              color: active ? "white" : "white",
              marginBottom: "2px",
              fontWeight: active ? "600" : "400",
            }),
          }}
        >
          <MenuItem component={<Link to="/dashboard" />}>Admin Portal</MenuItem>
          <MenuItem
            active={location.pathname === "/dashboard/admin/bookings"}
            component={<Link to="/dashboard/admin/bookings" />}
          >
            Bookings
          </MenuItem>
          <MenuItem active={location.pathname === "/dashboard/admin/reports"} component={<Link to="/dashboard/admin/reports" />}>
            Reports
          </MenuItem>
        </Menu>
      </Sidebar>
    </div>
  );
}