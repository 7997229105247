export const findEmptyInputFields = (refs: any) => {
    const emptyFields = [];
  
    for (const key in refs) {
      const fieldRef = refs[key];
      if (fieldRef.current && fieldRef.current.value.trim() === '') {
        emptyFields.push(key);
      }
    }
  
    return emptyFields;
  }