import { useEffect, useState } from "react";
import style from "../../../styles/dashboard-admin.module.scss";
import { RedirectToSignIn, SignedOut } from "@clerk/clerk-react";
import { getBookingWithCert } from "../../../utils/funcs/api/get-bookingWithCerts";
import { FaUpload } from "react-icons/fa6";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";

import SideBar from "../../sidebar";
import { BottomSpacer, TopSpacer } from "../../spacer";
import { Table } from "react-bootstrap";
import { BookingWithCert } from "../../../types/BookingWithCert";
import TableLoader from "../../table-loader";
import UploadFile from "../../modals/upload-file-modal";
import { ToastContainer } from "react-toastify";
import EditFile from "../../modals/edit-file-modal";
import DeleteFile from "../../modals/delete-file-modal";
import { sendExpiryEmail } from "../../../utils/funcs/api/send-expiry-email";
import { getServerURL } from "../../../utils/funcs/get-serverURL";

export default function FileUploader({ user }: any) {
  const serverURL = getServerURL();

  const [loading, setLoading] = useState(true);
  const [bookingWithCerts, setBookingWithCert] = useState<BookingWithCert[]>(
    []
  );
  const [showUpload, setShowUpload] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currentSelected, setCurrentSelected] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  const [remindersSent, setRemindersSent] = useState(false);

  useEffect(() => {
    getBookingWithCert({ user, serverURL, setBookingWithCert, setLoading });
  }, [user, serverURL, setBookingWithCert, setLoading, uploadSuccess]);

  useEffect(() => {
    if (!remindersSent && bookingWithCerts.length > 0) {
      const certsForReminder = bookingWithCerts.filter((cert) => {
        const today = new Date().getTime();
        let expiry: number;
        if (cert.certificate?.expiry && !cert.certificate?.expiryReminderSent) {
          expiry = new Date(cert.certificate?.expiry).getTime();

          const difference = Math.round((expiry - today) / (1000 * 3600 * 24));
          return difference <= 1;
        } else return false;
      });

      certsForReminder.forEach((cert) => {
        sendExpiryEmail(serverURL, cert.booking);
      });

      setRemindersSent(true);
    }
  }, [bookingWithCerts, remindersSent, serverURL]);

  const handleClose = () => {
    setCurrentSelected("");
  };

  return (
    <>
      {uploadSuccess}
      <TopSpacer />
      <ToastContainer className={style.toastPosition} />
      <div className={style.flexContainer}>
        <SideBar />
        <div className={style.column2}>
          <h1>Reports</h1>
          <br />
          <Table bordered hover className={style.table}>
            <thead>
              <tr>
                <th className={style.th}>Date/Time</th>
                <th className={style.th}>Customer</th>
                <th className={style.th}>Address</th>
                <th className={style.th}>Service</th>
                <th className={style.th}>Files</th>
              </tr>
            </thead>

            {loading ? (
              <TableLoader />
            ) : (
              <tbody>
                {bookingWithCerts &&
                  bookingWithCerts.map((certificate, index) => {
                    if (!certificate.booking.cancelled) {
                      return (
                        <tr key={index}>
                          <td>
                            {certificate.booking.longDate.day}{" "}
                            {certificate.booking.longDate.dateOfMonth}{" "}
                            {certificate.booking.longDate.month}{" "}
                            {certificate.booking.longDate.year} at{" "}
                            {certificate.booking.emailStartTime}
                          </td>
                          <td>{certificate.booking.customerName}</td>

                          <td>
                            {certificate.booking.addressLine1
                              ? `${certificate.booking.addressLine1}, ${certificate.booking.postcode}`
                              : certificate.booking.address}
                          </td>

                          <td>{certificate.booking.productName}</td>

                          {certificate.certificate ? (
                            <td className={style.editField}>
                              <button
                                className="button editBtn"
                                onClick={() => {
                                  setShowEdit(true);
                                  setShowDelete(false);
                                  setCurrentSelected(
                                    certificate.booking.bookingID
                                  );
                                }}
                              >
                                <MdOutlineEdit style={{ marginRight: "5px" }} />
                                Edit File
                              </button>
                              {currentSelected ===
                                certificate.booking.bookingID && (
                                <EditFile
                                  cert={certificate}
                                  setCertificates={setBookingWithCert}
                                  show={showEdit}
                                  handleClose={handleClose}
                                />
                              )}
                              <button
                                className="button secondaryBtn"
                                onClick={() => {
                                  setShowEdit(false);
                                  setShowDelete(true);
                                  setCurrentSelected(
                                    certificate.booking.bookingID
                                  );
                                }}
                              >
                                <MdOutlineDelete
                                  style={{ marginRight: "5px" }}
                                />
                                Delete File
                              </button>
                              {currentSelected ===
                                certificate.booking.bookingID && (
                                <DeleteFile
                                  setBookingWithCert={setBookingWithCert}
                                  cert={certificate}
                                  show={showDelete}
                                  handleClose={handleClose}
                                  deleteSuccess={deleteSuccess}
                                  setDeleteSuccess={setDeleteSuccess}
                                />
                              )}
                            </td>
                          ) : (
                            <td>
                              <button
                                className="button uploadBtn"
                                onClick={() => {
                                  setShowUpload(true);
                                  setCurrentSelected(
                                    certificate.booking.bookingID
                                  );
                                }}
                              >
                                <FaUpload style={{ marginRight: "5px" }} />
                                Upload File
                              </button>
                              {currentSelected ===
                                certificate.booking.bookingID && (
                                <UploadFile
                                  certificate={certificate}
                                  show={showUpload}
                                  handleClose={handleClose}
                                  uploadSuccess={uploadSuccess}
                                  setUploadSuccess={setUploadSuccess}
                                />
                              )}
                            </td>
                          )}
                        </tr>
                      );
                    }
                  })}
              </tbody>
            )}
          </Table>
        </div>
      </div>

      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
      <BottomSpacer />
    </>
  );
}
