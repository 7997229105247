import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { SignedIn, UserButton, useUser } from "@clerk/clerk-react";

import style from "../styles/header.module.scss";
import hamburger from "../assets/hamburger.png";
import logo from "../assets/electrician-logo2.png";
import { useState } from "react";
import cross from "../assets/cross.png";
import home from "../assets/home.png";
import contact from "../assets/contact.png";
import about from "../assets/electrical-worker-svgrepo-com.png";
import book from "../assets/book.png";
import avatar from "../assets/avatar.svg";
import greater from "../assets/greater.svg";
import { services } from "../utils/objects/services-object";

export default function Header(): React.ReactElement {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const toggle = isExpanded ? style.expanded : style.notExpanded;
  const linkExpanded = isExpanded ? style.linkExpanded : style.linkNotExpanded;
  const blur = isExpanded ? style.blur : style.displayNone;
  const icon = isExpanded ? style.icon : style.displayNone;
  const linkGroup = isExpanded ? style.linkGroup : "";
  const loginLinkGroup = isExpanded
    ? style.loginLinkGroup
    : style.loginLinkAvatar;
  const serviceLinkGroupMobile = isExpanded
    ? style.serviceLinkGroupMobile
    : style.serviceLinkGroupMobile;
  const serviceLinkGroupMedium = isExpanded ? style.serviceLinkGroupMedium : "";
  const { user } = useUser();
  let navigate = useNavigate();

  const serviceTabStyle = {
    marginBottom: "30px",
  };

  return (
    <nav className={style.nav}>
      <div>
        <div
          className={blur}
          onClick={() => {
            setIsExpanded(false);
          }}
        />

        <img
          src={logo}
          className={style.logo}
          alt="teck engineers logo"
          onClick={() => {
            setIsExpanded(false);
            setShowServices(false);
            let path = `/`;
            navigate(path);
          }}
        />
        <div className={toggle}>
          <div className={linkGroup}>
            <img src={home} className={icon} alt="home" />
            <Link
              to="/"
              onClick={() => {
                setIsExpanded(false);
              }}
              className={linkExpanded}
            >
              Home
            </Link>
          </div>

          <div className={linkGroup}>
            <img src={about} className={icon} alt="about" />
            <Link
              to="/about"
              onClick={() => {
                setIsExpanded(false);
              }}
              className={linkExpanded}
            >
              About
            </Link>
          </div>

          <div className={serviceLinkGroupMedium}>
            <Link
              to="/book-now"
              onClick={() => {
                setIsExpanded(false);
              }}
              className={linkExpanded}
            >
              Book Now
            </Link>
          </div>

          <div
            className={serviceLinkGroupMobile}
            style={showServices ? {} : serviceTabStyle}
          >
            <img src={book} className={icon} alt="services" />
            <p
              onClick={() => {
                setShowServices(!showServices);
              }}
              className={linkExpanded}
            >
              Services
            </p>
          </div>

          {showServices && (
            <div className={style.services}>
              <div className={style.serviceLink}>
                <Link
                  to="/book-now"
                  onClick={() => {
                    setIsExpanded(false);
                  }}
                >
                  Browse all services
                </Link>
                <img src={greater} alt="arrowhead right" />
              </div>

              {services.map((service) => {
                return (
                  <div className={style.serviceLink} key={service.id}>
                    <Link
                      to={`/book-now/${service.slug}`}
                      key={service.id}
                      onClick={() => {
                        setIsExpanded(false);
                      }}
                    >
                      {service.title}
                    </Link>
                    <img src={greater} alt="arrowhead right" />
                  </div>
                );
              })}
            </div>
          )}

          <div className={linkGroup}>
            <img src={contact} className={icon} alt="contact" />
            <Link
              to="/contact-us"
              onClick={() => {
                setIsExpanded(false);
              }}
              className={linkExpanded}
            >
              Contact Us
            </Link>
          </div>

          {/* <SignedIn>
            <div className={linkGroup}>
              <img src={bookings} className={icon} alt="bookings" />
              <Link
                to="/dashboard"
                onClick={() => {
                  setIsExpanded(false);
                }}
                className={linkExpanded}
              >
                My Bookings
              </Link>
            </div>
          </SignedIn> */}

          <div className={loginLinkGroup}>
            {!user && (
              <img
                src={avatar}
                className={style.accountIcon}
                alt="my account"
              />
            )}
            <SignedIn>
              <div className={style.userName}>
                <div className={style.userBtnContainer}>
                  <UserButton />
                </div>
                <Link
                  to="/dashboard"
                  className={linkExpanded}
                  onClick={() => {
                    setIsExpanded(false);
                  }}
                >
                  {user?.fullName}
                </Link>
              </div>
            </SignedIn>
            <Link
              to={"/dashboard"}
              onClick={() => {
                setIsExpanded(false);
              }}
              className={linkExpanded}
              style={{ color: "#00adb5", fontWeight: "600" }}
            >
              {!user && "Sign In"}
            </Link>
          </div>
        </div>

        {!isExpanded && (
          <img
            className={style.hamburger}
            src={hamburger}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
            alt="hamburger icon"
          />
        )}
        {isExpanded && (
          <img
            src={cross}
            className={style.cross}
            alt="red cross"
            onClick={() => {
              setIsExpanded(false);
            }}
          />
        )}
      </div>
    </nav>
  );
}
