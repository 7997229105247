import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SignIn, SignUp } from "@clerk/clerk-react";
import Footer from "../components/footer";
import Header from "../components/header";

import "../core/core.scss";
import Home from "../pages/home";
import Book from "../pages/book";
import Contact from "../pages/contact";
import About from "../pages/about";
import { services } from "../utils/objects/services-object";
import { ServiceTemplate } from "./service-router";
import ScrollToTop from "../utils/funcs/scroll-to-top";
import CheckoutSuccess from "../pages/checkout-success";
import CheckoutFail from "../pages/checkout-cancel";
import FreeQuoteSuccess from "../pages/free-quote-success";
import Dashboard from "../components/dashboard/dashboard";
import AdminBookings from "../components/dashboard/admin/all-bookings";
import { useUser } from "@clerk/clerk-react";
import FileUploader from "../components/dashboard/admin/all-certificates";
import { useEffect, useState } from "react";
import Bookings from "../components/dashboard/customer/bookings";
import { getRole } from "../utils/funcs/api/get-role";
import Certificates from "../components/dashboard/customer/certificates";
import { getServerURL } from "../utils/funcs/get-serverURL";

export default function Router() {
  const { isSignedIn, user } = useUser();

  const [editUpdate, setEditUpdate] = useState(false);

  const [role, setRole] = useState("");

  const serverURL = getServerURL();

  useEffect(() => {
    if (isSignedIn) {
      getRole({ user, serverURL, setRole });
    }
  }, [isSignedIn, user, serverURL]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/book-now" element={<Book />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/checkout-success" element={<CheckoutSuccess />} />
          <Route path="/booking-success" element={<FreeQuoteSuccess />} />
          <Route
            path="/sign-in/*"
            element={<SignIn routing="path" path="/sign-in" />}
          />
          <Route
            path="/sign-up/*"
            element={<SignUp routing="path" path="/sign-up" />}
          />
          <Route
            path="/dashboard"
            element={
              <Dashboard isSignedIn={isSignedIn} user={user} role={role} />
            }
          />
          <Route
            path="/dashboard/admin/bookings"
            element={<AdminBookings user={user} role={role} />}
          />
          <Route
            path="/dashboard/admin/reports"
            element={<FileUploader user={user} role={role} />}
          />
          <Route
            path="/dashboard/reports"
            element={<Certificates user={user} serverURL={serverURL} />}
          />
          <Route
            path="/dashboard/bookings"
            element={
              <Bookings
                user={user}
                serverURL={serverURL}
                editUpdate={editUpdate}
                setEditUpdate={setEditUpdate}
              />
            }
          />
          <Route path="/checkout-cancelled" element={<CheckoutFail />} />
          {services.map((service) => (
            <Route
              key={service.id}
              path={`/book-now/${service.slug}`}
              element={<ServiceTemplate service={service} />}
            />
          ))}
        </Routes>
      </main>
      <Footer />
    </BrowserRouter>
  );
}
