import style from "../../styles/service-prices.module.scss";
import { areAllValuesTruthy } from "../../utils/funcs/check-all-values-truthy";
import Calendar from "../calendar";
import Location from "../location";
import { useEffect, useMemo, useRef, useState } from "react";
import { BottomSpacer } from "../spacer";
import { findEmptyInputFields } from "../../utils/funcs/find-empty-input-ref";
import { validateEmail } from "../../utils/funcs/validate-email";
import { validateMobile } from "../../utils/funcs/validate-mobile";
import SummaryModal from "../modals/summary";
import ContactBanner from "../contact-banner";

export default function ServiceTemplateComponent({
  backgroundImage,
  item,
  setItem,
  children,
  serviceDescription,
  tomorrow,
  emergency,
  serviceRequired,
}: any) {
  const [finalPrice, setFinalPrice] = useState(item.price);
  const [isValid, setIsValid] = useState(null);
  const [showSummary, setShowSummary] = useState(false);
  const [error, setError] = useState("");
  const [access, setAccess] = useState({
    providedByOther: false,
    info: {
      name: item.customerName,
      email: item.customerEmail,
      number: item.customerNumber,
    },
  });
  const [errorMsgs, setErrorMsgs] = useState({
    telError: "",
    nameError: "",
    emailError: "",
    timeError: "",
    address1Error: "",
    postTownError: "",
    postcodeError: "",
    accessNameError: "",
    accessEmailError: "",
    accessNumberError: "",
  });

  const refs = {
    name: useRef(null),
    time: useRef(null),
    email: useRef(null),
    tel: useRef(null),
    address1: useRef(null),
    postTown: useRef(null),
    postcode: useRef(null),
    accessName: useRef(null),
    accessEmail: useRef(null),
    accessNumber: useRef(null),
  };

  const complete = useMemo(() => {
    return areAllValuesTruthy(Object.values(item));
  }, [item]);

  useEffect(() => {
    if (complete) {
      setError("");
    }
  }, [complete]);

  const handleShowSummary = async (e: any) => {
    e.preventDefault();
    setItem((prev: any) => ({
      ...prev,
      bookingID: Date.now(),
      finalPrice: finalPrice,
    }));
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("emailSent", "false");
    const emptyRefs = findEmptyInputFields(refs);

    setErrorMsgs({
      telError: "",
      emailError: "",
      timeError: "",
      address1Error: "",
      postTownError: "",
      postcodeError: "",
      nameError: "",
      accessNameError: "",
      accessEmailError: "",
      accessNumberError: "",
    });

    if (emptyRefs.length > 0) {
      emptyRefs.forEach((ref) => {
        setErrorMsgs((prev: any) => ({
          ...prev,
          [`${ref}Error`]: "Required",
        }));
      });
    }

    if (
      emptyRefs.includes("accessName") ||
      emptyRefs.includes("accessEmail") ||
      emptyRefs.includes("accessNumber")
    ) {
      // Prevent opening the summary modal if access-related fields are empty
      setShowSummary(false);
      setError("Please complete all required fields");
      return;
    }

    if (!complete) {
      setError("Please complete all required fields");
      return;
    }

    // email and phone validation

    const validBookingEmail = await validateEmail(item.customerEmail);
    const validBookingMobile = await validateMobile(item.customerNumber);

    if (!validBookingEmail) {
      setErrorMsgs((prev: any) => ({ ...prev, emailError: "Invalid email" }));
      setError("Please provide a valid email address");
      return;
    }
    if (!validBookingMobile) {
      setErrorMsgs((prev: any) => ({
        ...prev,
        telError: "Invalid phone number",
      }));
      setError("Please provide a valid phone number");
      return;
    }

    if (access.providedByOther) {
      const validAccessEmail = await validateEmail(access.info.email);
      const validAccessMobile = await validateMobile(access.info.number);
      if (!validAccessEmail) {
        setErrorMsgs((prev: any) => ({
          ...prev,
          accessEmailError: "Invalid email",
        }));
        setError("Please provide a valid email address");
        return;
      }
      if (!validAccessMobile) {
        setErrorMsgs((prev: any) => ({
          ...prev,
          accessNumberError: "Invalid phone number",
        }));
        setError("Please provide a valid phone number");
        return;
      }
    }

    // show booking summary only if all conditions are met
    if (isValid) {
      setShowSummary(true);
      setError("");
    }
  };

  return (
    <>
      <div
        className={style.imgContainer}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <div className={style.container}>
        <div className={style.intro}>
          <h3 className={style.heading}>{item.productName}</h3>
          <p className={style.description}>{serviceDescription}</p>
          {emergency && (
            <>
              <ContactBanner emergency={emergency} />
              <div className={style.spacer} />
            </>
          )}
          <p className={style.availability}>
            Check out our availability and book the date and time that works for
            you.
          </p>
        </div>
        <form onSubmit={handleShowSummary}>
          <div className={style.flex}>
            <div className={style.flexItem1}>
              <h3 className={style.heading}>Service Details</h3>
              <div className={style.divider} />
              {children}
              <Calendar
                item={item}
                setItem={setItem}
                refs={refs}
                errorMsgs={errorMsgs}
                setErrorMsgs={setErrorMsgs}
                selectedDate={tomorrow}
                emergency={emergency}
              />
            </div>

            <div className={style.flexItem2}>
              <Location
                item={item}
                setFinalPrice={setFinalPrice}
                setItem={setItem}
                isValid={isValid}
                setIsValid={setIsValid}
                errorMsgs={errorMsgs}
                setErrorMsgs={setErrorMsgs}
                refs={refs}
                access={access}
                setAccess={setAccess}
              />
              {isValid ? (
                <>
                  <div className={style.list}>
                    <p className={style.priceLabel}>Final price:</p>
                    <p className={style.price}>£{finalPrice}</p>
                  </div>
                  <p style={{ textAlign: "left", fontSize: "0.9em" }}>
                    <em>This is your final price based on your location.</em>
                  </p>
                </>
              ) : (
                <>
                  <div className={style.list}>
                    <p className={style.priceLabel}>Estimated price:</p>
                    <p className={style.price}>£{item.price}</p>
                  </div>
                  <p style={{ textAlign: "left", fontSize: "0.9em" }}>
                    <em>Add your postcode to calculate your final price.</em>
                  </p>
                </>
              )}
              <button
                className={style.button}
                onClick={(e) => handleShowSummary(e)}
              >
                Go to booking summary
              </button>
              {error && <p className="error">{error}</p>}

              {showSummary && (
                <SummaryModal
                  show={showSummary}
                  finalPrice={finalPrice}
                  handleClose={() => {
                    setShowSummary(false);
                  }}
                  item={item}
                  setError={setError}
                  serviceRequired={serviceRequired}
                />
              )}
            </div>
          </div>
        </form>
      </div>
      <BottomSpacer />
    </>
  );
}
