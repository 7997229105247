import { Booking } from "../types/BookingWithCert";
import style from "../styles/booking-extra-info.module.scss";

export default function ExtraInfo({
  past,
  booking,
}: {
  past?: Boolean;
  booking: Booking;
}) {
  return (
    <div className={style.extraInfo}>
      <p className={style.headingGroup}>Booking ID: {booking.bookingID}</p>
      {booking.cancelled && <p className={style.cancelled}>CANCELLED</p>}
      <div className={style.headingGroup}>
        <p className={style.heading}>Service</p>
        <p style={{marginBottom: "1px"}}>{booking.productName}</p>
        <p className={style.address}>{booking.productInfo}</p>
      </div>
      <div className={style.headingGroup}>
        <p className={style.heading}>{past ? "Completed on" : "Date/Time"}</p>
        <p className={style.address}>
          {booking.emailDate} at {booking.emailStartTime}
        </p>
      </div>
      <div className={style.headingGroup}>
        <p className={style.heading}>Address</p>
        <p className={style.address}>{booking.address}</p>
      </div>
      <div className={style.headingGroup}>
        <p className={style.heading}>Access</p>
        <p className={style.access}>{booking.access}</p>
      </div>
      <div className={style.headingGroup}>
        <p className={style.heading}>Payment Status</p>
        <p className={style.paid}>
          {booking.cancelled ? "Refunded: " : "Paid: "} £{booking.paid}
        </p>
      </div>
    </div>
  );
}
