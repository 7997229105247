import axios from "axios";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import buttonStyle from "../../styles/button-style.module.scss";
import style from "../../styles/modal.module.scss";
import { getServerURL } from "../../utils/funcs/get-serverURL";

function SummaryModal({
  show,
  handleClose,
  item,
  setError,
  serviceRequired,
}: any) {
  const [loading, setLoading] = useState(false);

  const serverURL = getServerURL();
  
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    localStorage.setItem("item", JSON.stringify(item));
    setLoading(true);
    setError("");
    if (serviceRequired === "Full Rewiring") {
      try {
        const res = await axios.post(`${serverURL}/book-free-quotation`, {
          item,
        });
        setLoading(false);
        if (res) {
          window.location.href = "/booking-success";
        }
      } catch (err: any) {
        setLoading(false);
        console.log(err.message);
      }
    } else {
      try {
        const res = await axios.post(`${serverURL}/create-checkout-session`, {
          item,
        });
        setLoading(false);
        if (res.data.url) {
          window.location.href = res.data.url;
        }
      } catch (err: any) {
        setLoading(false);
        console.log(err.message);
      }
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Booking Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={style.modalBody}>
            <p>Please ensure all booking details below are correct:</p>
            <h2>Service details</h2>
            <div className={style.divider} />
            <p>Service: {item.productName}</p>
            <p>Total price: £{item.finalPrice}</p>
            <p>{item.productInfo}</p>
            <h2>Customer details</h2>
            <div className={style.divider} />
            <p>Name: {item.customerName}</p>
            <p>Email: {item.customerEmail}</p>
            <p>Contact number: {item.customerNumber}</p>
            <h2>Appointment details</h2>
            <div className={style.divider} />
            <p>Date: {item.emailDate}</p>
            <p>Time: {item.emailStartTime}</p>
            <div>
              <p>
                Address: {item.address.line1}, {item.address.postTown},{" "}
                {item.address.postcode}
              </p>
            </div>
            <p>{item.access}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className={style.closeBtns}>
          <p className={style.redirect}>
            By clicking 'Pay', you confirm that all details provided are
            correct. You will be redirected to Stripe's secure checkout page to
            complete your payment.
          </p>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {loading ? (
            <>
              <div className={style.loadingBtn}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </>
          ) : (
            <Button
              variant="primary"
              onClick={handleSubmit}
              type="submit"
              className={buttonStyle.modalBtn}
            >
              {serviceRequired === "Full Rewiring"
                ? "Book now"
                : `Pay £${item.finalPrice}`}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SummaryModal;
