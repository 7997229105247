import axios from "axios";
import { BookingWithCert } from "../../../types/BookingWithCert";
import { createDisplayDate } from "../create-displayDate";
import { createShortDate } from "../createShortDate";

export const getBookingWithCert = async ({
  user,
  serverURL,
  setBookingWithCert,
  setLoading,
}: any) => {
  try {
    const result = await axios.post(`${serverURL}/get-certificates`, {
      email: user?.emailAddresses[0].emailAddress,
    });
    const dateAdded = result.data.map((data: BookingWithCert) => {
      data.booking.shortDate = createShortDate(new Date(data.booking.date))
      data.booking.longDate = createDisplayDate(data.booking.shortDate)
      return data
    })
    
    dateAdded.sort((a: any, b: any) => {
      a = a.booking.shortDate.split('/').reverse().join('');
      b = b.booking.shortDate.split('/').reverse().join('');
      return a < b ? 1 : a > b ? -1 : 0;
    })
    setBookingWithCert(dateAdded);
    if (result && user?.emailAddresses[0].emailAddress !== undefined) {
      setLoading(false);
    }
  } catch (err) {
    if (err) {
      setLoading(false);
    }
  }
};
