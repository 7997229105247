import style from "../../../styles/dashboard-customer.module.scss";
import adminStyle from "../../../styles/dashboard-admin.module.scss";
import { Link, useNavigate } from "react-router-dom";
import greater from "../../../assets/greater.svg";
import SideBar from "../../sidebar";
import { BottomSpacer } from "../../spacer";

export default function Admin() {
  let navigate = useNavigate();

  return (
    <div className={style.dashboard}>
      <br />
      <h4>Where would you like to go?</h4>
      <br />
      <br />
      <div className={adminStyle.container}>
         <div
          className={adminStyle.adminOptions}
          onClick={() => {
            navigate("/dashboard/admin/bookings");
          }}
        >
          <p>Bookings</p>
        </div>
        <div
          className={adminStyle.adminOptions}
          onClick={() => {
            navigate("/dashboard/admin/reports");
          }}
        >
          <p>Reports</p>
        </div>
      </div>
     <BottomSpacer/>
    </div>
  );
}
