import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import fail from "../assets/fail.png";
import style from "../styles/checkout-pages.module.scss";
import Button from "../components/button";

export default function CheckoutFail({ name }: any) {
  useEffect(() => {}, []);
  let navigate = useNavigate();

  const handleClick = () => {
    let path = `/book-now`;
    navigate(path);
  };

  return (
    <div className={style.container}>
      <img src={fail} className={style.icon} alt="red cross" />
      <h2 className={style.title}>Sorry, we've failed to take your payment</h2>
      <p>Please contact your payment provider for more information.</p>
      <Button onClick={handleClick}>Book another service</Button>
      <p className={style.title}>
        Need to talk to someone about your booking?{" "}
        <a href="/contact-us">Contact us</a>
      </p>
    </div>
  );
}
