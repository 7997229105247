export const capitaliseFirstLetter = (string: string) => {
    const wordsArray = string.split(" ")
    const wordsMapped = wordsArray.map((word: string) => {
        if (word !== "") {
        return word[0].toUpperCase() + word.slice(1, string.length);
      }
      return word;
    })
    return wordsMapped.join(" ")
};
