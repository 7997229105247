import { BookingWithCert } from "../types/BookingWithCert";
import style from "../styles/dashboard-customer.module.scss";
import { Quote } from "../types/QuoteItems";
import { Table } from "react-bootstrap";
import { useMemo } from "react";
import { createDisplayDate } from "../utils/funcs/create-displayDate";
import { createShortDate } from "../utils/funcs/createShortDate";

export default function FailQuoteBreakdown({ bookingWithCert }: { bookingWithCert: BookingWithCert }) {
  const date = useMemo(() => {
    if (bookingWithCert.certificate?.expiry) {
      return createDisplayDate(
        createShortDate(new Date(bookingWithCert.certificate?.expiry))
      );
    }
  }, [bookingWithCert.certificate?.expiry]);

    return  (
      <div className={style.extraInfo}>
        <p>Booking ID: {bookingWithCert.booking.bookingID}</p>
        <p className={style.heading}>Completed on</p>
        <p>
          {bookingWithCert.booking.emailDate} at{" "}
          {bookingWithCert.booking.emailStartTime}
        </p>
        <p className={style.heading}>Address</p>
        <p>{bookingWithCert.booking.address}</p>
        <h6 className={style.quoteHeader}>
          Quote for Remedial Works
        </h6>
        <p className={style.heading}>Duration of Job</p>
        <p>{bookingWithCert.certificate?.timeTaken}</p>
        <p className={style.heading}>Quote Breakdown</p>
        <Table className={style.quoteBreakdownTable}>
          <tbody>
            {bookingWithCert.certificate?.quoteItemsArr?.map(
              (quote: Quote, index) => {
                return (
                  <tr key={index}>
                    <td>{quote.description}</td>
                    <td>{quote.quantity}</td>
                    <td>£{quote.price}</td>
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>
        <p className={style.heading}>Estimated Total</p>
        <p>£{bookingWithCert.certificate?.quoteTotal}</p>
        <p className={style.heading}>Report expires on</p>
      <p>
        {date?.day} {date?.dateOfMonth} {date?.month} {date?.year}
      </p>
      </div>)
}