import style from "../styles/contact-banner.module.scss";
import Button from "./button";

export default function ContactBanner({emergency}: {emergency?: string}) {
  return (
    <div className={emergency ? style.emergencyBanner : style.bannerContainer}>
      <h3>Call us today on</h3>
      <Button>
        <a href="tel:+447496445148" className={style.anchor}>
          07496445148
        </a>
      </Button>
      <h3>or</h3>
      <Button>
        <a
          href="https://wa.me/+447496445148"
          target="_blank"
          rel="noopener noreferrer"
          className={style.anchor}
        >
          Whatsapp us
        </a>
      </Button>
    </div>
  );
}
