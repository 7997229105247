import axios from "axios";

export async function getLatLonFromPostcode(postcode: string) {
  try {
    const res = await axios
      .get(`https://api.postcodes.io/postcodes/${postcode}`)
      .then((response) => {
        const lat = response.data.result.latitude.toString();
        const lon = response.data.result.longitude.toString();
        return { lat, lon };
      });
    return res;
  } catch (error) {
    console.error(error);
  }
}