import EICRPrice from "../components/services/EICR";
import { ServicesExamples } from "../utils/objects/services-object";
import PATPrice from "../components/services/PAT";
import MinorElectricalWorks from "../components/services/minor-electrical-works";
import EmergencyCallouts from "../components/services/emergency-callout";
import FireAlarm from "../components/services/fire-alarm";
import RemedialWorks from "../components/services/remedial-works";
import LightingTesting from "../components/services/lighting-testing";

const componentMapping: {
  [slug: string]: React.FunctionComponent<{ service: ServicesExamples }>;
} = {
  "EICR-certificate": EICRPrice,
  "PAT-certificate": PATPrice,
  "minor-works": MinorElectricalWorks,
  "emergency-callouts": EmergencyCallouts,
  "fire-alarm-testing": FireAlarm,
  "emergency-lighting-testing": LightingTesting,
  "remedial-works": RemedialWorks,
};

export const ServiceTemplate: React.FunctionComponent<{
  service: ServicesExamples;
}> = ({ service }) => {
  const Component = componentMapping[service.slug] || <p>Failed to load</p>;
  return <Component service={service} />;
};

// import SocketsPrice from "./service-prices/sockets";
// import LightsPrice from "./service-prices/lights";
// import ConsumerUnitsPrice from "./components/service-prices/consumer-unit";
// import SwitchesPrice from "./service-prices/switches";
// "socket-installation": SocketsPrice,
// "light-fittings": LightsPrice,
// "new-consumer-unit-installation": ConsumerUnitsPrice,
// "switches-installation": SwitchesPrice,
