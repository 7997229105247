import style from "../styles/booking-summary-card.module.scss"

export default function BookingSummaryCard({ booking }: any) {
  return (
    <div className={style.bookingInfo}>
      <p>
       Booking ID:{" "}
        <span className={style.strong}>
          {booking.bookingID}
        </span>
      </p>
      <p>
        Date/Time:{" "}
        <span className={style.strong}>
          {booking.emailDate} at{" "}
          {booking.emailStartTime}
        </span>
      </p>
      <p>
        Customer:{" "}
        <span className={style.strong}>{booking.customerName}</span>
      </p>
      <p>
        Service:{" "}
        <span className={style.strong}>{booking.productName}</span>
      </p>
      <p>
        Address:{" "}
        <span className={style.strong}>{booking.address}</span>
      </p>
    </div>
  );
}
