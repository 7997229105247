import style from "../styles/home-banner-img.module.scss";
import ContactBanner from "../components/contact-banner";
import ServiceExamples from "../components/homepage-services-examples";
import ContactForm from "../components/contact-form";
import { BottomSpacer, MidSpacer } from "../components/spacer";
import electrician from "../assets/electrical-worker-svgrepo-com.png";
import SearchBar from "../components/search-services-bar";

export default function Home() {

  return (
    <div>
      <div className={style.spacer} />
      <ContactBanner />
      <div className={style.banner}>
        <div className={style.overlay} />
        <div className={style.textContainer}>
          <h1 className={style.title}>Expert Electrical Solutions</h1>
          <p className={style.bannerDescription}>
            Welcome to Teck Engineers, where your electrical needs are our top
            priority.
          </p>
          <ul className={style.checkList}>
            <li>Book fully qualified electricians online</li>
            <li>Next day appointments available</li>
            <li>
              <a href="/book-now" className={style.postcodeLink}>
                Check if we cover your address
              </a>
            </li>
          </ul>
          <SearchBar booking={false} />
        </div>
        <img
          src={electrician}
          alt="electrician"
          className={style.electrician}
        />
      </div>
      <ServiceExamples />
      <div className={style.reviewContainer}>
        <iframe src='https://widgets.sociablekit.com/google-reviews/iframe/25397113' width='100%' height='1000' title="GoogleReviews" className={style.reviewiFrame} />
      </div>
      <MidSpacer/>
      <ContactForm />
      <BottomSpacer />
    </div>
  );
}
