import { useEffect, useState } from "react";
import ServiceTemplateComponent from "./template";
import { formatEmailDate } from "../../utils/funcs/google-calendar";
import style from "../../styles/service-prices.module.scss";
import lightingTesting from "../../assets/lighting-testing.jpg";

export default function LightingTesting() {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  if (tomorrow.getDay() === 0) {
    // Sunday
    tomorrow.setDate(tomorrow.getDate() + 1); // Move to Monday
  } 

  const [item, setItem] = useState({
    bookingID: " ",
    productName: "Emergency Lighting Drain Testing",
    price: 79,
    productInfo: "1-5 lights",
    customerName: "",
    customerEmail: "",
    customerNumber: "",
    start: "",
    end: "",
    date: tomorrow,
    emailDate: formatEmailDate(tomorrow),
    emailStartTime: "",
    access: "Access provided by: Customer on booking",
    address: { line1: "", line2: " ", postTown: "", postcode: "" },
    finalPrice: 0
  });
  const [inputLights, setInputLights] = useState(0);
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    let productInfo = "1-5 lights";
    let price = 79;

    if (inputLights >= 1 && inputLights <= 5) {
      productInfo = "1-5 lights";
      price = 79;
    } else if (inputLights >= 6 && inputLights <= 10) {
      productInfo = "6-10 lights";
      price = 89;
    } else if (inputLights >= 11 && inputLights <= 15) {
      productInfo = "11-15 lights";
      price = 99;
    } else if (inputLights >= 16 && inputLights <= 20) {
      productInfo = "16-20 lights";
      price = 109;
    } else if (inputLights >= 21 && inputLights <= 25) {
      productInfo = "21-25 lights";
      price = 119;
    } else if (inputLights > 25) {
      const additionalLights = inputLights - 25;
      const additionalSetsOf5 = Math.ceil(additionalLights / 5);
      price = 119 + additionalSetsOf5 * 10;
      productInfo = `${inputLights} lights`;
    }

    setItem((prevItems) => ({
      ...prevItems,
      productInfo,
      price,
    }));
  }, [inputLights]);

  return (
    <>
      <ServiceTemplateComponent
        item={item}
        setItem={setItem}
        backgroundImage={lightingTesting}
        tomorrow={tomorrow}
        serviceDescription=""
      >
        <>
          <label htmlFor="lights">
            How many lights do you require testing for?
          </label>

          <div className={style.allMinorWorksRadios}>
            <div className={style.minorWorksRadioBtns}>
              <input
                defaultChecked
                type="radio"
                name="lightsRadio"
                value={"1-5"}
                id="1-5lights"
                onChange={(e) => {
                  setShowInput(false);
                  setItem((prevItem) => ({
                    ...prevItem,
                    productInfo: `${e.target.value} lights`,
                    price: 79,
                  }));
                }}
              />
              <label htmlFor="1-5lights">1-5 lights</label>
            </div>
            <div className={style.minorWorksRadioBtns}>
              <input
                type="radio"
                name="lightsRadio"
                value={"6-10"}
                id="6-10lights"
                onChange={(e) => {
                  setShowInput(false);
                  setItem((prevItem) => ({
                    ...prevItem,
                    productInfo: `${e.target.value} lights`,
                    price: 89,
                  }));
                }}
              />
              <label htmlFor="6-10lights">6-10 lights</label>
            </div>
            <div className={style.minorWorksRadioBtns}>
              <input
                type="radio"
                name="lightsRadio"
                value={"11-15"}
                id="11-15lights"
                onChange={(e) => {
                  setShowInput(false);
                  setItem((prevItem) => ({
                    ...prevItem,
                    productInfo: `${e.target.value} lights`,
                    price: 99,
                  }));
                }}
              />
              <label htmlFor="11-15lights">11-15 lights</label>
            </div>
            <div className={style.minorWorksRadioBtns}>
              <input
                type="radio"
                name="lightsRadio"
                value={"16-20"}
                id="16-20lights"
                onChange={(e) => {
                  setShowInput(false);
                  setItem((prevItem) => ({
                    ...prevItem,
                    productInfo: `${e.target.value} lights`,
                    price: 109,
                  }));
                }}
              />
              <label htmlFor="16-20lights">16-20 lights</label>
            </div>
            <div className={style.minorWorksRadioBtns}>
              <input
                type="radio"
                name="lightsRadio"
                value={"21"}
                id="21+lights"
                onChange={(e) => {
                  setShowInput(true);
                  setItem((prevItem) => ({
                    ...prevItem,
                    productInfo: `${e.target.value} lights`,
                    price: 119,
                  }));
                }}
              />
              <label htmlFor="21+lights">21+ lights</label>
            </div>
          </div>
          {showInput && (
            <>
              <input
                min="1"
                type="number"
                placeholder="No. of lights"
                id="lightsQuant"
                onChange={(e) => {
                  if (e.target.value === "") {
                    setInputLights(21);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `21 lights`,
                    }));
                  } else {
                    setInputLights(+e.target.value);
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${e.target.value} lights`,
                    }));
                  }
                }}
                onWheel={(e) => e.currentTarget.blur()}
              />
            </>
          )}
        </>
        <div className={style.list}>
              <p className={style.priceLabel}>Estimated price:</p>
              <p className={style.price}>£{item.price}</p>
            </div>
            <p style={{ textAlign: "left", fontSize: "0.9em" }}>
              <em>
                Your final price will be calculated based on your location
                before you go to the booking summary.
              </em>
            </p>
      </ServiceTemplateComponent>
    </>
  );
}
