import { useNavigate } from "react-router-dom";
import Button from "./button";
import style from "../styles/who-are-we.module.scss";
import whoAreWe from "../assets/who-are-we.webp";
import hereForYou from "../assets/here-for-you.webp";
import { BottomSpacer } from "./spacer";

export default function AboutUs() {
  let navigate = useNavigate();
  return (
    <div className={style.container}>
      <h1>Who are we?</h1>
      <div className={style.flexContainer}>
        <img alt="" src={whoAreWe} />
        <div>
          <p style={{ marginBottom: "10px" }}>
            At Teck Engineers, we specialise in a range of electrical
            services, including EICR and PAT certificates, emergency callouts,
            and general electrical work. We are committed to providing timely,
            reliable service and ensuring the satisfaction of our customers.
          </p>

          <p>
            Our team of qualified electricians is dedicated to providing
            high-quality service and ensuring the safety and reliability of your
            electrical systems.
          </p>
        </div>
      </div>

      <div className={style.divider} />
      <h1>Here for you and your business</h1>
      <div className={style.flexContainer}>
        <img alt="" src={hereForYou} />
        <div>
          <p style={{ marginBottom: "10px" }}>
            In the event of an electrical emergency, our team is available 24/7
            to provide fast, reliable assistance. Whether you're dealing with a
            power outage, faulty wiring, or an appliance malfunction, we're here
            to help.
          </p>
          <p>
            Our electricians are equipped with the necessary tools and equipment
            to carry out the necessary repairs or replacements, and we will work
            to resolve the issue as quickly as possible.
          </p>
        </div>
      </div>
      <Button  onClick={() => {
                    let path = "/book-now";
                    navigate(path);
                  }}>Book with us now</Button>
      <BottomSpacer />
    </div>
  );
}
