import { useEffect, useState } from "react";
import axios from "axios";
import buttonStyle from "../styles/button-style.module.scss";
import LoadingSpinner from "./loading-spinner";

export default function DownloadPDF({
  bookingID,
  serverURL,
  fileName,
}: {
  bookingID: string;
  serverURL: string;
  fileName: string;
}) {
  const [pdfURL, setPdfURL] = useState("");
  const [downloadError, setDownloadError] = useState("");
  const [loadingDownload, setLoadingDownload] = useState(false);

  useEffect(() => {
    setLoadingDownload(true);
    axios
      .get(`${serverURL}/download-certificate/${bookingID}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        setPdfURL(url);
        setLoadingDownload(false);
      })
      .catch((err) => {
        setLoadingDownload(false);
        setDownloadError("Error loading file");
        console.log(err);
      });
  }, [bookingID, serverURL, setLoadingDownload, setDownloadError]);

  return (
    <div>
      {loadingDownload ? (
        <LoadingSpinner />
      ) : (
        <>
          {pdfURL ? (
            <a
              href={pdfURL}
              download={fileName}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <button className={buttonStyle.button}>See Report</button>
            </a>
          ) : (
            <>
              <p className="error">{downloadError}</p>
            </>
          )}
        </>
      )}
    </div>
  );
}
