import { useEffect, useState } from "react";
import { formatEmailDate } from "../../utils/funcs/google-calendar";
import minorWorks from "../../assets/minor-works.jpg";
import ServiceTemplateComponent from "./template";
import style from "../../styles/service-prices.module.scss";

export default function MinorElectricalWorks() {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  if (tomorrow.getDay() === 0) {
    // Sunday
    tomorrow.setDate(tomorrow.getDate() + 1); // Move to Monday
  } 
  
  const [serviceRequired, setServiceRequired] = useState("Fault Finding");
  const [item, setItem] = useState({
    bookingID: " ",
    productName: "Minor Electrical Works",
    price: 120,
    productInfo: `Minor Electrical Works: ${serviceRequired}`,
    customerName: "",
    customerEmail: "",
    customerNumber: "",
    start: "",
    end: "",
    date: tomorrow,
    emailDate: formatEmailDate(tomorrow),
    emailStartTime: "",
    access: "Access provided by: Customer on booking",
    address: { line1: "", line2: " ", postTown: "", postcode: "" },
    finalPrice: 0
  });

  // Switches:
  const newSwitchesBasePrice = 110;
  const existingSwitchesBasePrice = 90;
  const [switchesQuantity, setSwitchesQuantity] = useState(1);
  const [existingSwitchesQuantity, setExistingSwitchesQuantity] = useState(1);
  const [showSwitchInput, setShowSwitchInput] = useState(false);
  const [showExistingSwitchInput, setShowExistingSwitchInput] = useState(false);
  const [switches, setSwitches] = useState("new");

  useEffect(() => {
    if (serviceRequired === "Switches and lights" && switches === "new") {
      setItem((prevItems) => ({
        ...prevItems,
        productInfo: `New switches/lights - quantity: ${switchesQuantity}`,
        price: switchesQuantity * newSwitchesBasePrice,
      }));
    }
  }, [switchesQuantity, serviceRequired, switches]);

  useEffect(() => {
    if (serviceRequired === "Switches and lights" && switches === "existing") {
      setItem((prevItems) => ({
        ...prevItems,
        productInfo: `Replace switches/lights - quantity: ${existingSwitchesQuantity}`,
        price: existingSwitchesQuantity * existingSwitchesBasePrice,
      }));
    }
  }, [existingSwitchesQuantity, serviceRequired, switches]);

  // Sockets:
  const newSocketsBasePrice = 120;
  const existingSocketsBasePrice = 65;
  const [socketsQuantity, setSocketsQuantity] = useState(1);
  const [existingSocketsQuantity, setExistingSocketsQuantity] = useState(1);
  const [showSocketInput, setShowSocketInput] = useState(false);
  const [showExistingSocketInput, setShowExistingSocketInput] = useState(false);
  const [sockets, setSockets] = useState("new");

  useEffect(() => {
    if (serviceRequired === "Sockets and plugs" && sockets === "new") {
      setItem((prevItems) => ({
        ...prevItems,
        productInfo: `New sockets/plugs - quantity: ${socketsQuantity}`,
        price: socketsQuantity * newSocketsBasePrice,
      }));
    }
  }, [socketsQuantity, serviceRequired, sockets]);

  useEffect(() => {
    if (serviceRequired === "Sockets and plugs" && sockets === "existing") {
      setItem((prevItems) => ({
        ...prevItems,
        productInfo: `Replace sockets/plugs - quantity: ${existingSocketsQuantity}`,
        price: existingSocketsQuantity * existingSocketsBasePrice,
      }));
    }
  }, [existingSocketsQuantity, serviceRequired, sockets]);

  return (
    <>
      <ServiceTemplateComponent
        item={item}
        setItem={setItem}
        backgroundImage={minorWorks}
        tomorrow={tomorrow}
        serviceDescription="This service includes 1hr of work in which the electrician will aim to resolve your minor electrical issue. This price does not include any parts. If you are looking for an installation service, you can find this under Electrical Remedial Works/Installations."
      >
        <div>
          <label htmlFor="minorWorks">
            What type of minor electrical work do you require?
          </label>

          <div className={style.allMinorWorksRadios}>
            <div className={style.minorWorksRadioBtns}>
              <input
                defaultChecked
                type="radio"
                name="minorWorksRadio"
                value={"Fault Finding"}
                id="faultFinding"
                onChange={(e) => {
                  setServiceRequired(e.target.value);
                  setItem((prevItem) => ({
                    ...prevItem,
                    productInfo: `${e.target.value} - 1hr`,
                    price: 120,
                  }));
                }}
              />
              <label htmlFor="faultFinding">Fault Finding</label>
            </div>
            <div className={style.minorWorksRadioBtns}>
              <input
                type="radio"
                name="minorWorksRadio"
                value={"Switches and lights"}
                id="switchesAndLights"
                onChange={(e) => {
                  setSwitchesQuantity(1);
                  setShowExistingSwitchInput(false);
                  setShowSwitchInput(false);
                  setServiceRequired(e.target.value);
                  setItem((prevItem) => ({
                    ...prevItem,
                    productInfo: `New ${e.target.value} - quantity: 1`,
                    price: newSwitchesBasePrice,
                  }));
                }}
              />
              <label htmlFor="switchesAndLights">Switches and lights</label>
            </div>
            <div className={style.minorWorksRadioBtns}>
              <input
                type="radio"
                name="minorWorksRadio"
                value={"Sockets and plugs"}
                id="socketsAndPlugs"
                onChange={(e) => {
                  setSocketsQuantity(1);
                  setShowExistingSocketInput(false);
                  setShowSocketInput(false);
                  setServiceRequired(e.target.value);
                  setItem((prevItem) => ({
                    ...prevItem,
                    productInfo: `New ${e.target.value} - quantity: 1`,
                    price: newSocketsBasePrice,
                  }));
                }}
              />
              <label htmlFor="socketsAndPlugs">Sockets and plugs</label>
            </div>
            <div className={style.minorWorksRadioBtns}>
              <input
                type="radio"
                name="minorWorksRadio"
                value={"Fusebox tripping"}
                id="fuseboxTripping"
                onChange={(e) => {
                  setServiceRequired(e.target.value);
                  setItem((prevItem) => ({
                    ...prevItem,
                    productInfo: `${e.target.value} - 1hr`,
                    price: 120,
                  }));
                }}
              />
              <label htmlFor="fuseboxTripping">Fusebox tripping</label>
            </div>
            <div className={style.minorWorksRadioBtns}>
              <input
                type="radio"
                name="minorWorksRadio"
                value={"Other"}
                id="other"
                onChange={(e) => {
                  setServiceRequired(e.target.value);
                  setItem((prevItem) => ({
                    ...prevItem,
                    productInfo: `${e.target.value} - 1hr`,
                    price: 120,
                  }));
                }}
              />
              <label htmlFor="other">Other</label>
            </div>
          </div>

          {/* 
          FAULT
          FINDING
          SELECTED
          */}

          {serviceRequired === "Fault Finding" && (
            <>
              <label style={{ textAlign: "left" }}>
                How many hours do you require?
              </label>
              <p style={{ textAlign: "left", fontSize: "12px" }}>
                <em>
                  Recommended time: 1 hour. <br /> If further time is required,
                  your electrician will advise you of the cost of this during
                  your appointment and book you in for further time.
                </em>
              </p>
              <div className={style.allRadios}>
                <div className={style.radioBtns}>
                  <input
                    defaultChecked
                    type="radio"
                    name="faultFindingRadio"
                    value={1}
                    id="1hr"
                    onChange={() => {
                      setItem((prevItem) => ({
                        ...prevItem,
                        productInfo: `${serviceRequired} - 1hr`,
                        price: 120,
                      }));
                    }}
                  />
                  <label htmlFor="1hr">1</label>
                </div>
                <div className={style.radioBtns}>
                  <input
                    type="radio"
                    name="faultFindingRadio"
                    value={2}
                    id="2hr"
                    onChange={() => {
                      setItem((prevItem) => ({
                        ...prevItem,
                        productInfo: `${serviceRequired} - 2hr`,
                        price: 240,
                      }));
                    }}
                  />
                  <label htmlFor="2hr">2</label>
                </div>
                <div className={style.radioBtns}>
                  <input
                    type="radio"
                    name="faultFindingRadio"
                    value={3}
                    id="3hr"
                    onChange={() => {
                      setItem((prevItem) => ({
                        ...prevItem,
                        productInfo: `${serviceRequired} - 3hr`,
                        price: 360,
                      }));
                    }}
                  />
                  <label htmlFor="3hr">3</label>
                </div>
              </div>
            </>
          )}

          {/* 
            SWITCHES
            AND
            LIGHTS
            SELECTED
          */}

          {serviceRequired === "Switches and lights" && (
            <>
              <div className={style.allMinorWorksRadios}>
                <p
                  style={{
                    textAlign: "left",
                    marginTop: "18px",
                    fontSize: "20px",
                  }}
                >
                  Switches and Lights
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "12px",
                    marginTop: "-15px",
                    marginBottom: "5px",
                  }}
                >
                  <em>
                    Please note: this service does not include the price of
                    parts
                  </em>
                </p>
                <div className={style.minorWorksRadioBtns}>
                  <input
                    defaultChecked
                    type="radio"
                    name="switchesRadio"
                    value={"Install new switches and lights"}
                    id="newSwitches"
                    onChange={(e) => {
                      setSwitchesQuantity(1);
                      setShowSwitchInput(false);
                      setSwitches("new");
                      setItem((prevItem) => ({
                        ...prevItem,
                        productInfo: `${e.target.value} - quantity: 1`,
                        price: newSwitchesBasePrice,
                      }));
                    }}
                  />
                  <label htmlFor="newSwitches">
                    Install new switches and lights
                  </label>
                </div>
                <div className={style.minorWorksRadioBtns}>
                  <input
                    type="radio"
                    name="switchesRadio"
                    value={"Replace existing switches and lights"}
                    id="existingSwitches"
                    onChange={(e) => {
                      setShowExistingSwitchInput(false);
                      setExistingSwitchesQuantity(1);
                      setSwitches("existing");
                      setItem((prevItem) => ({
                        ...prevItem,
                        productInfo: `${e.target.value} - quantity: 1`,
                        price: existingSwitchesBasePrice,
                      }));
                    }}
                  />
                  <label htmlFor="existingSwitches">
                    Replace existing switches and lights
                  </label>
                </div>
              </div>
              {switches === "new" && (
                <>
                  <label style={{ marginTop: "10px" }}>
                    How many new switches/lights do you require installation
                    for?
                  </label>
                  <div className={style.allRadios}>
                    <div className={style.radioBtns}>
                      <input
                        defaultChecked
                        type="radio"
                        name="switchesQuantity"
                        value={1}
                        id="1newswitch"
                        onChange={(e) => {
                          setShowSwitchInput(false);
                          setSwitchesQuantity(Number(e.target.value));
                          setItem((prev) => ({
                            ...prev,
                            price: newSwitchesBasePrice,
                          }));
                        }}
                      />
                      <label htmlFor="1newswitch">1</label>
                    </div>
                    <div className={style.radioBtns}>
                      <input
                        type="radio"
                        name="switchesQuantity"
                        value={2}
                        id="2newswitch"
                        onChange={(e) => {
                          setShowSwitchInput(false);
                          setSwitchesQuantity(Number(e.target.value));
                        }}
                      />
                      <label htmlFor="2newswitch">2</label>
                    </div>
                    <div className={style.radioBtns}>
                      <input
                        type="radio"
                        name="switchesQuantity"
                        value={3}
                        id="3newswitch"
                        onChange={(e) => {
                          setShowSwitchInput(false);
                          setSwitchesQuantity(Number(e.target.value));
                        }}
                      />
                      <label htmlFor="3newswitch">3</label>
                    </div>
                    <div className={style.radioBtns}>
                      <input
                        type="radio"
                        name="switchesQuantity"
                        value={4}
                        id="4newswitch"
                        onChange={(e) => {
                          setShowSwitchInput(false);
                          setSwitchesQuantity(Number(e.target.value));
                        }}
                      />
                      <label htmlFor="4newswitch">4</label>
                    </div>
                    <div className={style.radioBtns}>
                      <input
                        type="radio"
                        name="switchesQuantity"
                        value={5}
                        id="5newswitch"
                        onChange={(e) => {
                          setShowSwitchInput(true);
                          setSwitchesQuantity(Number(e.target.value));
                        }}
                      />
                      <label htmlFor="5newswitch">5+</label>
                    </div>
                  </div>

                  {showSwitchInput && (
                    <input
                      min="1"
                      type="number"
                      placeholder="No. of Switches"
                      id="newSwitchesQuantity"
                      onChange={(e) => {
                        if (e.target.value === "") {
                          setSwitchesQuantity(5);
                        } else {
                          setSwitchesQuantity(Number(e.target.value));
                        }
                      }}
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                  )}
                </>
              )}

              {switches === "existing" && (
                <>
                  <label>
                    How many existing switches/lights do you require replacement
                    for?
                  </label>
                  <div className={style.allRadios}>
                    <div className={style.radioBtns}>
                      <input
                        defaultChecked
                        type="radio"
                        name="existingSwitchesQuantity"
                        value={1}
                        id="1existingSwitch"
                        onChange={(e) => {
                          setExistingSwitchesQuantity(Number(e.target.value));
                          setShowExistingSwitchInput(false);
                        }}
                      />
                      <label htmlFor="1existingSwitch">1</label>
                    </div>
                    <div className={style.radioBtns}>
                      <input
                        type="radio"
                        name="existingSwitchesQuantity"
                        value={2}
                        id="2existingSwitch"
                        onChange={(e) => {
                          setExistingSwitchesQuantity(Number(e.target.value));
                          setShowExistingSwitchInput(false);
                        }}
                      />
                      <label htmlFor="2existingSwitch">2</label>
                    </div>
                    <div className={style.radioBtns}>
                      <input
                        type="radio"
                        name="existingSwitchesQuantity"
                        value={3}
                        id="3existingSwitch"
                        onChange={(e) => {
                          setExistingSwitchesQuantity(Number(e.target.value));
                          setShowExistingSwitchInput(false);
                        }}
                      />
                      <label htmlFor="3existingSwitch">3</label>
                    </div>
                    <div className={style.radioBtns}>
                      <input
                        type="radio"
                        name="existingSwitchesQuantity"
                        value={4}
                        id="4existingSwitch"
                        onChange={(e) => {
                          setExistingSwitchesQuantity(Number(e.target.value));
                          setShowExistingSwitchInput(false);
                        }}
                      />
                      <label htmlFor="4existingSwitch">4</label>
                    </div>
                    <div className={style.radioBtns}>
                      <input
                        type="radio"
                        name="existingSwitchesQuantity"
                        value={5}
                        id="5existingSwitch"
                        onChange={(e) => {
                          setExistingSwitchesQuantity(Number(e.target.value));
                          setShowExistingSwitchInput(true);
                        }}
                      />
                      <label htmlFor="5existingSwitch">5+</label>
                    </div>
                  </div>

                  {showExistingSwitchInput && (
                    <input
                      min="1"
                      type="number"
                      placeholder="No. of Switches"
                      id="existingSwitchesQuantity"
                      onChange={(e) => {
                        if (e.target.value === "") {
                          setExistingSwitchesQuantity(5);
                        } else {
                          setExistingSwitchesQuantity(Number(e.target.value));
                        }
                      }}
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                  )}
                </>
              )}
            </>
          )}

          {/* 
            SOCKETS
            AND
            PLUGS
            SELECTED
          */}
          {serviceRequired === "Sockets and plugs" && (
            <>
              <div className={style.allMinorWorksRadios}>
                <p
                  style={{
                    textAlign: "left",
                    marginTop: "20px",
                    fontSize: "20px",
                  }}
                >
                  Sockets and Plugs
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "12px",
                    marginTop: "-15px",
                    marginBottom: "5px",
                  }}
                >
                  <em>
                    Please note: this service does not include the price of
                    parts
                  </em>
                </p>
                <div className={style.minorWorksRadioBtns}>
                  <input
                    defaultChecked
                    type="radio"
                    name="socketsRadio"
                    value={"Install new sockets and plugs"}
                    id="newSockets"
                    onChange={(e) => {
                      setSocketsQuantity(1);
                      setSockets("new");
                      setShowSocketInput(false);
                      setItem((prevItem) => ({
                        ...prevItem,
                        productInfo: `${e.target.value} - quantity: 1`,
                        price: newSocketsBasePrice,
                      }));
                    }}
                  />
                  <label htmlFor="newSockets">
                    Install new sockets and plugs
                  </label>
                </div>
                <div className={style.minorWorksRadioBtns}>
                  <input
                    type="radio"
                    name="socketsRadio"
                    value={"Replace existing sockets and plugs"}
                    id="existingSockets"
                    onChange={(e) => {
                      setExistingSocketsQuantity(1);
                      setShowExistingSocketInput(false);
                      setSockets("existing");
                      setItem((prevItem) => ({
                        ...prevItem,
                        productInfo: `New ${e.target.value} - quantity: 1`,
                        price: existingSocketsBasePrice,
                      }));
                    }}
                  />
                  <label htmlFor="existingSockets">
                    Replace existing sockets and lights
                  </label>
                </div>
              </div>

              {sockets === "new" && (
                <>
                  <label style={{ marginTop: "10px" }}>
                    How many new sockets/plugs do you require installation for?
                  </label>
                  <div className={style.allRadios}>
                    <div className={style.radioBtns}>
                      <input
                        defaultChecked
                        type="radio"
                        name="socketsQuantity"
                        value={1}
                        id="1newsocket"
                        onChange={(e) => {
                          setShowSocketInput(false);
                          setSocketsQuantity(Number(e.target.value));
                        }}
                      />
                      <label htmlFor="1newsocket">1</label>
                    </div>
                    <div className={style.radioBtns}>
                      <input
                        type="radio"
                        name="socketsQuantity"
                        value={2}
                        id="2newsocket"
                        onChange={(e) => {
                          setShowSocketInput(false);
                          setSocketsQuantity(Number(e.target.value));
                        }}
                      />
                      <label htmlFor="2newsocket">2</label>
                    </div>
                    <div className={style.radioBtns}>
                      <input
                        type="radio"
                        name="socketsQuantity"
                        value={3}
                        id="3newsocket"
                        onChange={(e) => {
                          setShowSocketInput(false);
                          setSocketsQuantity(Number(e.target.value));
                        }}
                      />
                      <label htmlFor="3newsocket">3</label>
                    </div>
                    <div className={style.radioBtns}>
                      <input
                        type="radio"
                        name="socketsQuantity"
                        value={4}
                        id="4newsocket"
                        onChange={(e) => {
                          setShowSocketInput(false);
                          setSocketsQuantity(Number(e.target.value));
                        }}
                      />
                      <label htmlFor="4newsocket">4</label>
                    </div>
                    <div className={style.radioBtns}>
                      <input
                        type="radio"
                        name="socketsQuantity"
                        value={5}
                        id="5newsocket"
                        onChange={(e) => {
                          setShowSocketInput(true);
                          setSocketsQuantity(Number(e.target.value));
                        }}
                      />
                      <label htmlFor="5newsocket">5+</label>
                    </div>
                  </div>

                  {showSocketInput && (
                    <input
                      min="1"
                      type="number"
                      placeholder="No. of Sockets"
                      id="newSocketsQuantity"
                      onChange={(e) => {
                        if (e.target.value === "") {
                          setSocketsQuantity(5);
                          setItem((prevItem) => ({
                            ...prevItem,
                            productInfo: `No. of New Sockets: 1`,
                          }));
                        } else {
                          setItem((prevItem) => ({
                            ...prevItem,
                            productInfo: `No. of New Sockets: ${e.target.value}`,
                          }));
                          setSocketsQuantity(Number(e.target.value));
                        }
                      }}
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                  )}
                </>
              )}

              {sockets === "existing" && (
                <>
                  <label>
                    How many existing sockets/plugs do you require replacement
                    for?
                  </label>
                  <div className={style.allRadios}>
                    <div className={style.radioBtns}>
                      <input
                        defaultChecked
                        type="radio"
                        name="existingSocketsQuantity"
                        value={1}
                        id="1existingSocket"
                        onChange={(e) => {
                          setExistingSocketsQuantity(Number(e.target.value));
                          setShowExistingSocketInput(false);
                        }}
                      />
                      <label htmlFor="1existingSocket">1</label>
                    </div>
                    <div className={style.radioBtns}>
                      <input
                        type="radio"
                        name="existingSocketsQuantity"
                        value={2}
                        id="2existingSocket"
                        onChange={(e) => {
                          setExistingSocketsQuantity(Number(e.target.value));
                          setShowExistingSocketInput(false);
                        }}
                      />
                      <label htmlFor="2existingSocket">2</label>
                    </div>
                    <div className={style.radioBtns}>
                      <input
                        type="radio"
                        name="existingSocketsQuantity"
                        value={3}
                        id="3existingSocket"
                        onChange={(e) => {
                          setExistingSocketsQuantity(Number(e.target.value));
                          setShowExistingSocketInput(false);
                        }}
                      />
                      <label htmlFor="3existingSocket">3</label>
                    </div>
                    <div className={style.radioBtns}>
                      <input
                        type="radio"
                        name="existingSocketsQuantity"
                        value={4}
                        id="4existingSocket"
                        onChange={(e) => {
                          setExistingSocketsQuantity(Number(e.target.value));
                          setShowExistingSocketInput(false);
                        }}
                      />
                      <label htmlFor="4existingSocket">4</label>
                    </div>
                    <div className={style.radioBtns}>
                      <input
                        type="radio"
                        name="existingSocketsQuantity"
                        value={5}
                        id="5existingSocket"
                        onChange={(e) => {
                          setExistingSocketsQuantity(Number(e.target.value));
                          setShowExistingSocketInput(true);
                        }}
                      />
                      <label htmlFor="5existingSocket">5+</label>
                    </div>
                  </div>
                  {showExistingSocketInput && (
                    <input
                      min="1"
                      type="number"
                      placeholder="No. of Sockets"
                      id="existingSocketsQuantity"
                      onChange={(e) => {
                        if (e.target.value === "") {
                          setExistingSocketsQuantity(5);
                        } else {
                          setExistingSocketsQuantity(Number(e.target.value));
                        }
                      }}
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                  )}
                </>
              )}
            </>
          )}

          {/* 
            FUSEBOX
            TRIPPING
            SELECTED
          */}
          {serviceRequired === "Fusebox tripping" && (
            <>
              <label style={{ textAlign: "left" }}>
                How many hours do you require?
              </label>
              <p style={{ textAlign: "left", fontSize: "12px" }}>
                <em>
                  Recommended time: 1 hour. <br /> If further time is required,
                  your electrician will advise you of the cost of this during
                  your appointment and book you in for further time.
                </em>
              </p>
              <div className={style.allRadios}>
                <div className={style.radioBtns}>
                  <input
                    defaultChecked
                    type="radio"
                    name="fuseboxTrippingRadio"
                    value={1}
                    id="1hr"
                    onChange={() => {
                      setItem((prevItem) => ({
                        ...prevItem,
                        productInfo: `${serviceRequired} - 1hr`,
                        price: 120,
                      }));
                    }}
                  />
                  <label htmlFor="1hr">1</label>
                </div>
                <div className={style.radioBtns}>
                  <input
                    type="radio"
                    name="fuseboxTrippingRadio"
                    value={2}
                    id="2hr"
                    onChange={() => {
                      setItem((prevItem) => ({
                        ...prevItem,
                        productInfo: `${serviceRequired} - 2hr`,
                        price: 240,
                      }));
                    }}
                  />
                  <label htmlFor="2hr">2</label>
                </div>
                <div className={style.radioBtns}>
                  <input
                    type="radio"
                    name="fuseboxTrippingRadio"
                    value={3}
                    id="3hr"
                    onChange={() => {
                      setItem((prevItem) => ({
                        ...prevItem,
                        productInfo: `${serviceRequired} - 3hr`,
                        price: 360,
                      }));
                    }}
                  />
                  <label htmlFor="3hr">3</label>
                </div>
              </div>
            </>
          )}

          {/* 
            OTHER 
            SELECTED
          */}

          {serviceRequired === "Other" && (
            <>
              <input
                type="text"
                placeholder="Enter name of service required"
                id="otherInput"
                onChange={(e) => {
                  if (e.target.value === "") {
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `Other`,
                    }));
                  } else {
                    setItem((prevItem) => ({
                      ...prevItem,
                      productInfo: `${e.target.value}`,
                    }));
                  }
                }}
                onWheel={(e) => e.currentTarget.blur()}
              />
              <label style={{ textAlign: "left" }}>
                How many hours do you require?
              </label>
              <p style={{ textAlign: "left", fontSize: "12px" }}>
                <em>
                  Recommended time: 1 hour. <br /> If further time is required,
                  your electrician will advise you of the cost of this during
                  your appointment and book you in for further time.
                </em>
              </p>
              <div className={style.allRadios}>
                <div className={style.radioBtns}>
                  <input
                    defaultChecked
                    type="radio"
                    name="otherRadio"
                    value={1}
                    id="1hr"
                    onChange={() => {
                      setItem((prevItem) => ({
                        ...prevItem,
                        productInfo: `${serviceRequired} - 1hr`,
                        price: 120,
                      }));
                    }}
                  />
                  <label htmlFor="1hr">1</label>
                </div>
                <div className={style.radioBtns}>
                  <input
                    type="radio"
                    name="otherRadio"
                    value={2}
                    id="2hr"
                    onChange={() => {
                      setItem((prevItem) => ({
                        ...prevItem,
                        productInfo: `${serviceRequired} - 2hr`,
                        price: 240,
                      }));
                    }}
                  />
                  <label htmlFor="2hr">2</label>
                </div>
                <div className={style.radioBtns}>
                  <input
                    type="radio"
                    name="otherRadio"
                    value={3}
                    id="3hr"
                    onChange={() => {
                      setItem((prevItem) => ({
                        ...prevItem,
                        productInfo: `${serviceRequired} - 3hr`,
                        price: 360,
                      }));
                    }}
                  />
                  <label htmlFor="3hr">3</label>
                </div>
              </div>
            </>
          )}

          <div className={style.list}>
            <p className={style.priceLabel}>Estimated price:</p>
            <p className={style.price}>£{item.price}</p>
          </div>
          <p style={{ textAlign: "left", fontSize: "0.9em" }}>
            <em>
              Your final price will be calculated based on your location before
              you go to the booking summary.
            </em>
          </p>
        </div>
      </ServiceTemplateComponent>
    </>
  );
}
