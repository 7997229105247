import { useEffect, useState } from "react";
import { ServicesExamples, services } from "../utils/objects/services-object";
import { Link } from "react-router-dom";
import style from "../styles/search-bar.module.scss";

export default function SearchBar({
  booking,
}: {
  booking: boolean;
}): React.ReactElement {
  const [search, setSearch] = useState<{
    query: string;
    list: ServicesExamples[];
  }>({
    query: "",
    list: [],
  });

  const allServices = [...services];
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const listenToScroll = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;

      if (winScroll) {
        isVisible && setIsVisible(false);
      }
    };
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, [isVisible]);

  const handleSearch = (e: any) => {
    const results = allServices.filter((service) => {
      if (e === "") return allServices;
      return service.searchTerms?.some((term) => {
        return term.includes(e.toLowerCase());
      });
    });

    setSearch({
      query: e,
      list: results as ServicesExamples[],
    });
    setIsVisible(true);
  };

  const handleClick = () => {
    setSearch({
      query: "",
      list: allServices as ServicesExamples[],
    });
    setIsVisible(true);
  };
  return (
    <div
      className={booking ? style.bookingSearchContainer : style.searchContainer}
    >
      <input
        type="text"
        onChange={(e) => {
          handleSearch(e.target.value);
        }}
        onClick={handleClick}
        className={style.searchInput}
        placeholder="Search for a service"
        value={search.query}
        onBlur={(e) => {
          if (!e.relatedTarget || e.relatedTarget.tagName !== "A") {
            setSearch((prev) => ({ ...prev, list: [] }));
          }
        }}
     
      />

      {search.list.length !== 0 &&(
        <div className={style.searchResults}>
          {search.list.map(({ slug, title }, index) => {
            return (
              <div key={index} className={style.searchResult}>
                <Link to={`/book-now/${slug}`}>{title}</Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
