import { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";
import style from "../styles/calendar.module.scss";
import {
  convertTo24HourFormat,
  createTimeSlots,
  formatEmailDate,
  formatEmailStartTime,
  getStartAndEndTimes,
  isWeekday,
} from "../utils/funcs/google-calendar";
import { ErrorMsgBelow } from "./required-error-messages";

const Calendar = ({
  item,
  setItem,
  refs,
  errorMsgs,
  setErrorMsgs,
  selectedDate,
  emergency,
  edit,
}: any) => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  if (tomorrow.getDay() === 0) {
    // Sunday
    tomorrow.setDate(tomorrow.getDate() + 1); // Move to Monday
  }

  const initialDisplayDate = useMemo(() => {
    return emergency ? new Date() : selectedDate;
  }, [emergency, selectedDate]);

  const initialDisplayTime = useMemo(() => {
    return edit ? convertTo24HourFormat(item.emailStartTime) : "9:00";
  }, [edit, item.emailStartTime]);

  const [date, setDate] = useState<Date | null>(initialDisplayDate);
  const [, setShowTimeSlots] = useState(false);
  const [selectedTime, setSelectedTime] = useState(initialDisplayTime);

  useEffect(() => {
    if (selectedTime && date) {
      const { start, end } = getStartAndEndTimes(selectedTime, date);
      setItem((prevItems: any) => ({
        ...prevItems,
        emailStartTime: formatEmailStartTime(start),
        start: start,
        end: end,
      }));
    }
  }, [date, selectedTime, setItem]);

  const updateDate = (clickedDate: any) => {
    if (clickedDate) {
      setItem((prevItems: any) => ({
        ...prevItems,
        emailDate: formatEmailDate(clickedDate),
        date: clickedDate,
      }));
    }
  };

  const updateTime = (time: any) => {
    const { start, end } = getStartAndEndTimes(time, date);
    setItem((prevItems: any) => ({
      ...prevItems,
      emailStartTime: formatEmailStartTime(start),
      start: start,
      end: end,
    }));
  };

  const handleDateChange = (e: Date | null) => {
    setDate(e);
    updateDate(e);
  };
  const handleDateSelect = () => {
    setShowTimeSlots(false);
  };

  const timeSlots = createTimeSlots().map((timeSlot: string) => {
    return {
      timeSlot: timeSlot,
    };
  });

  return (
    <>
      {!edit ? (
        <>
          <h3 className={style.heading}>Availability</h3>
          <div className={style.divider} />
          <label>
            When would you like to book this {item.productName} for?
          </label>
        </>
      ) : (
        <>
          <h3 className={style.heading}>Appt. Time</h3>
          <div className={style.divider} />
        </>
      )}
      <div>
        <div className={style.dateTimeContainer}>
          <div className={style.dateInput}>
            {edit && <label>Date</label>}
            <DatePicker
              allowSameDay={emergency}
              selected={date}
              placeholderText="Choose a date*"
              onSelect={handleDateSelect}
              onChange={(e) => handleDateChange(e)}
              dateFormat="eeee do MMMM yyyy"
              closeOnScroll={false}
              showPopperArrow={false}
              filterDate={isWeekday}
              disabledKeyboardNavigation
              minDate={emergency ? new Date() : tomorrow}
              maxDate={addDays(new Date(), 120)}
              onFocus={(e) => (e.target.readOnly = true)}
              required
            />
          </div>
          <div>
            {edit && <label>Time</label>}
            <div className={style.timeslotContainer}>
              <select
                id="timeslots"
                name="time"
                value={selectedTime}
                onChange={(e) => {
                  setSelectedTime(e.target.value);
                  updateTime(e.target.value);
                  setErrorMsgs((prev: any) => ({ ...prev, timeError: "" }));
                }}
                ref={refs.time}
              >
                <option disabled hidden value="">
                  Choose a time*
                </option>
                {timeSlots.map((slot: any, index: number) => (
                  <option
                    key={index}
                    value={slot.timeSlot}
                    disabled={slot.disabled}
                  >
                    {slot.timeSlot}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {errorMsgs.timeError && <ErrorMsgBelow msg={errorMsgs.timeError} />}
      </div>
    </>
  );
};

export default Calendar;

// date changed in the display but didn't change the date in the start time
// display time says 12.30 when 13.30 was clicked and 13.30 is shown on the start time
