import { useEffect, useState } from "react";
import { BottomSpacer, TopSpacer } from "../../spacer";
import { RedirectToSignIn, SignedIn, SignedOut } from "@clerk/clerk-react";
import { BookingWithCert } from "../../../types/BookingWithCert";
import style from "../../../styles/dashboard-customer.module.scss";
import certStyle from "../../../styles/certificates.module.scss";
import { Link } from "react-router-dom";
import lessSign from "../../../assets/lessSign.svg";
import SignOutButton from "../../sign-out";
import Card from "../../cards";
import { getCustomerBookingsWithCerts } from "../../../utils/funcs/api/get-customer-bookings-with-certs";
import DownloadPDF from "../../download-pdf";
import FailQuoteBreakdown from "../../fail-quote-breakdown";
import PassQuoteBreakdown from "../../pass-quote-breakdown";

export default function Certificates({
  user,
  serverURL,
}: any): React.ReactElement {
  const [loading, setLoading] = useState(true);
  const [currentSelected, setCurrentSelected] = useState("");
  const [pastBookings, setPastBookings] = useState<BookingWithCert[]>([]);
  const [, setFutureBookings] = useState<BookingWithCert[]>([]);

  useEffect(() => {
    getCustomerBookingsWithCerts({
      user,
      setPastBookings,
      setFutureBookings,
      setLoading,
      serverURL,
    }).then(() => {
      setPastBookings((prev) => prev.filter((booking) => booking.certificate));
    });
  }, [user, serverURL]);

  return (
    <div className={style.dashboard}>
      <TopSpacer />

      <>
        <SignedIn>
          <div className={style.linkGroups}>
            <img src={lessSign} alt="" />
            <Link to="/dashboard">Back to dashboard</Link>
          </div>
          <br />
          <h2>Available Reports</h2>
          <br />
          {loading ? (
            <>
              <div className="loadingBtn">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </>
          ) : pastBookings.length ? (
            pastBookings?.map((bookingWithCert, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    if (
                      currentSelected === bookingWithCert.certificate?.bookingID
                    ) {
                      setCurrentSelected("");
                    } else
                      setCurrentSelected(
                        bookingWithCert.certificate
                          ? bookingWithCert.certificate?.bookingID
                          : ""
                      );
                  }}
                >
                  <Card
                    date={bookingWithCert.booking.date}
                    title={bookingWithCert.booking.productName}
                    currentSelected={currentSelected}
                    bookingID={bookingWithCert.certificate?.bookingID}
                    columns={{
                      service: bookingWithCert.booking.productName,
                      status:
                        bookingWithCert.certificate?.status?.toUpperCase(),
                    }}
                  >
                    <div>
                      {bookingWithCert.certificate?.status === "fail" ? (
                        <FailQuoteBreakdown bookingWithCert={bookingWithCert} />
                      ) : (
                        <PassQuoteBreakdown bookingWithCert={bookingWithCert} />
                      )}

                      <div className={certStyle.button}>
                        <DownloadPDF
                          bookingID={bookingWithCert.booking.bookingID}
                          serverURL={serverURL}
                          fileName={
                            bookingWithCert.certificate
                              ? bookingWithCert.certificate?.certData.fileName
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </Card>
                </div>
              );
            })
          ) : (
            <p>You currently have no reports to view</p>
          )}

          <SignOutButton />
        </SignedIn>
      </>

      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>

      <BottomSpacer />
    </div>
  );
}
