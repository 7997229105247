import { useEffect, useState } from "react";
import style from "../../../styles/dashboard-admin.module.scss";
import { useNavigate } from "react-router-dom";
import { BottomSpacer, TopSpacer } from "../../spacer";

import SignOutButton from "../../sign-out";
import Card from "../../cards";
import SideBar from "../../sidebar";
import { Booking } from "../../../types/BookingWithCert";
import { getAllBookings } from "../../../utils/funcs/api/get-admin-bookings";
import ExtraInfo from "../../booking-extra-info";
import { createDisplayDate } from "../../../utils/funcs/create-displayDate";
import { getServerURL } from "../../../utils/funcs/get-serverURL";

export default function AdminBookings({ user, role }: any) {
  const [currentSelected, setCurrentSelected] = useState("");
  const [pastBookings, setPastBookings] = useState<Booking[]>([]);
  const [futureBookings, setFutureBookings] = useState<Booking[]>([]);
  const [loading, setLoading] = useState<Boolean>(true);
  const [search, setSearch] = useState<{
    query: string;
    listPastBookings: Booking[];
    listFutureBookings: Booking[];
  }>({
    query: "",
    listPastBookings: [],
    listFutureBookings: [],
  });

  const serverURL = getServerURL();

  let navigate = useNavigate();

  useEffect(() => {
    if (role !== "admin") {
      // navigate("/dashboard");
    }
  }, [role, navigate]);

  useEffect(() => {
    getAllBookings({
      user,
      serverURL,
      setPastBookings,
      setFutureBookings,
      setLoading,
      setSearch,
    });
  }, [user, serverURL]);

  const showSelectedBooking = (bookingID: string) => {
    if (currentSelected === bookingID) {
      setCurrentSelected("");
    } else {
      setCurrentSelected(bookingID);
    }
  };

  // search
  const handleSearch = (searchQuery: any) => {
    const pastResults = pastBookings.filter(
      ({ bookingID, customerEmail, customerName, productName }) => {
        if (searchQuery === "") return true;
        return (
          bookingID.includes(searchQuery) ||
          customerEmail.toLowerCase().includes(searchQuery.toLowerCase()) ||
          customerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          productName.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
    );
    const futureResults = futureBookings.filter(
      ({ bookingID, customerEmail, customerName, productName }) => {
        if (searchQuery === "") return true;
        return (
          bookingID.includes(searchQuery) ||
          customerEmail.toLowerCase().includes(searchQuery.toLowerCase()) ||
          customerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          productName.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
    );

    setSearch((prev) => ({
      ...prev,
      listPastBookings: pastResults as Booking[],
      listFutureBookings: futureResults as Booking[],
    }));
  };

  const createColumns = (booking: Booking) => {
    const columns = {
      time: booking.emailStartTime,
      name: booking.customerName,
      address: booking.addressLine1
        ? `${booking.addressLine1}, ${booking.postcode}`
        : booking.address,
      service: booking.productName,
    };

    if (booking.cancelled) {
      columns.time = "CANCELLED";
    }

    return columns;
  };

  return (
    <>
      <TopSpacer />
      <div className={style.flexContainer}>
        <SideBar />
        <div className={style.column2}>
          <h1>All Bookings</h1>
          <br />
          <p style={{ textAlign: "left" }}>
            Search for a booking by ID, name, email, address, date or service
            name
          </p>
          <input
            type="text"
            onChange={(e) => {
              setSearch((prev) => ({ ...prev, query: e.target.value }));
              handleSearch(e.target.value);
            }}
            className={style.searchInput}
            placeholder="Start typing to search..."
            value={search.query}
            onBlur={() => {
              setSearch((prev) => ({ ...prev, list: [] }));
            }}
            onWheel={() => {
              setSearch((prev) => ({ ...prev, list: [] }));
            }}
          />
          <h4 style={{ textAlign: "left" }}>Your Upcoming Bookings:</h4>
          {!search.listFutureBookings.length && <br />}
          {loading ? (
            <>
              <div className="loadingBtn">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </>
          ) : (
            <>
              {search.listFutureBookings.length ? (
                <>
                  {search.listFutureBookings.map((booking, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          if (currentSelected === booking.bookingID) {
                            setCurrentSelected("");
                          } else setCurrentSelected(booking.bookingID);
                        }}
                      >
                        <Card
                          date={booking.date}
                          title={booking.productName}
                          currentSelected={currentSelected}
                          bookingID={booking.bookingID}
                          key={index}
                          columns={createColumns(booking)}
                        >
                          <ExtraInfo past={false} booking={booking} />
                        </Card>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p>No upcoming bookings</p>
              )}
            </>
          )}

          <br />
          <h4 style={{ textAlign: "left" }}>Your Past Bookings:</h4>
          {!search.listPastBookings.length && <br />}

          {loading ? (
            <>
              <div className="loadingBtn">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </>
          ) : (
            <>
              {search.listPastBookings.length ? (
                <>
                  {search.listPastBookings.map((booking, index) => {
                    return (
                      <div
                        key={booking.bookingID}
                        onClick={() => {
                          showSelectedBooking(booking.bookingID);
                        }}
                      >
                        <Card
                          date={booking.date}
                          title={booking.productName}
                          currentSelected={currentSelected}
                          bookingID={booking.bookingID}
                          key={index}
                          columns={createColumns(booking)}
                        >
                          <ExtraInfo past={true} booking={booking} />
                        </Card>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p>No past bookings</p>
              )}
            </>
          )}
        </div>
      </div>
      <BottomSpacer />
      <SignOutButton />
      <BottomSpacer />
    </>
  );
}
