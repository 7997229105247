import { Modal, Button } from "react-bootstrap";
import buttonStyle from "../../styles/button-style.module.scss";
import style from "../../styles/file-uploader.module.scss";
import FileInput from "../file-input";
import { useState } from "react";
import { toast } from "react-toastify";
import BookingSummaryCard from "../booking-summary-card";
import DownloadPDF from "../download-pdf";
import axios from "axios";
import { BookingWithCert } from "../../types/BookingWithCert";
import LoadingSpinner from "../loading-spinner";
import QuoteSummaryCard from "../quote-summary";
import convertShortDateToObj from "../../utils/funcs/convert-short-date-to-object";
import { sendPDFEmail } from "../../utils/funcs/api/send-PDF-email";
import { getServerURL } from "../../utils/funcs/get-serverURL";

export default function EditFile({
  cert,
  show,
  handleClose,
  setCertificates,
}: {
  cert: BookingWithCert;
  show: boolean;
  handleClose: any;
  setCertificates: any;
}): React.ReactElement {
  const [file, setFile] = useState<FileList | null>(null);
  const [fileError, setFileError] = useState("");
  const [loading, setLoading] = useState(false);

  // expiry
  const [typeDate, setTypeDate] = useState("");
  const [dateError, setDateError] = useState("");

  const serverURL = getServerURL();

  const notify = () => {
    toast.success("Your report has been updated successfully", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const updateCertName = () => {
    if (file) {
      setCertificates((certificates: BookingWithCert[]) => {
        return certificates.map((certificate: BookingWithCert) => {
          if (
            certificate.booking.bookingID === cert.booking.bookingID &&
            cert.certificate
          ) {
            return {
              ...certificate,
              certificate: {
                ...cert.certificate,
                certData: {
                  ...cert.certificate.certData,
                  fileName: file[0].name,
                },
              },
            };
          }
          return certificate;
        });
      });
    }
  };

  const editFile = () => {
    setLoading(true);
    setFileError("");
    setDateError("");

    const converted = convertShortDateToObj(typeDate);
    if (!typeDate) {
      setLoading(false);
      setDateError("Please enter an expiry date above");
      return;
    } else if (!converted) {
      setLoading(false);
      setDateError("Please enter a valid future date in the format dd/mm/yyyy");
      return;
    }
    const formData = new FormData();
    if (file) {
      const quote = { expiry: converted };
      formData.append("file", file[0]);
      formData.append("quote", JSON.stringify(quote));
      axios
        .patch(
          `${serverURL}/edit-certificate/${cert.booking.bookingID}`,
          formData
        )
        .then((res) => {
          updateCertName();
          setLoading(false);
          handleClose();
          notify();
          sendPDFEmail(serverURL, cert.booking, "edit");
          console.log(res);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          setFileError("Error uploading file. Please try again later.");
        });
    } else {
      setLoading(false);
      setFileError("Please select a file to upload");
      return;
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} className={style.uploadModal}>
        <Modal.Header closeButton className={style.uploadModalTitle} />
        <Modal.Body className={style.modalBody}>
          <h1>Edit File</h1>
          <BookingSummaryCard booking={cert.booking} />
          {cert.certificate?.status === "pass" ? (
            <>
              <h3>
                Status: <span className={style.pass}>Pass</span>
              </h3>
            </>
          ) : (
            <>
              <h3>Quote Summary</h3>
              <QuoteSummaryCard
                totalPrice={cert.certificate?.quoteTotal}
                quoteItems={cert.certificate?.quoteItems}
              />
            </>
          )}
          <div className={style.flex}>
            <h5>{cert.certificate?.certData?.fileName}</h5>
            <DownloadPDF
              bookingID={cert.booking.bookingID}
              serverURL={serverURL}
              fileName={
                cert.certificate ? cert.certificate?.certData.fileName : ""
              }
            />
          </div>
          <br />
          <h2>Choose a new file:</h2>
          <br />
          <FileInput
            setFile={setFile}
            setFileError={setFileError}
            file={file}
            fileError={fileError}
          />
          <br />
          <br />
          <label htmlFor="timeTaken">When will this report expire? *</label>
          <input
            type="text"
            placeholder="Type a date in the format: dd/mm/yyy"
            value={typeDate}
            maxLength={10}
            className={style.expiry}
            onChange={(e) => {
              setDateError("");
              setTypeDate(e.target.value);
            }}
          />
          {dateError && <p className="error">{dateError}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} className="btn secondaryBtn">
            Close
          </Button>

          {loading ? (
            <LoadingSpinner />
          ) : (
            <Button className={buttonStyle.modalBtn} onClick={editFile}>
              Save Changes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
