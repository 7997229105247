import style from "../../../styles/dashboard-customer.module.scss";
import { Link } from "react-router-dom";
import greater from "../../../assets/greater.svg";

export default function Customer() {
  return (
    <div className={style.dashboard}>
       <br />
      <div className={style.linkGroups}>
        <Link to="/dashboard/bookings">Your Bookings</Link>
        <img src={greater} alt="" />
      </div>
      <div className={style.linkGroups}>
        <Link to="/dashboard/reports">Your Reports</Link>
        <img src={greater} alt="" />
      </div>
      <br />
    </div>
  );
}
